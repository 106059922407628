import { useState } from 'react';
import { useQueryClient } from 'react-query';
import * as Sentry from '@sentry/react';
import { Button, Input } from '@careeros/coco';
import { addResumeComment } from '@/services/api/advisor';
import { useAnalytics } from '@/services/hooks/use-analytics';
import './add-comment-to-resume.scss';

type Props = {
  id: string;
  email: string;
  url: string;
  toggleCommentInput: () => void;
};

export const AddCommentToResume = ({
  id,
  email,
  url,
  toggleCommentInput,
}: Props) => {
  const [comment, setComment] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);
  const queryClient = useQueryClient();
  const { trackEvent } = useAnalytics();

  const addComment = async () => {
    if (comment.trim() === '') {
      setIsInvalid(true);
      return;
    }
    try {
      await addResumeComment(comment, id, email, url);
      queryClient.invalidateQueries(['resumeComments', id]);
      setComment('');
      trackEvent('CA left comment for resume');
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const onChangeHandler = (value: string) => {
    setComment(value);
    setIsInvalid(false);
  };
  return (
    <div className="add-comment-to-resume">
      <Input
        id="comment"
        label="Write a comment:"
        placeholder="Add your comment here"
        value={comment}
        height="auto"
        handleValueChange={onChangeHandler}
        invalid={isInvalid}
        warningText="Comment cannot be empty"
      />
      <div className="add-comment-to-resume__buttons">
        <Button
          label="Cancel"
          mode="primary"
          outlined
          size="small"
          icon="bi bi-x-circle"
          onClick={toggleCommentInput}
        />
        <Button
          label="Send"
          mode="primary"
          size="small"
          icon="bi bi-send"
          iconPosition="right"
          onClick={addComment}
        />
      </div>
    </div>
  );
};
