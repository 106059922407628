import { useCallback, useContext, useEffect } from 'react';
import { EventCard, EventModal } from '@careeros/coco';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { ModalContext } from '@/components/modal/modal-provider';
import { CompanyEvents } from '../../types';
import { isBefore } from '@/services/helpers/date';
import './company-events.scss';

type CompanyEventsProps = {
  events: CompanyEvents[];
  eventID: string | null;
  companyLogo: string;
  companyName: string;
  handleOpenEvent: (id: string) => void;
  handleCloseEvent: () => void;
};

const CompanyEventsContainer = ({
  events, eventID, companyLogo, companyName, handleOpenEvent, handleCloseEvent,
}: CompanyEventsProps) => {
  const { openModal, closeModal } = useContext(ModalContext) as any;
  const navigate = useNavigate();

  const pastEvents = events?.filter(event => isBefore(event)) || [];
  const upcomingEvents = events?.filter(event => !isBefore(event)) || [];

  const handleClose = () => {
    closeModal();
    handleCloseEvent();
  };

  const handleEscape = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      handleClose();
    }
  }, [closeModal, navigate]);

  useEffect(() => {
    document.addEventListener('keydown', handleEscape);
    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [handleEscape]);

  useEffect(() => {
    if (eventID && events) {
      const event = events.find(
        (ev) => ev.id === eventID.toString(),
      );
      if (event) {
        openModal(<EventModal event={event} company={{ logo: companyLogo, name: companyName }} />, false, handleClose);
      }
    }
  }, [eventID, events]);

  return (
    <div className="premium-events">
      {upcomingEvents.length > 0 && (
        <div>
          <div className="premium-events__section-title">Upcoming Events</div>
          <div className="premium-events__list">
            {upcomingEvents.sort((a, b) => (dayjs.utc(a.date_time).isBefore(dayjs.utc(b.date_time)) ? -1 : 1)).map((event) => (
              <EventCard key={event.id} event={event} onClick={() => handleOpenEvent(event.id || '')} />
            ))}
          </div>
        </div>
      )}

      {pastEvents.length > 0 && (
        <div>
          <div className="premium-events__section-title">Past Events</div>
          <div className="premium-events__list">
            {pastEvents.sort((a, b) => (dayjs.utc(a.date_time).isBefore(dayjs.utc(b.date_time)) ? 1 : -1)).map((event) => (
              <EventCard disabled key={event.id} event={event} onClick={() => handleOpenEvent(event.id || '')} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CompanyEventsContainer;
