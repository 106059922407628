import './contacts-response-rate.scss';

export const ContactsResponseRate = ({ rate }: { rate: number }) => {
  if (rate === 0) {
    return <span>📥</span>;
  }

  let rateModifier = 'high';
  if (rate < 33) {
    rateModifier = 'low';
  } else if (rate < 66) {
    rateModifier = 'medium';
  }

  return (
    <span className={`rate rate--${rateModifier}`}>
      {rate}
      %
    </span>
  );
};
