import { MiniAdvisor } from '@/career-advisor-v3/types';
import { convertFromApiMiniAdvisors } from '@/career-advisor-v3/api/model/advisor';
import { http } from '@/services/api/http';

export const getAllAdvisors = async (universityID: string): Promise<MiniAdvisor[]> => {
  const url = `/university/${universityID}/advisors`;

  const res = await http.get(url, 'v2');

  return convertFromApiMiniAdvisors(res);
};

export const toggleAdvisorAssigment = async (studentID: string, advisorID :string): Promise<void> => {
  const url = `/university/students/${studentID}/assign/${advisorID}`;

  await http.post(url, null, undefined, 'v2');
};
