import { useMemo } from 'react';
import DOMPurify from 'dompurify';
import { formatDates, orderByDate } from '@/services/helpers/contact';

import './contact-summary.scss';
import { CareerType } from '../../types/contact';
import { Education, Work } from '@/domains/core/resume/types';
import { formatDatesDifferenceText } from '@/services/helpers/date';
import { ContactInfoBox } from '../info-box/info-box';
import { Badge } from '@/components/badge/badge';
import { prettifyHTML } from '@/services/helpers/processHtml';

interface SidebarProps {
  careerSummary: CareerType;
}

export const ContactSummary = ({ careerSummary }: SidebarProps) => {
  if (!careerSummary) return null;
  const orderedWorkExperience = useMemo(() => (
    (careerSummary.work?.sort(orderByDate) ?? [])?.map(formatDates)
  ), [careerSummary.work]);

  const orderedEducation = useMemo(() => (
    (careerSummary.education?.sort(orderByDate) ?? []).map(formatDates)
  ), [careerSummary.education]);
  return (
    <div className="contact-page-sidebar">
      <div className="contact-page-sidebar__container">
        {careerSummary.basics.summary && (
          <>
            <h3 className="contact-page-sidebar__header">
              Summary
            </h3>
            <p className="contact-page-sidebar__summary" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(prettifyHTML(careerSummary.basics.summary)) }} />
          </>
        )}
        {careerSummary.languages.length > 0 && (
          <>
            <h3 className="contact-page-sidebar__header">
              Languages
            </h3>
            <ul className="contact-page-sidebar__list">
              {careerSummary.languages.map((language) => (
                <li key={language.language} className="contact-page-sidebar__list-item">
                  {language.language}
                  {' '}
                  (
                  {language.fluency}
                  {' '}
                  proficiency
                  )
                </li>
              ))}
            </ul>
          </>
        )}
        {orderedWorkExperience.length > 0 && (
          <>
            <h3 className="contact-page-sidebar__header">
              Work Experience
            </h3>
            <ul className="contact-page-sidebar__list contact-page-sidebar__list--experience">
              {orderedWorkExperience.map((work: Work) => (
                <li key={work.startDate} className="contact-page-sidebar__list-item">
                  <ContactInfoBox
                    key={`${work.startDate}-${work.endDate}`}
                    title={work.position || ''}
                    subtitle={work.name || ''}
                    period={`${work.startDate}-${work.endDate} (${formatDatesDifferenceText(work.startDate || '', work.endDate || '')})`}
                    location={work.location || undefined}
                  />
                </li>
              ))}
            </ul>
          </>
        )}
        {orderedEducation.length > 0 && (
          <>
            <h3 className="contact-page-sidebar__header">
              Education
            </h3>
            <ul className="contact-page-sidebar__list contact-page-sidebar__list--experience">
              {orderedEducation.map((edu: Education) => (
                <li key={edu.startDate} className="contact-page-sidebar__list-item">
                  <ContactInfoBox
                    key={`${edu.startDate}-${edu.endDate}`}
                    title={`${edu.studyType} - ${edu.area}`}
                    subtitle={edu.institution || ''}
                    period={`${edu.startDate}-${edu.endDate} (${formatDatesDifferenceText(edu.startDate || '', edu.endDate || '')})`}
                    icon="education"
                  />
                </li>
              ))}
            </ul>
          </>
        )}
        {careerSummary?.skills?.length > 0 && (
          <>
            <h3 className="contact-page-sidebar__header">
              Skills
            </h3>
            <p className="contact-page-sidebar__skills">
              {careerSummary.skills.map((skill) => (
                <Badge label={skill.name} color="gradient" />
              ))}
            </p>
          </>
        )}
      </div>
    </div>
  );
};
