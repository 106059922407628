/* eslint-disable no-bitwise */
import React, {
  useEffect, useState, useRef, useCallback,
} from 'react';
import './radial-progress.scss';

interface RadialProgressProps {
  percentage: number;
  difference: number;
  status: 'positive' | 'negative';
  startPercentage: number;
  size?: string;
}

const RadialProgress: React.FC<RadialProgressProps> = ({
  percentage = 0,
  difference = 0,
  status,
  startPercentage,
  size,
}) => {
  const [currentPercentage, setCurrentPercentage] = useState(startPercentage);
  const [barColor, setBarColor] = useState('');
  const animationRef = useRef<number>();

  const hexToRgb = useCallback((hex: string): [number, number, number] => {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return [r, g, b];
  }, []);

  const rgbToHex = useCallback(
    (r: number, g: number, b: number): string => `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`,
    [],
  );

  const interpolateColor = useCallback((color1: [number, number, number], color2: [number, number, number], factor: number): [number, number, number] => {
    const r = Math.round(color1[0] + factor * (color2[0] - color1[0]));
    const g = Math.round(color1[1] + factor * (color2[1] - color1[1]));
    const b = Math.round(color1[2] + factor * (color2[2] - color1[2]));
    return [r, g, b];
  }, []);

  const calculateColor = useCallback((number: number): string => {
    if (number < 0 || number > 100) {
      return rgbToHex(255, 255, 255);
    }

    const red = hexToRgb('#ff8585');
    const yellow = hexToRgb('#ffe59e');
    const green = hexToRgb('#7bcd8c');

    let interpolatedColor: [number, number, number];

    if (number <= 50) {
      const factor = number / 50;
      interpolatedColor = interpolateColor(red, yellow, factor);
    } else {
      const factor = (number - 50) / 50;
      interpolatedColor = interpolateColor(yellow, green, factor);
    }

    return rgbToHex(interpolatedColor[0], interpolatedColor[1], interpolatedColor[2]);
  }, [hexToRgb, rgbToHex, interpolateColor]);

  useEffect(() => {
    let startTime: number | null = null;
    const duration = 1000; // Animation duration in milliseconds

    const animate = (timestamp: number) => {
      if (!startTime) startTime = timestamp;
      const progress = Math.min((timestamp - startTime) / duration, 1);
      const current = Math.round(startPercentage + (percentage - startPercentage) * progress);
      setCurrentPercentage(current);

      if (progress < 1) {
        animationRef.current = requestAnimationFrame(animate);
      }
    };

    animationRef.current = requestAnimationFrame(animate);

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [startPercentage, percentage]);

  useEffect(() => {
    const color = calculateColor(currentPercentage);
    document.documentElement.style.setProperty('--bar-color', color);
    setBarColor(color);
  }, [currentPercentage, calculateColor]);

  return (
    <div className={`progress ${size ? `progress--${size}` : ''}`}>
      <div className="barOverflow">
        <div
          className={`bar bar--${currentPercentage}`}
          style={{
            transform: `rotate(${45 + currentPercentage * 1.8}deg)`,
            borderBottomColor: barColor,
            borderRightColor: barColor,
          }}
        />
      </div>
      <span className="value">
        {currentPercentage}
        {' '}
        pts
        {difference !== 0 && (
        <span
          className={`difference difference--${status}`}
          style={{
            color: status === 'positive' ? '#559161' : '#DB7171',
          }}
        >
          {' '}
          (
          {status === 'positive' ? '+' : '-'}
          {Math.abs(difference)}
          {' '}
          pts)
        </span>
        )}
      </span>
    </div>
  );
};

export default RadialProgress;
