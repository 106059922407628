import { useState } from 'react';
import './use-ai-button.scss';
import { getTemplate } from '@/services/api/ai';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { useSelfUniversity } from '@/services/queries/user';
import { AITemplateType } from '../../types';
import { AITemplatesButton } from './templates-button';

type Props = {
  options: AITemplateType[]
  handleResult: (message: string) => void;
  metaData: any;
  type: string;
  size?: 'small' | 'medium' | 'large';
  buttonMode?: 'blue' | 'special';
};

export const UseAIButton = ({
  options,
  handleResult,
  metaData,
  type,
  size = 'large',
  buttonMode = 'special',
}: Props) => {
  const [poolingMessages, setPoolingMessages] = useState(false);
  const { trackEvent } = useAnalytics();
  const { data: university } = useSelfUniversity();

  const handleTemplateFetch = async (name: string, outreachType: string) => {
    setPoolingMessages(true);
    const payload = {
      type,
      outreach_type: outreachType,
      name,
      to_contact_id: metaData.contactID,
      character_limit: metaData.characterLimit ?? 500,
    };
    const response = await getTemplate(payload);
    if (response.response) {
      handleResult(response.response);
      setPoolingMessages(false);
    }
  };

  const handleOptionClick = (option: string, outreactType: string) => {
    trackEvent('AI Button clicked', {}, {
      type: outreactType,
      option,
      university: university?.name,
      cohort: university?.cohort,
    });
    if (type === 'linkedin' || type === 'email') {
      handleTemplateFetch(option, outreactType);
    }
  };
  return (
    <AITemplatesButton
      options={options}
      size={size}
      buttonMode={buttonMode}
      isLoading={poolingMessages}
      handleOptionClick={handleOptionClick}
      buttonLabel={size === 'large' ? 'Generate Message' : 'AI'}
    />
  );
};
