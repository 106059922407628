import { COMPANY_STEPS } from '../../constants';
import './company-stepper.scss';

interface CompanyStepperProps {
  activeState: typeof COMPANY_STEPS[number];
  handleStepClick?: (step: typeof COMPANY_STEPS[number]) => void;
  readonly?: boolean;
}

/**
 * Primary UI component for networking stepper
 */
export const CompanyStepper = ({ activeState, handleStepClick, readonly = false }: CompanyStepperProps) => {
  const computeStepClasses = (
    step: typeof COMPANY_STEPS[number],
    index: number,
  ) => {
    if (activeState === step) {
      return 'company-stepper__step company-stepper__step--active';
    }
    if (index < COMPANY_STEPS.indexOf(activeState)) {
      return 'company-stepper__step company-stepper__step--completed';
    }
    return 'company-stepper__step';
  };
  return (
    <div className={`company-stepper ${readonly ? 'company-stepper--readOnly' : ''}`}>
      {COMPANY_STEPS.slice(0, -1).map((step: string, index: number) => (
        <div onClick={() => handleStepClick && handleStepClick(step)} key={step} className={computeStepClasses(step, index)}>
          {step}
        </div>
      ))}
    </div>
  );
};
