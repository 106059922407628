import { useMemo } from 'react';
import { Badge } from '@careeros/coco';
import { TallyDataTypes } from '../../types/types';
import './tally-info.scss';

type Props = {
  tallyData: {
    type: TallyDataTypes;
    values: string[];
  }[];
};
export const TallyInfo = ({
  tallyData,
}: Props) => {
  const tallyInfoMap = useMemo(() => ({
    DREAM_JOB: {
      label: '🌟 Dream Role',
      value: tallyData?.find(item => item.type === 'DREAM_JOB')?.values || [],
    },
    JOB_SEEKING_STATUS: {
      label: '👀 Job-Seeking Status',
      value: tallyData?.find(item => item.type === 'JOB_SEEKING_STATUS')?.values || [],
    },
    EXPECTED_GRADUATION_DATE: {
      label: '🎓 Expected Graduation Date',
      value: tallyData?.find(item => item.type === 'EXPECTED_GRADUATION_DATE')?.values || [],
    },
    IDEAL_EMPLOYMENT_DATE: {
      label: '🧑‍💻 Ideal Employment Date',
      value: tallyData?.find(item => item.type === 'IDEAL_EMPLOYMENT_DATE')?.values || [],
    },
    PREFERRED_CONTRACT_TYPES: {
      label: '📑 Preferred Contract Types',
      value: tallyData?.find(item => item.type === 'PREFERRED_CONTRACT_TYPES')?.values || [],
    },
    LANGUAGES: {
      label: '🌎 Language(s)',
      value: tallyData?.find(item => item.type === 'LANGUAGES')?.values || [],
    },
    TARGET_INDUSTRIES: {
      label: '🎯 Target Industries',
      value: tallyData?.find(item => item.type === 'TARGET_INDUSTRIES')?.values || [],
    },
    PREFERRED_DEPARTMENTS: {
      label: '👥 Preferred Departments',
      value: tallyData?.find(item => item.type === 'PREFERRED_DEPARTMENTS')?.values || [],
    },
    PREFERRED_LOCATIONS: {
      label: '📍 Preferred Location(s)',
      value: tallyData?.find(item => item.type === 'PREFERRED_LOCATIONS')?.values || [],
    },
    WORK_PERMIT: {
      label: '📄 Work Permit',
      value: tallyData?.find(item => item.type === 'WORK_PERMIT')?.values || [],
    },
  }), [tallyData]);

  return (
    <div className="tally-info">
      {Object.entries(tallyInfoMap).map(([key, { label, value }]) => (
        <div key={key} className="tally-info__item">
          <div className="tally-info__label">{label}</div>
          <div className="tally-info__value">
            {value.map((item: string) => (
              <Badge key={item} label={item} color="grey" />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
