import { FilterOption } from '../../company/types/company';
import {
  contractStaticOptions,
  languageMap,
  postedDateStaticOptions,
  workTypeStaticOptions,
} from '../components/job-filters/constants';
import { JobsFilterState } from '../types/job';

type FormatterType<Source, K extends keyof Source> = (value: string) => Source[K];

const mapDropdownOptions = (values: string[], options: FilterOption[]) => values
  .flatMap((value) => decodeURIComponent(value).split(','))
  .map((value) => options.find((option) => option.value === value))
  .filter(Boolean) as FilterOption[];

const mapLocationOptions = (locationStrings: string[]) => {
  try {
    const locations = JSON.parse(decodeURIComponent(locationStrings[0]));
    return Array.isArray(locations) ? locations.map(loc => ({
      name: [loc.city, loc.state, loc.country].filter(Boolean).join(', '),
      value: JSON.stringify(loc),
    })) : [];
  } catch (error) {
    return [];
  }
};

const mapLanguageOptions = (languageStrings: string[]) => {
  const codes = languageStrings.flatMap(code => code.split(','));
  return codes.map(code => ({
    name: languageMap[code] || code,
    value: code,
  }));
};

export const convertURLParamsToFilters = (
  searchParams: URLSearchParams,
  staticOptions: {
    industriesOptions: FilterOption[];
    sizesOptions: FilterOption[];
    jobsLanguagesOptions: FilterOption[];
  },
): JobsFilterState => {
  const newFilters: JobsFilterState = {
    query: '',
    sizes: [],
    job_types: [],
    work_modes: [],
    industries: [],
    locations: [],
    date_range: [],
    languages: [],
    favoriteCompanies: false,
  };
  const { industriesOptions, sizesOptions } = staticOptions;

  const formatters: { [K in keyof JobsFilterState]: FormatterType<JobsFilterState, K> } = {
    query: (value: string) => value,
    favoriteCompanies: (value: string) => value === 'true',
    sizes: (value: string) => mapDropdownOptions(value.split(','), sizesOptions),
    job_types: (value: string) => mapDropdownOptions(value.split(','), contractStaticOptions),
    work_modes: (value: string) => mapDropdownOptions(value.split(','), workTypeStaticOptions),
    industries: (value: string) => mapDropdownOptions(value.split(','), industriesOptions),
    date_range: (value: string) => mapDropdownOptions(value.split(','), postedDateStaticOptions),
    languages: (value: string) => mapLanguageOptions(value.split(',')),
    locations: (value: string) => mapLocationOptions(value.split(',')),
  };

  searchParams.forEach((value, key) => {
    if (key in formatters) {
      const k = key as keyof JobsFilterState;
      const formatter = formatters[k];
      const formattedValue = formatter(value);
      (newFilters[k] as JobsFilterState[typeof k]) = formattedValue;
    }
  });

  return newFilters;
};

const formatArrayValueForSearch = (
  name: string,
  value: FilterOption[],
): string => {
  if (name === 'locations') {
    return encodeURIComponent(JSON.stringify(value.flatMap((v) => JSON.parse(v.value as string))));
  }

  return value.flatMap((v: { value: any }) => encodeURIComponent(v.value)).join(',');
};

export const getSearchParamsString = (
  filters: JobsFilterState,
): URLSearchParams => {
  const newSearchParams = new URLSearchParams();

  Object.entries(filters).forEach(([key, value]) => {
    if (Array.isArray(value) && value.length > 0) {
      newSearchParams.append(key, formatArrayValueForSearch(key, value));
    }

    if (
      ['string', 'boolean'].includes(typeof value) && value !== ''
    ) {
      newSearchParams.append(key, value.toString());
    }
  });

  return newSearchParams;
};

export const updateQueryString = (params: URLSearchParams) => {
  window.history.replaceState(null, '', `?${params.toString()}`);
};
