import { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@careeros/coco';
import { APIstatuses } from '@/domains/core/tracker-data';
import { CompanyStepper } from '../company-stepper/company-stepper';
import { TrackerContextType, TrackerContext } from '../tracker-provider/tracker-context';

interface CompanyProgressTrackerProps {
  status: string,
  name: string,
  id: string;
}
export const CompanyProgressTracker = ({ status, name, id }: CompanyProgressTrackerProps) => {
  const { openTracker } = useContext(TrackerContext) as TrackerContextType;
  const navigate = useNavigate();

  const goToTrackerPage = () => {
    openTracker(id);
  };
  const goToArchivePage = () => {
    navigate('/app/archive');
  };
  return (
    <div>
      {(status === 'saved'
          && (
          <div className="progress-tracker__status">
            <p className="progress-tracker__text">
              {' '}
              {name}
              {' '}
              is saved to your
              {' '}
              <Link to="/app/overview">Overview Board</Link>
              . You’re off to a great start!
            </p>
            <CompanyStepper activeState={APIstatuses[status]} readonly />
            <p className="progress-tracker__text">
              The next step for you is to start networking with the company. To make things easier, we’ve prepared a list of tips and tricks - check them out!
            </p>
            <Button mode="primary" handleClick={goToTrackerPage} label="Start networking" icon="bi bi-arrow-up-right" iconPosition="right" size="medium" />
          </div>
          )
      ) || (status !== 'saved' && status !== 'archive'
          && (
          <div className="progress-tracker__status">
            <CompanyStepper activeState={APIstatuses[status]} readonly />
            <p className="progress-tracker__text">
              Check out
              {' '}
              {name}
              &apos;s profile below, then head over to your progress page to complete the next steps on your journey with
              {' '}
              {name}
              .
            </p>
            <Button mode="primary" handleClick={goToTrackerPage} icon="bi bi-check-circle" label="Complete Next Steps" size="medium" />
          </div>
          )
      ) || (status === 'archive'
        && (
        <div className="progres-tracker__archive">
          <p className="progress-tracker__text">
            Your journey with
            {' '}
            {name}
            {' '}
            has been paused since it was placed in your archived list. Click the button below, to view list or restore
            {' '}
            {name}
            {' '}
            to your Overview Board.
          </p>
          <Button mode="primary" handleClick={goToArchivePage} label="View in Archive" size="fill" />
        </div>
        )
      )}
    </div>
  );
};
