import { http } from '@/services/api/http';
import { Company, CompanyProfile, FilterCompaniesRequestBody } from '@/domains/core/company/types';

export const getSingleCompany = async (companyID?: string): Promise<Company | any> => http.get(`/companies/${companyID}`);

export const getSingleCompanyProfile = async (companyID?: string): Promise<CompanyProfile> => http.get(`/companies/${companyID}/profile`);

export const patchSingleCompany = async (id: string, payload: { [name in keyof Company]: any }) => http.patch(`/companies/${id}`, payload);

export const updateCompanyRating = async (id:string, rating: number) => http.patch(`/applications/${id}/rating?rating=${rating}`, {});

export const getCompanies = async () => http.get('/companies/explore');

export const getExploreCompanies = async (industryName: string, requestData: FilterCompaniesRequestBody = {}) => {
  if (!industryName) {
    industryName = 'Featured';
  }

  const params = new URLSearchParams(
    Object.entries({
      ...requestData,
      ...(requestData.locations && { locations: JSON.stringify(requestData.locations.map(({ country, city }) => ({ country, city }))) }),
    }).filter(([, value]) => value !== undefined)
      .map(([key, value]) => [key, value.toString()]),
  );

  return http.get(`/companies/explore?industry=${encodeURIComponent(industryName)}&${params.toString()}`);
};

export const getFilteredCompanies = (
  requestData: FilterCompaniesRequestBody,
  page: number = 1,
) => {
  const params = new URLSearchParams(
    Object.entries({
      ...requestData,
      ...(requestData.locations && { locations: JSON.stringify(requestData.locations.map(({ country, city }) => ({ country, city }))) }),
      page: page.toString(),
    }).filter(([, value]) => value !== undefined)
      .map(([key, value]) => [key, value.toString()]),
  );

  return http.get(`/companies?${params.toString()}`) || [];
};

export const getCompaniesByName = async (name: string) => http.get(`/companies/search/${name}`);

export const getNotesByCompany = async (companyID: string) => http.get(`/companies/${companyID}/notes?offset=0&limit=20`);

export const addCompanyNote = async (companyID: string, title: string, content: string) => http.post(`/companies/${companyID}/notes`, { content, title });

export const editCompanyNote = async (companyID: string, noteID: string, title: string, content: string) => http.patch(`/companies/${companyID}/notes/${noteID}`, { content, title });

export const deleteCompanyNote = async (companyID: string, noteID: string) => http.delete(`/companies/${companyID}/notes/${noteID}`);

export const getCompanyLocations = async () => http.get('/companies/locations');

export const getCompaniesLocationsByQuery = async (query: string) => http.get(`/companies/locations?name=${query}`);

export const getCompanySizes = async () => http.get('/companies/sizes');

export const getCompanyTags = async () => http.get('/companies/tags');

export const getIndustryByCategoryName = async (categoryName: string): Promise<{ name: string, icon: string }> => http.get(`/industries/bycategory/${categoryName}`);
