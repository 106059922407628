import { Provider } from './navbar-context';
import { useNavbarContext } from '@/services/hooks/use-navbar-context';

type Props = {
  children: JSX.Element;
};

const NavbarContextProvider = ({ children }: Props) => {
  const {
    unreadTabs, addUnreadTab, removeUnreadTab,
  } = useNavbarContext();

  return (
    <Provider value={{
      unreadTabs, addUnreadTab, removeUnreadTab,
    }}
    >
      {children}
    </Provider>
  );
};

export { NavbarContextProvider };
