import { DefaultLogo } from '@/components/default-logo/default-logo';
import backgroundProfile from '@/assets/images/backgroundProfile.png';
import './basic-profile-info.scss';

type Props = {
  email: string;
  first_name: string;
  last_name: string;
  profile_picture_url: string;
  background_image: string | null;
  degree: string | null;
  summary: string | null;
  university: {
    name: string;
    logo_url: string | null;
    location: string;
  };
};

export const BasicProfileInfo = ({
  email,
  first_name,
  last_name,
  profile_picture_url,
  background_image,
  degree,
  summary,
  university,
}: Props) => (
  <div className="basic-profile-info">
    <div className="basic-profile-info__banner">
      <img src={background_image || backgroundProfile} alt="banner" className="basic-profile-info__banner-image" />
    </div>
    <DefaultLogo
      source={profile_picture_url || ''}
      name={`${first_name} ${last_name}`}
      size={160}
      type="contact"
      className="basic-profile-info__image"
    />
    <div className="basic-profile-info__content">
      <div className="basic-profile-info__content-name">{`${first_name} ${last_name}`}</div>
      <div className="basic-profile-info__content-degree">{degree}</div>
      <div className="basic-profile-info__content-university">
        <DefaultLogo
          source={university?.logo_url || ''}
          name={university?.name}
          size={32}
          type="company"
          className="basic-profile-info__content-university-icon"
        />
        <div>
          <div className="basic-profile-info__content-university-name">{university?.name}</div>
          <div className="basic-profile-info__content-university-location">{university?.location}</div>
        </div>
      </div>
      <div className="basic-profile-info__content-email">
        <i className="bi bi-envelope" />
        <span>{email}</span>
      </div>
      <div className="basic-profile-info__content-summary">{summary}</div>
    </div>
  </div>
);
