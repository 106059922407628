import { useContext } from 'react';
import { ModalContext } from '@/components/modal/modal-provider';
import { ToastContext } from '@/components/toast/toast-provider';
import { SimpleInfoModal } from '@/domains/generic/modals/simple-info-modal/simple-info-modal';
import { AddToastType } from '@/domains/generic/toasts/types';
import { Tooltip } from '@/components/tooltip/tooltip';
import { actionVerbs } from '../../helpers/actionVerbs';
import './action-verb-modal.scss';

export const ActionVerbModal = () => {
  const { openModal } = useContext(ModalContext) as any;
  const { addToast }: AddToastType = useContext(ToastContext);
  const copyActionVerb = (verb: string) => {
    navigator.clipboard.writeText(verb);

    addToast({
      type: 'success',
      message: 'Action verb copied to clipboard successfully!',
    });
  };

  const showActionVerbsModal = () => {
    openModal(
      <SimpleInfoModal
        title="What action verbs to use?"
        subtitle="Click on any action verb below to copy it to your clipboard, and you can then paste it into your bullet points."
        icon="bi bi-lightbulb"
        description={(
          <div className="action-verb-modal__action-verb-container">
            {actionVerbs.map((actionVerb) => (
              <Tooltip
                label="Click to copy to clipboard"
                position="top"
                key={actionVerb}
                withArrow
                size="fit-content"
              >
                <div
                  className="action-verb-modal__action-verb-item"
                  onClick={() => copyActionVerb(actionVerb)}
                >
                  <i className="bi bi-copy" />
                  {actionVerb}
                </div>
              </Tooltip>
            ))}
          </div>
        )}
      />,
    );
  };
  return (
    <div className="action-verb-modal">
      <span className="action-verb-modal__divider">|</span>
      <span
        className="action-verb-modal__description-link action-verb-modal__divider"
        onClick={showActionVerbsModal}
      >
        Tell me Action Verbs
      </span>
    </div>
  );
};
