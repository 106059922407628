import { IconButton } from '@careeros/coco';
import { Column, TableRow } from '@/components/table/table-types';
import { ConnectionStatus, ContactWithNetworkingData } from '../../contact/types';
import { router } from '@/main';
import { DefaultLogo } from '@/components/default-logo/default-logo';
import { StatusTag } from '../../contact/components/status-tags/status-tag';
import { Tooltip } from '@/components/tooltip/tooltip';
import { contactAPIstatuses } from './contacts-kanban';

export const ContactsTableColumns: Column[] = [
  {
    label: 'Name', accessor: 'full_name', sortable: true, type: 'link', isCentered: false,
  },
  {
    label: 'Company', accessor: 'company', sortable: true, type: 'link', sortbyOrder: 'asc', isCentered: false,
  },
  {
    label: 'Job Title', accessor: 'job_title', sortable: true, type: 'text', isCentered: false,
  },
  {
    label: 'Email', accessor: 'email', type: 'text', sortable: true, isCentered: false,
  },
  {
    label: 'LinkedIn Status', accessor: 'linkedin_status', type: 'text', sortable: true, isCentered: false,
  },
  {
    label: 'Networking Status', accessor: 'networking_status', type: 'text', sortable: true, isCentered: false,
  },
  {
    label: 'Actions', accessor: 'actions', type: 'actions', sortable: false, isCentered: false,
  },
];

export const ContactsTableColumnsWithoutStatuses: Column[] = ContactsTableColumns.filter((column) => column.label !== 'Networking Status' && column.label !== 'LinkedIn Status');

const calculateNetworkingStatusValue = (status: ConnectionStatus) => {
  const statusValues = {
    connected: 3,
    pending: 2,
    notConnected: 1,
  };

  return statusValues[status];
};

type MapContactsFunction = (
  contacts: ContactWithNetworkingData[],
  openTracker: (
    id: string,
    tab?: string | undefined,
    additionalParams?: {
      [key: string]: string;
    }) => void,
  archiveContact: (id: string, currentStatus: string) => void,
) => TableRow[];

export const mapContacts: MapContactsFunction = (contacts, openTracker, archiveContact) => (Array.isArray(contacts) ? contacts.map((contact) => ({
  id: contact.id,
  handleRowClick: () => router.navigate(`/app/inbox/${contact.id}`),
  items: {
    full_name: {
      value: `${contact.first_name} ${contact.last_name}`,
      label: `${contact.first_name} ${contact.last_name}`,
      image: <DefaultLogo
        type="contact"
        source={contact.career_summary.basics.image}
        className="contacts-page__table-image"
        name={`${contact.first_name} ${contact.last_name}`}
        size={24}
      />,
      type: 'link',
      to: `/app/inbox/${contact.id}`,
    },
    company: {
      value: contact.current_position.company,
      label: contact.current_position.company,
      image: <DefaultLogo
        type="company"
        source={contact.current_position.company_logo_url}
        className="contacts-page__table-image"
        name={contact.current_position.company}
        size={24}
      />,
      type: 'link',
      handleClick: () => openTracker(encodeURIComponent(contact.current_position.company_id)),
    },
    job_title: {
      type: 'text',
      label: contact.current_position.title,
      value: contact.current_position.title,
    },
    email: {
      type: 'text',
      label: contact.email ? (
        <span className="contacts-page__contact-email">
          {[contact.email, contact.secondary_email].filter(Boolean).join(', ')}
        </span>
      ) : (
        <span className="contacts-page__table-placeholder">no email found</span>
      ),
      value: [contact.email, contact.secondary_email].filter(Boolean).join(', ') || '',
    },
    networking_status: {
      type: 'text',
      label: (
        <div className="contacts-page__statuses">
          <StatusTag status={contact.networking_status || 'notConnected'} type="networking" />
        </div>
      ),
      value: calculateNetworkingStatusValue(contact.networking_status || 'notConnected'),
    },
    linkedin_status: {
      type: 'text',
      label: (
        <div className="contacts-page__statuses">
          <StatusTag status={contact.linkedin_connection_status} type="linkedin" withTooltip />
        </div>
      ),
      value: calculateNetworkingStatusValue(contact.linkedin_connection_status),
    },
    actions: {
      label: (
        <div className="contacts-page__actions">
          <div className="contacts-page__action">
            <Tooltip label="View profile" position="top" withArrow>
              <IconButton
                icon="bi bi-linkedin"
                mode="rounded"
                size="medium"
                outlined
                handleClick={() => window.open(contact.linkedin_url, '_blank')}
                hoverIcon="bi bi-box-arrow-up-right"
              />
            </Tooltip>
          </div>
          <div className="contacts-page__action">
            <Tooltip label="Archive" position="top" withArrow>
              <IconButton
                icon="bi bi-archive"
                mode="rounded"
                size="medium"
                outlined
                isDangerous
                handleClick={() => archiveContact(contact.id, contactAPIstatuses[contact.kanban_position.status])}
              />
            </Tooltip>
          </div>
        </div>
      ),
      value: calculateNetworkingStatusValue(contact.networking_status || 'notConnected'),
    },
  },
})) : []);
