import {
  About,
  Benefits, CompanyMedia, CompanyProfileHeader, Faqs, Locations,
} from '@careeros/coco';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import {
  ApiStatus,
  Company, CompanyCulture, CompanyEvents, CompanyFeatures, OverviewInformation, PremiumCompanyGeneralInformation,
} from '@/domains/core/company/types';
import '../styles/company.scss';
import { TabsContent, TabsHeader } from '@/components/tabs';
import {
  addCompanyToFavorites, BasicCompanyJobsTab, CompanyStatusContainer, CompanyEventsContainer, CompanyCultureContainer,
} from '@/domains/core/company';
import { Contact } from '@/domains/core/contact/types';
import { ModalContext } from '@/components/modal/modal-provider';

type PremiumProfileProps = {
  company: Company;
  companyOverview: OverviewInformation;
  companyFeatures: CompanyFeatures;
  generalInformation: PremiumCompanyGeneralInformation;
  events: CompanyEvents[];
  culture: CompanyCulture;
  testimonials: any; // we dont know the response yet
  aboutApplication: any; // we dont know the response yet
  application: {
    application_id: string;
    application_status: ApiStatus;
  }
  contacts: Contact[];
};

const mapsKey = import.meta.env.VITE_GOOGLE_MAPS_ID || '';
const DEFAULT_TABS = ['Overview', 'Jobs'];

const PremiumCompanyProfile = ({
  companyOverview, company, companyFeatures, generalInformation, events, culture, testimonials, aboutApplication, application, contacts,
}: PremiumProfileProps) => {
  const [tabs, setTabs] = useState(DEFAULT_TABS);
  const { openModal } = useContext(ModalContext) as any;
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [searchParams, setSearchParams] = useSearchParams();

  const openEvent = (id: string) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set('eventID', id);
    newSearchParams.set('basic-company-tab', 'Events'); // Ensure we're on Events tab
    setSearchParams(newSearchParams);
  };

  const closeEvent = () => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete('eventID');
    // Keep the current tab
    setSearchParams(newSearchParams);
  };
  const revalidate = () => {
    queryClient.invalidateQueries(['applications-no-cache']);
    queryClient.invalidateQueries(['company', company.id]);
    queryClient.invalidateQueries(['company-profile', company.id]);
  };

  const goToContact = (contactID: string) => {
    navigate(`/app/inbox/${contactID}`);
  };

  const saveCompany = () => {
    addCompanyToFavorites(company.id).finally(revalidate);
  };

  useEffect(() => {
    const newTabs = DEFAULT_TABS;
    // TODO uncomment when we have the components
    if (events && events?.length > 0) {
      newTabs.push('Events');
    }
    if (culture) {
      newTabs.push('Culture');
    }
    if (testimonials) {
      newTabs.push('Testimonials');
    }
    if (aboutApplication) {
      newTabs.push('About Application');
    }
    setTabs(newTabs);
  }, []);

  if (!company) {
    return (
      <>
        Company not found
      </>
    );
  }

  const showInfoSection = companyOverview.benefits_custom || (companyOverview.benefits && companyOverview.benefits.length > 0) || companyOverview.upload_photos_videos || companyOverview.office_locations || (companyOverview.faq_questions && companyOverview.faq_questions.length > 0);

  return (
    <>
      <CompanyProfileHeader
        name={company.name}
        logoImage={company.logo_url}
        location={company.hq_location}
        coverImage={company.bg_image_url || ''}
        isVerified
      />
      <div className="company-page-premium__content">
        <div className="company-page-premium__tabs">
          <TabsHeader
            tabs={tabs}
            size="medium"
            withBorder
            defaultTab={searchParams.get('basic-company-tab') || tabs[0]}
            containerName="basic-company"
          />
        </div>
        <TabsContent>
          <div data-tab="Overview">
            <CompanyStatusContainer company={company} application={application} saveCompany={saveCompany} goToContact={goToContact} contacts={contacts} />
            <div className="company-page-premium__overview">
              {showInfoSection && (
              <section className="company-page-premium__info-section">
                {(companyOverview.benefits_custom || companyOverview.benefits.length > 0) && companyFeatures.show_benefits
                  ? <Benefits badges={companyOverview.benefits} description={companyOverview.benefits_custom} />
                  : null}

                {companyOverview.upload_photos_videos && companyFeatures.show_overview_media ? <CompanyMedia media={companyOverview.upload_photos_videos} openModal={openModal} /> : null}
                {companyOverview.office_locations && companyFeatures.show_office_locations && <Locations locations={companyOverview.office_locations} badges={companyOverview.office_perks} description={companyOverview.office_perks_custom} showMap={companyFeatures.show_office_locations} showPerks={companyFeatures.show_office_perks} googleMapsApiKey={mapsKey} />}
                {companyOverview.faq_questions && companyOverview.faq_questions.length > 0 && companyFeatures.show_faq ? <Faqs faqs={companyOverview.faq_questions} companyName={company.name} /> : null}
              </section>
              )}
              <section className={`company-page-premium__about-section ${showInfoSection ? '' : 'company-page-premium__about-section--full'}`}>
                <About generalInformation={{
                  ...generalInformation, location: generalInformation.hq_location, size: generalInformation.company_size, description: generalInformation.overview, industry: generalInformation.careeros_industry,
                }}
                />
              </section>
            </div>
          </div>
          <div className="company-page__events" data-tab="Events">
            <CompanyEventsContainer
              events={events}
              eventID={searchParams.get('eventID')}
              companyName={company.name}
              companyLogo={company.logo_url}
              handleOpenEvent={openEvent}
              handleCloseEvent={closeEvent}
            />
          </div>
          <div className="company-page__culture" data-tab="Culture">
            <CompanyCultureContainer culture={culture} features={companyFeatures} />
          </div>
          <div className="company-page__jobs" data-tab="Jobs">
            <BasicCompanyJobsTab // to decide if we reuse this or we have something else here
              companyID={company.id}
              isCompanyApplicationActive={!!company.application_status && company.application_status !== 'archive'}
              companyName={company.name}
              companyLogo={company.logo_url}
              companyLinkedIn={company.linkedin_url}
              updateTabCount={() => { }}
              applicationID={application.application_id}
            />
          </div>
        </TabsContent>

      </div>
    </>
  );
};

export default PremiumCompanyProfile;
