import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useCookies } from 'react-cookie';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { useNavbarContext } from '@/services/hooks/use-navbar-context';
import { LinkType } from '@/pages/layout/navbar/types/types';
import { NavbarView } from '@/pages/layout/navbar/components/navbar-view/navbar-view';
import { NewCareerAdvisorLinks, StudentLinks, StudentLinksForCareerAdvisor } from '@/pages/layout/navbar/navbar-links';

type Props = {
  collapsed: boolean;
  handleToggle: () => void;
};

export default function CareerAdvisorNavbar({ collapsed, handleToggle }: Props) {
  const { user } = useAuth0();
  const { trackEvent } = useAnalytics();
  const { unreadTabs } = useNavbarContext();
  const [links, setLinks] = useState<LinkType[]>(NewCareerAdvisorLinks);
  const [cookies] = useCookies(['cookiesAccepted']);
  const handleLinkClick = (linkName: string) => {
    trackEvent('Page Visit', user, {
      pageURL: linkName,
    });
    const newLinks = links?.map((linksLink: LinkType) => {
      linksLink.isActive = false;
      return linksLink;
    });
    setLinks(newLinks);
  };

  return (
    <NavbarView
      collapsed={collapsed}
      isCookieBannerVisible={!cookies.cookiesAccepted}
      showContent
      logoLink="/app/dashboard-advisor"
      links={links}
      handleToggle={handleToggle}
      handleLinkClick={handleLinkClick}
      splitBefore={links[0] !== StudentLinks[0] ? StudentLinks[0].label : undefined}
      unreadTabs={unreadTabs}
      hasStudentAppView
      studentLinks={StudentLinksForCareerAdvisor}
    />
  );
}
