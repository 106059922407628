import { setLetestUrlToStorage } from '@/services/helpers/browser-storage';

const BASE_URL = import.meta.env.VITE_BASE_URL;

async function request(
  url: string,
  method = 'GET',
  data: any = null,
  opt: RequestInit = {},
  responseType?: 'blob',
  version: string = 'v1', // New default parameter
): Promise<any> {
  const accessToken = window.localStorage.getItem('token');
  const baseUrlWithVersion = `${BASE_URL}/${version}`;

  const options: RequestInit = { ...opt, method };
  options.headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  if (data) {
    if (data instanceof FormData) {
      options.body = data;
    } else {
      options.headers['Content-Type'] = 'application/json; charset=UTF-8';
      options.body = JSON.stringify(data);
    }
  }

  return fetch(baseUrlWithVersion + url, options)
    .then(response => {
      const contentType = response.headers.get('content-type');

      if (!response.ok) {
        return response.text().then(text => { throw new Error(text); });
      }

      if (responseType === 'blob') {
        const contentDisposition = response.headers.get('content-disposition');
        return {
          response: response.blob(),
          contentDisposition,
        };
      }

      if (contentType && contentType.indexOf('application/json') !== -1) {
        return response.json();
      }

      return null;
    }).catch(err => {
      let error;
      try {
        error = JSON.parse(err.message);
      } catch (e) {
        // trackEvent(`JSON Parse Error for ${url} `, e);
        // throw new Error(error.message);
      }

      if (error?.error === 'Failed to validate JWT token') {
        window.localStorage.removeItem('token');
        setLetestUrlToStorage(window.location.href);
        window.location.href = '/logout';
      } else {
        // throw new Error(`Request Error for ${url}`);
        // we need to add an error toast here
        // trackEvent(`Request Error for ${url} `, error.error);

      }
      return error;
    });
}

export const http = {
  get: (url: string, version?: string) => request(url, 'GET', null, {}, undefined, version),
  delete: (url: string, version?: string) => request(url, 'DELETE', null, {}, undefined, version),
  post: (url: string, data: any, options?: RequestInit & { responseType?: 'blob' }, version?: string) => request(url, 'POST', data, options, options?.responseType, version),
  patch: (url: string, data: any, version?: string) => request(url, 'PATCH', data, {}, undefined, version),
  put: (url: string, data: any, version?: string) => request(url, 'PUT', data, {}, undefined, version),
};
