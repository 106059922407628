import { FilterOption } from '@/domains/core/company/types';
import { MultiSelectOption } from './multi-select-option/multi-select-option';
import './select-dropdown.scss';

type Props = {
  options: FilterOption[];
  withSelectAllOption?: boolean;
  width?: 'full' | 'fit-box';
  icon?: string;
  showIcon?: boolean;
  selectedOptions: FilterOption[];
  handleSelectAll?: () => void;
  handleItemSelect: (option: FilterOption) => void;
};

export const DropdownSelect = ({
  options,
  withSelectAllOption = false,
  width = 'full',
  icon,
  showIcon,
  handleSelectAll,
  handleItemSelect,
  selectedOptions,
}: Props) => (
  <ul className="dropdown-select">
    {withSelectAllOption && (
      <li
        className="dropdown-select__item dropdown-select__item--fixed"
        onClick={handleSelectAll}
      >
        <div className={`dropdown-select__checkbox ${selectedOptions.length === options.length ? 'dropdown-select__checkbox--minus' : ''}`} />
        <span>
          {selectedOptions.length === options.length ? 'Deselect all' : 'Select all'}
        </span>
      </li>
    )}
    {options.map((option) => (
      <li
        key={option.value.toString()}
        className="dropdown-select__item"
        onClick={() => handleItemSelect(option)}
      >
        <MultiSelectOption showIcon={showIcon} icon={icon} width={width} isSelected={selectedOptions.map(o => o.value).includes(option.value)} name={option.name} />
      </li>
    ))}
  </ul>
);
