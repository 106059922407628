import {
  useState,
  useRef,
  useEffect,
  useMemo,
} from 'react';
import { getContactsForContactsBook } from '@/career-advisor-v3/api/contacts-book';
import { ContactType } from '../types/contacts-book';

type Prop = {
  currentTab: string | null;
  isLoading: boolean;
  cohortID?: string;
};

export const useSavedContacts = ({ currentTab, isLoading, cohortID }: Prop) => {
  const [isPageSavedEnd, setIsPageSavedEnd] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [savedContacts, setSavedContacts] = useState<ContactType[]>([]);
  const [totalContactsCountSaved, setTotalContactsCountSaved] = useState<number>(0);
  const [nextPageSaved, setNextPageSaved] = useState(1);
  const [currentPageSaved, setCurrentPageSaved] = useState(1);
  const [isNewPageLoading, setIsNewPageLoading] = useState(false);
  const [currentActiveId, setCurrentActiveId] = useState('');
  const observerTargetSaved = useRef<HTMLDivElement>(null);

  const isSavedContactsTabSelected = useMemo(() => currentTab === 'Bookmarked Contacts', [currentTab]);

  const fetchSavedJobs = async () => {
    setNextPageSaved(1);
    setSavedContacts([]);
    setIsPageSavedEnd(false);
    setIsNewPageLoading(true);
    setError(null);

    try {
      const response = await getContactsForContactsBook(cohortID, 1, true);

      setTotalContactsCountSaved(response.bookmarked_count);

      if (!response.contacts || !response.contacts?.length) {
        setIsPageSavedEnd(true);
        return;
      }

      setSavedContacts(response.contacts);
    } catch (err) {
      setError(err as Error);
    } finally {
      setIsNewPageLoading(false);
      setCurrentPageSaved(1);
    }
  };

  const fetchNextPageDataSaved = async () => {
    if (isSavedContactsTabSelected && isPageSavedEnd) {
      return;
    }

    setIsNewPageLoading(true);
    setError(null);

    try {
      const response = await getContactsForContactsBook(cohortID, nextPageSaved, true);

      setTotalContactsCountSaved(response.bookmarked_count);

      if (!response.contacts || !response.contacts?.length) {
        setIsPageSavedEnd(true);
      }

      const newJobs = [...savedContacts, ...response.contacts];

      setSavedContacts(newJobs);
    } catch (err) {
      setError(err as Error);
    } finally {
      setIsNewPageLoading(false);
      setCurrentPageSaved(nextPageSaved);
    }
  };

  useEffect(() => {
    if (isSavedContactsTabSelected && nextPageSaved > currentPageSaved && !isNewPageLoading && !isPageSavedEnd) {
      fetchNextPageDataSaved();
    }
  }, [nextPageSaved, currentPageSaved, isNewPageLoading, isPageSavedEnd]);

  useEffect(() => {
    fetchSavedJobs();
  }, [cohortID]);

  useEffect(() => {
    let observerSaved: IntersectionObserver | null = null;

    if (!observerSaved && observerTargetSaved.current) {
      observerSaved = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            setNextPageSaved((prevPage) => prevPage + 1);
          }
        },
        { threshold: 1 },
      );
      observerSaved.observe(observerTargetSaved.current);
    }

    return () => {
      if (observerSaved && observerTargetSaved.current) {
        observerSaved.unobserve(observerTargetSaved.current);
      }
    };
  }, [observerTargetSaved.current, savedContacts.length, currentTab, isSavedContactsTabSelected, isLoading]);

  return {
    savedContacts,
    observerTargetSaved,
    isNewPageLoading,
    error,
    currentActiveId,
    totalContactsCountSaved,
    isPageSavedEnd,
    setCurrentActiveId,
    setTotalContactsCountSaved,
    setSavedContacts,
  };
};
