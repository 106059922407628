import React from 'react';
import { NavbarItemName } from '../types/types';

export type NavbarContextType = {
  unreadTabs: NavbarItemName[];
  addUnreadTab: (tab: NavbarItemName) => void;
  removeUnreadTab: (tab: NavbarItemName) => void;
};

// eslint-disable-next-line import/no-mutable-exports
let TrackerContext: any;
// eslint-disable-next-line no-multi-assign
const { Provider } = (TrackerContext = React.createContext<any>(null));

export { TrackerContext, Provider };
