import { Badge } from '@careeros/coco';
import './skills.scss';

type Props = {
  skills: string[];
};

export const Skills = ({ skills }: Props) => (
  <div className="skills">
    <div className="skills__title">Skills</div>
    <div className="skills__list">
      {skills?.map((skill) => (
        <Badge key={skill} label={skill} color="gradient" />
      ))}
    </div>
  </div>
);
