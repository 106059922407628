import { useEffect, useRef } from 'react';

import './dropdown-list.scss';

type Props = {
  options: {
    label: string | JSX.Element;
    icon?: string;
    image?: string;
    onClick: () => Promise<void> | void;
  }[];
  handleClose: () => void;
  parentRef?: React.RefObject<HTMLDivElement>;
};

export const DropdownList = ({ options, parentRef, handleClose }: Props) => {
  const dropdownRef = useRef<HTMLUListElement>(null);

  const handleBlur = (e: any) => {
    const { target } = e;

    if (!dropdownRef.current?.contains(target) && !parentRef?.current?.contains(target)) {
      handleClose();
    }
  };

  const handleOptionClick = (e: React.MouseEvent, option: { onClick: () => Promise<void> | void }) => {
    e.stopPropagation();
    option.onClick();
    handleClose();
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleBlur);

    return () => document.removeEventListener('mousedown', handleBlur);
  }, []);

  return (
    <ul tabIndex={-1} ref={dropdownRef} className="dropdown-list">
      {options.map((option) => (
        <li
          key={option.label?.toString()}
          className="dropdown-list__item"
          onClick={(e) => handleOptionClick(e, option)}
        >
          {option.icon && <i className={`dropdown-list__item-icon ${option.icon}`} />}
          {option.image && <img className="dropdown-list__image" src={option.image} alt={option.label?.toString()} />}
          <span>{option.label}</span>
        </li>
      ))}
    </ul>
  );
};
