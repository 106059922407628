import { useEffect, useState } from 'react';
import { Badge, RangeBadge } from '@/components/badge/badge';
import { OverallLengthSection } from '../../../types';
import { ResumeBanner } from '../../resume-banner/resume-banner';

type OverallLengthSectionProps = {
  over?: OverallLengthSection;
  showBanner?: boolean;
};

export const SectionOverallLength = ({ over, showBanner = true }: OverallLengthSectionProps) => {
  const [numberOfPositionsBannerVisible, setNumberOfPositionsBannerVisible] = useState(true);
  const [numberOfTotalBulletsVisible, setNumberOfTotalBulletsVisible] = useState(true);
  const [numberOfTotalWordsVisible, setNumberOfTotalWordsVisible] = useState(true);

  const computeBackgroundColor = (value: number | undefined) => {
    if (value === undefined) return 'medium-grey';
    if (value === 0 || value > 8) return 'low-percentage-color';
    if (value === 1 || value === 7 || value === 8) return 'medium-percentage-color';
    return 'high-percentage-color';
  };

  useEffect(() => {
    setNumberOfPositionsBannerVisible(true);
    setNumberOfTotalBulletsVisible(true);
    setNumberOfTotalWordsVisible(true);
  }, [over]);

  return (
    <div className="score-info-improvement">
      <div className="score-info-improvement__row">
        <div className="score-info-improvement__progress">
          <Badge color={computeBackgroundColor(over?.number_of_positions.value)} label={`${over?.number_of_positions.value}`} />
        </div>
        <div className="score-info-improvement__label">
          Number of positions
        </div>
      </div>
      {showBanner && (
        <div className="score-info-improvement__row score-info-improvement__row--full">
          {numberOfPositionsBannerVisible && <ResumeBanner title="" text={over?.number_of_positions.feedback} fragments={over?.number_of_positions.fragments} isClosable handleClose={() => setNumberOfPositionsBannerVisible(false)} />}
        </div>
      )}
      <div className="score-info-improvement__row">
        <div className="score-info-improvement__progress">
          <RangeBadge label={`${over?.number_of_total_bullets.value}`} percentageOfCompletion={over?.number_of_total_bullets.percentage_score || 0} rangeColors={['#ff8585', '#ffe59e', '#7bcd8c']} />
        </div>
        <div className="score-info-improvement__label">
          Number of total bullets
        </div>
      </div>
      {showBanner && (
        <div className="score-info-improvement__row score-info-improvement__row--full">
          {numberOfTotalBulletsVisible && <ResumeBanner title="" text={over?.number_of_total_bullets.feedback} fragments={over?.number_of_total_bullets.fragments} isClosable handleClose={() => setNumberOfTotalBulletsVisible(false)} />}
        </div>
      )}
      <div className="score-info-improvement__row">
        <div className="score-info-improvement__progress">
          <RangeBadge label={`${over?.number_of_total_words.value}`} percentageOfCompletion={over?.number_of_total_words.percentage_score || 0} rangeColors={['#ff8585', '#ffe59e', '#7bcd8c']} />
        </div>
        <div className="score-info-improvement__label">
          Number of total words
        </div>
      </div>
      {showBanner && (
        <div className="score-info-improvement__row score-info-improvement__row--full">
          {numberOfTotalWordsVisible && <ResumeBanner title="" text={over?.number_of_total_words.feedback} fragments={over?.number_of_total_words.fragments} isClosable handleClose={() => setNumberOfTotalWordsVisible(false)} />}
        </div>
      )}
    </div>
  );
};
