import {
  useState,
  useRef,
  useEffect,
  useMemo,
} from 'react';
import {
  getJobsLanguages,
  getJobsLocations,
  searchJobs,
} from '../api/job';
import { JobFeedJob, JobsFilterState, JobsLocationsType } from '@/domains/core/job/types/job';
import useDebounceEffect from './use-debounce';
import { useAnalytics } from './use-analytics';
import { getSearchParamsString, updateQueryString } from '@/domains/core/job/helpers/convertURLParamsToFilters';

type Props = {
  currentTab: string | null;
};

export const useJobsFiltering = ({ currentTab }: Props) => {
  const [isPageEnd, setIsPageEnd] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [jobs, setJobs] = useState<JobFeedJob[]>([]);
  const [totalJobsCount, setTotalJobsCount] = useState<number>(0);
  const [nextPage, setNextPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [isNewPageLoading, setIsNewPageLoading] = useState(false);
  const [currentActiveId, setCurrentActiveId] = useState('');
  const [jobsLocations, setJobsLocations] = useState<JobsLocationsType[]>([]);
  const [jobsLanguages, setJobsLanguages] = useState<string[]>([]);
  const [currentLoadingId, setCurrentLoadingId] = useState('');
  const observerTarget = useRef<HTMLDivElement>(null);
  const defaultFilters = {
    query: '',
    sizes: [],
    job_types: [],
    work_modes: [],
    industries: [],
    locations: [],
    date_range: [],
    languages: [],
    favoriteCompanies: false,
  };
  const [filters, setFilters] = useState<JobsFilterState>(defaultFilters);
  const { trackEvent } = useAnalytics();
  const isSmallScreen = () => window.innerWidth <= 720;

  const getLocations = async () => {
    const locations = await getJobsLocations();
    setJobsLocations(locations);
  };

  const getLanguages = async () => {
    const languages = await getJobsLanguages();
    setJobsLanguages(languages);
  };

  const searchQuery = useMemo(() => getSearchParamsString(filters).toString(), [filters]);
  const convertSearchForTrackerEvent = searchQuery.split('&').map(param => param.split('='));
  const isSavedJobsTabSelected = useMemo(() => currentTab === 'Saved Jobs', [currentTab]);

  const updateCurrentActiveIdBasedOnScreen = (defaultId: string) => {
    if (!isSmallScreen()) {
      setCurrentActiveId(defaultId);
    } else {
      setCurrentActiveId('');
    }
  };

  const fetchNextPageData = async () => {
    if (!isSavedJobsTabSelected && isPageEnd) {
      return;
    }

    setIsNewPageLoading(true);
    setError(null);

    try {
      const { jobs: jobsResponse, total } = await searchJobs(searchQuery, nextPage);

      setTotalJobsCount(total);

      if (!jobsResponse || !jobsResponse?.length) {
        setIsPageEnd(true);
        return;
      }

      const newJobs = [...jobs, ...jobsResponse];

      if (!currentActiveId && jobsResponse.length > 0) {
        updateCurrentActiveIdBasedOnScreen(jobsResponse[0].id);
      }

      setJobs(newJobs);
    } catch (err) {
      setError(err as Error);
    } finally {
      setIsNewPageLoading(false);
      setCurrentPage(nextPage);
    }
  };

  const fetchJobsWithFilters = async () => {
    setNextPage(1);
    setJobs([]);
    setIsPageEnd(false);
    setIsNewPageLoading(true);
    setError(null);
    setIsLoading(true);

    try {
      const { jobs: jobsResponse, total } = await searchJobs(searchQuery, 1);

      setTotalJobsCount(total);

      if (!jobsResponse || !jobsResponse?.length) {
        setIsPageEnd(true);
        setJobs([]);
        return;
      }

      if (jobsResponse.length > 0) {
        updateCurrentActiveIdBasedOnScreen(jobsResponse[0].id);
      }

      setJobs(jobsResponse);
      convertSearchForTrackerEvent.forEach(([key, value]) => {
        if (key && value) {
          trackEvent('Filter applied', {}, { filter_type: key, filter_value: value });
        }
      });

      // Extract the actual search query from the URL query string
      const searchParams = new URLSearchParams(searchQuery);
      const queryValue = searchParams.get('query');

      if (queryValue) {
        trackEvent('Job title searched', {}, { search: queryValue });
      }
    } catch (err) {
      setError(err as Error);
    } finally {
      setIsNewPageLoading(false);
      setIsLoading(false);
      setCurrentPage(1);
    }
  };

  useEffect(() => {
    const searchString = getSearchParamsString(filters);
    updateQueryString(searchString);
  }, [filters]);

  useEffect(() => {
    if (!isSavedJobsTabSelected && nextPage > currentPage && !isNewPageLoading && !isPageEnd) {
      fetchNextPageData();
    }
  }, [nextPage, currentPage, isNewPageLoading, isPageEnd]);

  useDebounceEffect(fetchJobsWithFilters, [searchQuery]);

  useEffect(() => {
    getLocations();
    getLanguages();
  }, []);

  useEffect(() => {
    let observer: IntersectionObserver | null = null;

    if (!observer && observerTarget.current) {
      observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            setNextPage((prevPage) => prevPage + 1);
          }
        },
        { threshold: 1 },
      );
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observer && observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget.current, jobs.length, isSavedJobsTabSelected]);

  return {
    jobs,
    filters,
    observerTarget,
    isNewPageLoading,
    isLoading,
    error,
    currentActiveId,
    jobsLocations,
    jobsLanguages,
    totalJobsCount,
    currentLoadingId,
    isPageEnd,
    setFilters,
    setCurrentActiveId,
    setCurrentLoadingId,
    setTotalJobsCount,
    setJobs,
  };
};
