export enum NavbarItemName {
  Dashboard = 'Dashboard',
  Students = 'Students',
  Insights = 'Insights',
  Admin = 'Admin',
  Home = 'Home',
  OverviewBoard = 'Overview Board',
  Companies = 'Companies',
  Contacts = 'Contacts',
  Inbox = 'Inbox',
  ChromeExtension = 'Chrome Extension',
  Jobs = 'Jobs',
  ResumeOS = 'ResumeOS',
  ResumeOSStudent = 'ResumeOS (Builder)',
  ContactsBook = 'Contacts Book',
  StudentAppFeatures = 'Student App Features',
}

export type LinkType = {
  label: `${NavbarItemName}`;
  url: string;
  icon: string;
  iconSelected: string;
  children?: Pick<LinkType, 'label' | 'url'>[];
  isActive?: boolean;
  isExternal?: boolean;
  excludedForUniversities?: string[];
  includedForUniversities?: string[];
  order: number;
  isHiddenOnReadState?: boolean;
};
