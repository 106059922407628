import { Button, IconButton } from '@careeros/coco';
import { RangeBadge } from '@/components/badge/badge';
import { Tooltip } from '@/components/tooltip/tooltip';
import { useResumeBuilderContext } from '@/pages/context/resume-builder';

type PreviewControlsProps = {
  toggleHighlights?: () => void;
  improveScore?: () => void;
  changeTemplate?: () => void;
  viewComments?: () => void;
  isDisabled?: boolean;
};

export const PreviewControls = ({
  improveScore, changeTemplate, viewComments, toggleHighlights, isDisabled = false,
}: PreviewControlsProps) => {
  const {
    toggleHighlightsValue, comments, zoomIn, zoomOut, latestScore,
  } = useResumeBuilderContext();

  return (
    <>
      <div className="resume-builder__control-actions resume-builder__control-actions--left">
        <Tooltip label="Zoom out" position="bottom-center">
          <IconButton handleClick={zoomOut} mode="rounded" outlined size="medium" icon="bi bi-zoom-out" />
        </Tooltip>
        <Tooltip label="Zoom in" position="bottom-center">
          <IconButton handleClick={zoomIn} mode="rounded" outlined size="medium" icon="bi bi-zoom-in" />
        </Tooltip>
        {toggleHighlights && (
          <Tooltip label={toggleHighlightsValue ? 'Hide scoring highlights' : 'Show scoring highlights'} position="bottom-center">
            <IconButton handleClick={toggleHighlights} mode="rounded" outlined size="medium" icon={toggleHighlightsValue ? 'bi bi-eye-slash' : 'bi bi-eye'} />
          </Tooltip>
        )}
      </div>
      <div className={`resume-builder__control-actions resume-builder__control-actions--center ${isDisabled ? 'resume-builder__control-actions--disabled' : ''}`} onClick={improveScore}>
        {isDisabled ? (
          <Tooltip label="Fill out all required fields under “Confirm Contents” on the left to reveal your score." position="bottom-center">
            <>
              <span className="resume-builder__score">Score</span>
              {' '}
              <RangeBadge label={`${latestScore || '0'}`} percentageOfCompletion={latestScore || 0} rangeColors={['#ff8585', '#ffe59e', '#7bcd8c']} isDisabled={isDisabled} />
            </>
          </Tooltip>
        ) : (
          <>
            <span className="resume-builder__score">Score</span>
            {' '}
            <RangeBadge label={`${latestScore || '0'}`} percentageOfCompletion={latestScore || 0} rangeColors={['#ff8585', '#ffe59e', '#7bcd8c']} isDisabled={isDisabled} />
          </>
        )}
      </div>
      <div className="resume-builder__control-actions resume-builder__control-actions--right">
        {improveScore && <Button handleClick={improveScore} mode="invisible" size="medium" label="Improve Score" icon="bi bi-speedometer2" disabled={isDisabled} />}
        {changeTemplate && <Button handleClick={changeTemplate} mode="invisible" size="medium" icon="bi bi-layout-text-window" label="Change Template" disabled={isDisabled} />}
        {Array.isArray(comments) && comments.length > 0 && viewComments && <Button handleClick={viewComments} mode="invisible" size="medium" icon="bi bi-chat-left-dots" label="View Comments" disabled={isDisabled} />}
      </div>
    </>
  );
};
