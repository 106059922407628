export const sentryIngnoredErrors = [
  'Error: failed to get CSRF token from cookie', 'Login required', 'Non-Error promise rejection captured with value: {"error":"Failed to validate JWT token"}', 'got undefined response after sending message to extension', 'fetch self profile failed: 403', 'Non-Error promise rejection captured with value: Failed to fetch', 'undefined is not an object (evaluating \'a.globalStyling\')',
  'Non-Error promise rejection captured with value: Object Not Found Matching Id:5, MethodName:simulateEvent, ParamCount:1', 'Load failed',
  'Non-Error promise rejection captured with value: Object Not Found Matching Id:5, MethodName:update, ParamCount:4',
  'Non-Error promise rejection captured with value: Object Not Found Matching Id:3, MethodName:update, ParamCount:4',
  'Non-Error promise rejection captured with value: Object Not Found Matching Id:4, MethodName:simulateEvent, ParamCount:1',
  'Failed to fetch', 'Object captured as exception with keys: message', "Cannot read properties of undefined (reading 'csid')",
  'Firebase: Error (auth/network-request-failed).',
];

export const ignoredSources = [
  // Appcues
  'appcues.js',
  // Hotjar
  'hotjar',
  'static.hotjar.com',
  // Customer.io
  'customer.io',
  'assets.customer.io',
  'track-eu.js',
  // Common third-party domains/paths
  '_cio',
  'hotjar-',
];
