/* eslint-disable react/no-array-index-key */
import { DefaultLogo } from '@/components/default-logo/default-logo';
import { EducationStudentProfileType } from '../../types/types';
import './education.scss';

type Props = {
  education: EducationStudentProfileType[];
};

export const Education = ({ education }: Props) => (
  <div className="education">
    <div className="education__title">Education</div>
    <div className="education__list">
      {education?.map((school) => (
        <div className="education__item" key={school.id}>
          <DefaultLogo
            type="company"
            source={school.institution_icon_url}
            name={school.institution_name}
            className="education__item-icon"
            size={40}
          />
          <div className="education__item-content">
            <div className="education__item-title">{school.institution_name}</div>
            <div className="education__item-company">{school.degree}</div>
            <div className="education__item-dates">
              {`${school.start_date} - ${school.end_date}`}
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
);
