import './collapse-button.scss';

type Props = {
  handleCollapse: () => void;
  isCollapsed: boolean;
};

export const NavbarCollapse = ({ handleCollapse, isCollapsed }: Props) => (
  <div
    role="button"
    onClick={handleCollapse}
    className="sidebar-collapse"
    aria-label="Collapse Sidebar"
    tabIndex={0}
  >
    <div
      className={`sidebar-collapse__container ${isCollapsed ? 'sidebar-collapse__container--collapsed' : ''}`}
    >
      <i
        className={`sidebar-collapse__icon bi bi-arrow-bar-${isCollapsed ? 'right' : 'left'}`}
      />
      <span className="sidebar-collapse__label">
        {isCollapsed ? 'Expand' : 'Collapse'}
      </span>
    </div>
  </div>
);
