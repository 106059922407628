import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Loader } from '@/components/loader/loader';
import './styles/student-overview.scss';
import { DocumentTable } from '@/domains/core/student';
import CustomWithAuthenticationRequired from '../../pages/auth/custom-protected-route';
import { StudentOBHeader } from '../components/student-OB-header/student-OB-header';
import { TabsContent, TabsHeader, withTabs } from '@/components/tabs';
import { CACommentInputProvider } from '../contexts/CA-comment-input/CA-comment-input';
import { StudentContextProvider } from '../contexts/student-context/student-context-provider';
import { StudentOverviewActions } from '../components/student-overview-actions/student-overview-actions';
import { StudentNotesTab } from '../components/student-notes-tab/student-notes-tab';
import { StudentResumesTab } from '../components/student-resumes-tab/student-resumes-tab';
import { StudentsProfile } from '@/domains/core/profile/profile';
import { StudentOBTab } from '../components/student-OB-tab/student-OB-tab';
import {
  useAISummaryQuery,
  useGetAllStudentResumesQueries, useStudentApplications, useStudentByID, useStudentComments, useStudentDocuments,
} from '@/services/queries/student';
import { useStudentNotes } from '../queries/advisor';
import { StudentContactsTab } from '../components/student-contacts-tab/student-contacts';

function StudentOverviewPage() {
  const { studentID } = useParams();
  const { user } = useAuth0();
  const { data: commentsResponse } = useStudentComments(studentID, { enabled: !!studentID });
  const { data: student, isLoading: isStudentLoading } = useStudentByID(studentID || '', { enabled: !!studentID });
  const { data: studentDocuments } = useStudentDocuments(studentID || '', { enabled: !!studentID });
  const { data: studentApplications, isLoading: isApplicationsLoading } = useStudentApplications(studentID || '', { enabled: !!studentID });
  const { data: studentBaseResumes, isLoading: isStudentBaseResumesLoading } = useGetAllStudentResumesQueries(studentID ?? '', {
    enabled: !!studentID,
  });
  const { data: notes } = useStudentNotes(studentID ?? '', {
    enabled: !!studentID,
  });
  const [isBetaUser, setIsBetaUser] = useState(false);
  const { data: AISummary, isLoading: isAISummaryLoading } = useAISummaryQuery(studentID || '', { enabled: !!studentID });
  const tabCounters = useMemo(() => ({
    Resumes: studentBaseResumes?.length || 0,
    Notes: notes?.length || 0,
    Documents: studentDocuments?.length || 0,
  }), [studentBaseResumes, studentDocuments, notes]);

  const tabs = useMemo(() => [
    'Overview Board',
    ...(isBetaUser ? ['Contacts'] : []),
    ...(isBetaUser ? ['Profile'] : []),
    'Resumes',
    'Notes',
    'Documents',
  ], [isBetaUser]);

  const icons = useMemo(() => [
    'bi bi-kanban',
    ...(isBetaUser ? ['bi-person-vcard'] : []),
    ...(isBetaUser ? ['bi bi-person-square'] : []),
    'bi bi-file-earmark-text',
    'bi bi-file-earmark',
    'bi bi-filetype-doc',
  ], [isBetaUser]);

  useEffect(() => {
    if (user) {
      const roles = user['https://careeros.com/roles'];
      setIsBetaUser(roles.includes('BetaUser'));
    }
  }, [user]);

  if (isStudentLoading || isApplicationsLoading) {
    return (
      <div id="loader-zone">
        <Loader />
      </div>
    );
  }
  return (
    <div className="student-overview">
      <StudentContextProvider student={student}>
        <CACommentInputProvider>
          <div className="student-overview__top">
            <div className="student-overview__header">
              <StudentOBHeader
                name={`${student?.first_name} ${student?.last_name}`}
                email={student?.email}
                profileImage={student?.profile_image}
                university={student?.university}
                cohort={student?.cohort}
                AISummary={AISummary?.message}
                isAISummaryLoading={isAISummaryLoading}
              />
              <div className="student-overview__header-actions">
                <StudentOverviewActions comments={commentsResponse || []} />
              </div>
            </div>

            <div className="student-overview__tabs">
              <TabsHeader
                tabs={tabs}
                defaultTab="Overview Board"
                icons={icons}
                withBorder
                tabCounters={tabCounters}
              />
            </div>
          </div>
          <TabsContent>
            <div data-tab="Documents" className="student-overview__tab-content">
              <DocumentTable documents={studentDocuments || []} accessType="advisor" />
            </div>
            {isBetaUser && (
            <div data-tab="Profile" className="student-overview__tab-content">
              <StudentsProfile id={student.id} />
            </div>
            )}
            {isBetaUser && (
            <div data-tab="Notes" className="student-overview__tab-content">
              <StudentNotesTab studentId={student.id} notes={notes || []} />
            </div>
            )}
            {isBetaUser && (
            <div data-tab="Contacts" className="student-overview__overview-tab">
              <StudentContactsTab studentID={student.id} studentName={student.first_name} />
            </div>
            )}
            <div data-tab="Resumes" className="student-overview__tab-content">
              <StudentResumesTab
                studentID={student.id}
                studentName={student.first_name}
                resumes={studentBaseResumes || []}
                isLoading={isStudentBaseResumesLoading}
              />
            </div>
            <div className="student-overview__overview-tab" data-tab="Overview Board">
              <StudentOBTab
                studentFirstName={student.first_name}
                studentLastName={student.last_name}
                tiles={studentApplications || []}
                studentID={student.id}
              />
            </div>
          </TabsContent>
        </CACommentInputProvider>
      </StudentContextProvider>
    </div>
  );
}

export default CustomWithAuthenticationRequired(withTabs(StudentOverviewPage, {
  onRedirecting: () => (
    <div id="loader-zone">
      <Loader />
    </div>
  ),
}));
