import { useMemo, useState } from 'react';
import * as Sentry from '@sentry/react';
import { useQueryClient } from 'react-query';
import { Badge } from '@/components/badge/badge';
import { AvatarsGroup } from '../avatars-group/avatars-group';
import { StudentActionsButton } from '../student-actions-button/student-actions-button';
import './student-tile.scss';
import { DefaultLogo } from '@/components/default-logo/default-logo';
import { MiniAdvisor, Person } from '@/career-advisor-v3/types';
import { FilterOption } from '@/domains/core/company/types';
import { toggleAdvisorAssigment } from '@/career-advisor-v3/api/advisor';

type Props = {
  id: string;
  title: string;
  image: string;
  subtitle?: string;
  handleTileClick: (id: string) => void;
  universityId: string;
  // isChanged?: boolean;
  allAdvisors: MiniAdvisor[];
  assignedAdvisors: MiniAdvisor[];
};

export const StudentKanbanTile = ({
  title,
  image,
  subtitle,
  handleTileClick,
  id,
  universityId,
  // isChanged = false,
  assignedAdvisors,
  allAdvisors,
}: Props) => {
  const queryClient = useQueryClient();

  const mapMiniAdvisorsToPerson = (advisors: MiniAdvisor[]): Person[] => advisors.map((advisor) => ({
    id: advisor.id,
    name: advisor.firstName,
    avatar: advisor.profileImage,
  }));

  const mapMiniAdvisorsToFilterOptions = (advisors: MiniAdvisor[]): FilterOption[] => advisors.map((advisor) => ({
    name: advisor.firstName,
    value: advisor.id,
  }));

  const [selectedAdvisors, setSelectedAdvisors] = useState<Person[]>(mapMiniAdvisorsToPerson(assignedAdvisors));

  const fullAdvisorsList = useMemo(() => mapMiniAdvisorsToFilterOptions(allAdvisors), [allAdvisors]);

  const handleAdvisorAssignment = async (element: FilterOption) => {
    try {
      const selectedAdvisor = allAdvisors.find((advisor) => advisor.id === element.value);
      const isAlreadySelected = selectedAdvisors.find(a => a.id === element.value);

      if (isAlreadySelected) {
        setSelectedAdvisors((prev) => prev.filter((a) => a.id !== element.value));
      } else {
        setSelectedAdvisors((prev) => [...prev, ...mapMiniAdvisorsToPerson([selectedAdvisor as MiniAdvisor])]);
      }

      await toggleAdvisorAssigment(id, element.value as string);
      queryClient.invalidateQueries(['allStudentsList']);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const onClick = () => {
    handleTileClick(id);
  };

  return (
    <div className="student-kanban-tile" onClick={onClick}>
      <div className="student-kanban-tile__top">
        <div className="student-kanban-tile__header">
          <div className="student-kanban-tile__avatar">
            <DefaultLogo
              type="contact"
              source={image}
              className="student-kanban-tile__avatar"
              name={title}
              size={48}
            />
          </div>
          <div className="student-kanban-tile__info">
            <div className="student-kanban-tile__name">{title}</div>
            {subtitle && <div className="student-kanban-tile__subtitle">{subtitle}</div>}
          </div>
        </div>
      </div>
      <div className="student-kanban-tile__separator" />
      <div className="student-kanban-tile__bottom">
        <AvatarsGroup
          personList={selectedAdvisors}
          fullList={fullAdvisorsList}
          handleSelectOption={handleAdvisorAssignment}
          addButtonTooltip="Assign an Advisor"
          emptyStateTooltip="Assign an Advisor"
        />
        {(title === 'Maxito Stengel' || title === 'Marcus Castro' || title === 'Linh Le') && <Badge color="blue-outline" label="Changed since last view" />}
        <StudentActionsButton studentId={id} universityId={universityId} />
      </div>
    </div>
  );
};
