import './simple-info-modal.scss';

type Props = {
  orientation?: 'horizontal' | 'vertical';
  icon: string;
  title: string;
  subtitle: string;
  description: string | JSX.Element | JSX.Element[];
};

export const SimpleInfoModal = ({
  orientation = 'vertical',
  icon,
  title,
  subtitle,
  description,
}: Props) => (
  <div className={`simple-info-modal simple-info-modal--${orientation}`}>
    {icon && (
    <div className="simple-info-modal__image">
      <i className={icon} />
    </div>
    )}
    <div className="simple-info-modal__text">
      <h3 className="simple-info-modal__title">{title}</h3>
      <p className="simple-info-modal__subtitle">{subtitle}</p>
      <div className="simple-info-modal__description">{description}</div>
    </div>
  </div>
);
