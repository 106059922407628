import { ApiStudent } from '@/career-advisor-v3/types/api/student';
import { convertFromApiStatus } from '@/career-advisor-v3/api/status';
import { convertFromApiMiniAdvisors } from '@/career-advisor-v3/api/model/advisor';
import { Student } from '@/career-advisor-v3/types';

export const convertFromApiStudent = (apiStudent: ApiStudent): Student => ({
  id: apiStudent.id,
  firstName: apiStudent.first_name,
  lastName: apiStudent.last_name,
  email: apiStudent.email,
  profileImage: apiStudent.profile_picture_url,
  status: convertFromApiStatus(apiStudent.status),
  cohort: apiStudent.cohort,
  assignedAdvisors: apiStudent.assigned_advisors ? convertFromApiMiniAdvisors(apiStudent.assigned_advisors) : [],
  tags: apiStudent.tags,
  lastActivityAt: apiStudent.last_activity_at,
});

export const convertFromApiStudents = (apiStudents: ApiStudent[]): Student[] => apiStudents.map(convertFromApiStudent);
