import { IconButton } from '@careeros/coco';
import { useMemo, useState } from 'react';
import './avatars-group.scss';
import { DefaultLogo } from '@/components/default-logo/default-logo';
import { Tooltip } from '@/components/tooltip/tooltip';
import EmptyAvatarImage from '@/assets/images/man-gesturing-no.png';
import { FilterOption } from '@/domains/core/company/types';
import { PopupBoxWrapper } from '@/domains/generic/popup-box/popup-box';
import { DropdownSelect } from '@/components/select-dropdown/select-dropdown';
import { Person } from '@/career-advisor-v3/types';

type Props = {
  fullList?: FilterOption[];
  personList: Person[];
  maxAvatars?: number;
  addButtonTooltip?: string;
  withAddButton?: boolean;
  handleSelectOption?: (option: FilterOption) => void;
  emptyStateTooltip?: string;
};

// TODO: move to coco
export const AvatarsGroup = ({
  personList,
  maxAvatars = 4,
  addButtonTooltip,
  handleSelectOption,
  withAddButton = false,
  fullList = [],
  emptyStateTooltip,
}: Props) => {
  const visibleAvatars = useMemo(() => {
    if (personList.length <= maxAvatars) {
      return personList;
    }
    return personList.slice(0, maxAvatars - 1);
  }, [personList, maxAvatars]);

  const remainingNames = useMemo(() => {
    if (personList.length <= maxAvatars) {
      return [];
    }
    return personList.flatMap((p) => p.name).slice(maxAvatars - 1);
  }, [personList, maxAvatars]);

  const mappedSelectedNameOptions = useMemo<FilterOption[]>(() => personList.map((p) => ({ name: p.name, value: p.id })), [personList]);

  const [isFullListOpen, setIsFullListOpen] = useState(false);

  const openFullListModal = () => {
    if (!fullList.length) return;
    setIsFullListOpen(true);
  };

  const handleClick:React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    openFullListModal();
  };

  const closeListModal = () => {
    setIsFullListOpen(false);
  };

  const handleSelectOptionFromList = (option: FilterOption) => {
    if (handleSelectOption) {
      handleSelectOption(option);
    }
    closeListModal();
  };

  return (
    <div className="avatars-group" onClick={handleClick}>
      {visibleAvatars.map((person) => (
        <div className="avatars-group__item">
          <Tooltip key={person.name} label={person.name} withArrow position="top">
            <DefaultLogo
              className="avatars-group__avatar"
              source={person.avatar}
              name={person.name}
              size={24}
              type="contact"
            />
          </Tooltip>
        </div>
      ))}

      {(personList.length > maxAvatars) && (
        <Tooltip label={remainingNames.join(', ')} withArrow position="top">
          <div className="avatars-group__avatar avatars-group__avatar--extra">
            <span>
              {`+${personList.length - maxAvatars + 1}`}
            </span>
          </div>
        </Tooltip>
      )}

      {(withAddButton && personList.length === 0) && (
        <div className="avatars-group__avatar avatars-group__avatar--add">
          <Tooltip label={addButtonTooltip || ''} withArrow position="top" disableHoverListener={!addButtonTooltip}>
            <IconButton
              icon="bi bi-person-add"
              mode="rounded"
              size="small"
              outlined
              label={addButtonTooltip}
              handleClick={openFullListModal}
            />
          </Tooltip>
        </div>
      )}

      {(!withAddButton && personList.length === 0) && (
        <Tooltip label={emptyStateTooltip || ''} position="top" withArrow disableHoverListener={!emptyStateTooltip}>
          <div className="avatars-group__placeholder">
            <img src={EmptyAvatarImage} alt="Man gesturing no" className="avatars-group__image" />
          </div>
        </Tooltip>
      )}

      {isFullListOpen && handleSelectOption && (
        <div className="avatars-group__dropdown-container">
          <PopupBoxWrapper handleBlur={closeListModal}>
            <div className="avatars-group__dropdown">
              <DropdownSelect
                options={fullList}
                handleItemSelect={handleSelectOptionFromList}
                selectedOptions={mappedSelectedNameOptions}
              />
            </div>
          </PopupBoxWrapper>
        </div>
      )}
    </div>
  );
};
