import './contacts-book-not-found.scss';

export const ContactsNotFound = () => (
  <div className="contacts-book-not-found">
    <div className="contacts-book-not-found__container">
      <div className="contacts-book-not-found__image">&#129335;</div>
      <div className="contacts-book-not-found__title">
        We could not find any results for your search.
      </div>
    </div>
  </div>
);
