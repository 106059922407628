/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  useRef,
  useState,
  useContext,
} from 'react';
import ReactQuill from 'react-quill';
import { Button } from '@careeros/coco';
import useDebounceEffect from '@/services/hooks/use-debounce';
import {
  findTextInBrackets,
  transformEmailHTML,
} from '@/services/helpers/messages';
import { EditorToolbar } from '@/domains/core/contact/components/editor-toolbar/editor-toolbar';
import { AddToastType } from '@/domains/generic/toasts/types';
import { ToastContext } from '@/components/toast/toast-provider';
import 'react-quill/dist/quill.snow.css';
import './email-box.scss';
import '@/services/helpers/quill-config';
import {
  StudentStatus,
} from '../../types';
import { ModalContext } from '@/components/modal/modal-provider';
import { CustomTemplatesModal } from '@/domains/core/netwoking/components';
import { stripHTML } from '@/services/helpers/processHtml';
import { AITemplatesButton } from '@/domains/core/student/components/use-ai-button/templates-button';
import { GenerateCommentOptions } from '@/career-advisor-v3/constants/templates';
import { Tooltip } from '@/components/tooltip/tooltip';
import { InfoModal } from '@/domains/generic/modals';

type EmailBoxProps = {
  handleSend: (message: string, subject: string) => void;
  setIsBoxOpened: (value: boolean) => void;
  column: StudentStatus | null;
};

export const EmailBox = ({
  handleSend,
  setIsBoxOpened,
  column,
}: EmailBoxProps) => {
  const { openModal, closeModal } = useContext(ModalContext) as any;
  const reactQuillRef = useRef<ReactQuill | null>(null);
  const reactQuillSubjectRef = useRef<ReactQuill | null>(null);
  const [subject, setSubject] = useState<string>('');
  const [message, setMessage] = useState('');
  const [isBoxMinimized, setIsBoxMinimized] = useState(false);
  const { addToast }: AddToastType = useContext(ToastContext);

  const toggleMinimize = () => setIsBoxMinimized(!isBoxMinimized);

  const applyTemplate = (template: string, subjectTemplate?: string) => {
    closeModal();
    setMessage(template);

    if (subjectTemplate) {
      setSubject(stripHTML(subjectTemplate));
    } else {
      setSubject('');
    }
  };

  const applyAITemplate = (template: string) => {
    closeModal();
    setMessage(template);
  };

  const handleCustomTemplatesClick = () => {
    openModal(
      <CustomTemplatesModal
        contactPlaceholders={{}}
        previewType="email"
        contactEmail={`${column} Students`}
        selectTemplate={applyTemplate}
      />,
      {
        closeOnClickOutside: false,
      },
    );
  };

  const checkTextForPlaceholders = () => {
    const hasPlaceholders = message.match(/\[[^[\]]*\]/g) || subject.match(/\[[^[\]]*\]/g);

    if (hasPlaceholders) {
      addToast({
        type: 'error',
        message: 'Please fill in all placeholders',
        additionalMessage:
          'Please fill in all placeholders before sending the email.',
      });
    }

    return hasPlaceholders;
  };

  const handleClose = () => {
    setIsBoxOpened(false);
    setSubject('');
    setMessage('');
  };

  const handleSendClick = () => {
    const havePlaceholders = checkTextForPlaceholders();
    if (havePlaceholders) return;
    const messageSubject = reactQuillSubjectRef.current?.editor?.getText().trim();
    if (message.length && messageSubject?.length) {
      handleSend(transformEmailHTML(message), messageSubject);
    } else {
      addToast({
        type: 'error',
        message: 'Please fill in all fields',
        additionalMessage:
          'You cannot send an empty message or with an empty subject.',
      });
    }
  };

  const handleSubmitCommentRemoval = () => {
    handleClose();
    closeModal();
  };

  const handleEmailClose = () => {
    const messageLength = reactQuillRef.current?.getEditor().getText().trim().length || 0;
    const subjectLength = reactQuillSubjectRef.current?.getEditor().getText().trim().length || 0;

    if (messageLength > 0 || subjectLength > 0) {
      openModal(
        <InfoModal
          title="Discard Message"
          description="Looks like you’re about to discard your message. If you discard now, your message will be permanently lost. How about giving it a quick review and sending it off?"
          icon="bi bi-exclamation-triangle"
          buttonLabel="Keep Writing"
          buttonIcon="bi bi-pencil-square"
          secondaryButtonIcon="bi bi-trash"
          secondaryButtonLabel="Delete Message"
          isSecondButtonDanger
          handleButtonClick={closeModal}
          handleSecondaryButtonClick={handleSubmitCommentRemoval}
          isImageDanger
          isButtonOutlined
          orientation="horizontal"
        />,
      );

      return;
    }

    handleClose();
  };

  const highlightPlaceholders = () => {
    if (!reactQuillRef.current) {
      return;
    }
    const quillEditor = reactQuillRef.current.getEditor();
    const bracketedParts = findTextInBrackets(quillEditor.getText());
    quillEditor.formatText(0, quillEditor.getText().length, {
      color: false,
    });
    bracketedParts.forEach((part) => {
      quillEditor.formatText(part.index, part.length, {
        color: 'var(--purpose-information)',
      });
    });
  };

  useDebounceEffect(() => {
    if (message) {
      highlightPlaceholders();
    }
  }, [message]);

  return (
    <div className="ca-email-box">
      <div className="ca-email-box__title">
        <h2>New Message</h2>
        <div className="ca-email-box__actions">
          <div onClick={toggleMinimize} className="ca-email-box__action">
            <i className="bi bi-dash" />
          </div>
          <div onClick={handleEmailClose} className="ca-email-box__action">
            <i className="bi bi-x-lg" />
          </div>
        </div>
      </div>
      <div className={`ca-email-box__content ${isBoxMinimized ? 'ca-email-box__content--hidden' : ''}`}>
        <div className="ca-email-box__header-line ca-email-box__email">
          To
          <div className="ca-email-box__recipient-box">
            {`${column} Students`}
          </div>
        </div>
        <div
          className="ca-email-box__subject ca-email-box__header-line"
          onKeyDownCapture={(e) => {
            if (e.code === 'Enter') {
              e.preventDefault();
              reactQuillRef.current?.focus();
            }
          }}
        >
          <ReactQuill
            ref={reactQuillSubjectRef}
            theme="snow"
            modules={{
              toolbar: null,
            }}
            id="email-subject"
            value={subject}
            placeholder="Subject"
            onChange={setSubject}
            style={{
              border: 'none',
              padding: '0',
              height: 'fit-content',
              width: '100%',
            }}
            data-testid="email-subject-input"
          />
        </div>
        <div className="ca-email-box__text-container" onClick={() => reactQuillRef.current?.focus()}>
          <ReactQuill
            ref={reactQuillRef}
            placeholder="Draft your message using one of our templates or write your own"
            modules={{
              toolbar: {
                container: '#toolbar',
              },
            }}
            theme="snow"
            value={message}
            onChange={setMessage}
            style={{
              border: 'none',
              padding: '0',
              borderRadius: '8px',
              display: 'block',
            }}
          />
          <div className="ca-email-box__footer">
            <Button
              handleClick={handleSendClick}
              mode="primary"
              size="medium"
              label="Send Message"
              icon="bi bi-send"
            />
            <div>
              <AITemplatesButton
                options={GenerateCommentOptions}
                size="small"
                buttonMode="blue"
                listPosition="left"
                handleOptionClick={applyAITemplate}
                isLoading={false}
                buttonIcon="bi bi-file-text"
                buttonLabel="Template"
                isOutlined
                tooltipLabel="Choose from templates list"
              />
            </div>
            <div>
              <Tooltip label="Create custom templates" position="top" withArrow>
                <Button
                  mode="blue"
                  outlined
                  label="Custom"
                  handleClick={handleCustomTemplatesClick}
                  size="medium"
                  icon="bi bi-tools"
                  data-testid="templates-button-custom"
                />
              </Tooltip>
            </div>
            <EditorToolbar />
          </div>
        </div>
      </div>
    </div>
  );
};
