import { University } from '@/domains/generic/auth/types';
import PlaceholderLogo from '@/assets/images/university/university-placeholder-logo.png';
import { searchTrialUniversitiesByName } from '../strapi/universities';

export function mapTrialUniversitiesFromStrapiToGeneric(universities: any[]): University[] {
  return universities.map((university) => ({
    name: university?.attributes.name,
    logo: university?.attributes.logo?.data?.attributes.url || PlaceholderLogo,
    isTrial: true,
    id: university.id,
  }));
}

export function mapUniversitiesToGeneric(universities: any[]): University[] {
  return universities.map((university) => ({
    name: university.name,
    id: university.id,
    logo: university.logo_url,
    isTrial: false,
    abbreviation: university.abbreviation,
  }));
}

export const findTrialUniversity = async (universityName: string): Promise<University | undefined> => {
  try {
    const trialUniversities = await searchTrialUniversitiesByName(universityName, true) || { data: [] };
    const matchedUniversities = trialUniversities.data;

    if (!Array.isArray(matchedUniversities) || matchedUniversities.length === 0) {
      return undefined;
    }

    const mappedUniversities = matchedUniversities.map((university: any) => ({
      name: university?.attributes.name,
      logo: university?.attributes.logo?.data?.attributes.url || PlaceholderLogo,
      isTrial: true,
      id: university.id,
    }));

    const [selected] = mappedUniversities;

    return selected;
  } catch (error) {
    return undefined;
  }
};
