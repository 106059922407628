import { FilterOption } from '../../domains/core/company/types/company';
import { ContactBookFiltersType, ContactFilterCompany } from '../types/contacts-book';

const mapYesNoToBoolean = (value: string): string => {
  if (value === 'Yes') return 'true';
  if (value === 'No') return 'false';
  return '';
};

const mapBooleanToYesNo = (value: string): string => {
  if (value === 'true') return 'Yes';
  if (value === 'false') return 'No';
  return '';
};

const formatArrayValueForSearch = (
  name: string,
  value: string[] | FilterOption[] | ContactFilterCompany[],
): string => {
  if (Array.isArray(value)) {
    if (value[0] && typeof value[0] === 'object' && 'id' in value[0]) {
      return (value as { id: string }[]).map(v => encodeURIComponent(v.id)).join(',');
    }
    if (value[0] && typeof value[0] === 'object') {
      return (value as { value: string }[]).map(v => encodeURIComponent(v.value)).join(',');
    }
    // Handle string arrays
    return (value as string[]).map(v => encodeURIComponent(v)).join(',');
  }
  return '';
};

export const getSearchParamsStringContacts = (
  filters: ContactBookFiltersType,
): URLSearchParams => {
  const newSearchParams = new URLSearchParams();

  Object.entries(filters).forEach(([key, value]) => {
    if (!value || (Array.isArray(value) && value.length === 0)) {
      return;
    }

    if (key === 'response_rate' && typeof value === 'object') {
      const rateValue = value as { min: number; max: number };
      newSearchParams.append('responseRateMin', rateValue.min.toString());
      newSearchParams.append('responseRateMax', rateValue.max.toString());
    } else if (key === 'isAlum' || key === 'isRecruiter') {
      const booleanValue = mapYesNoToBoolean(value as string);
      if (booleanValue) {
        newSearchParams.append(key, booleanValue);
      }
    } else if (key === 'companies') {
      newSearchParams.append('companyID', formatArrayValueForSearch(key, value as ContactFilterCompany[]));
    } else if (Array.isArray(value) && value.length > 0) {
      newSearchParams.append(key, formatArrayValueForSearch(key, value));
    } else if (typeof value === 'string' && value !== '') {
      newSearchParams.append(key, value);
    }
  });

  return newSearchParams;
};

export const convertURLParamsToFiltersContacts = (
  searchParams: URLSearchParams,
  staticOptions: {
    companies: { id: string; name: string; }[];
    industries: { name: string; }[];
  },
): ContactBookFiltersType => {
  const defaultFilters: ContactBookFiltersType = {
    contactName: '',
    companies: [],
    isAlum: '',
    isRecruiter: '',
    industryName: [],
    response_rate: {
      min: 0,
      max: 100,
    },
  };

  const mapDropdownOptions = (values: string[], options: { name: string; }[]) => values
    .flatMap((value) => decodeURIComponent(value).split(','))
    .map((value) => options.find((option) => ('value' in option ? option.value === value : option.name === value)))
    .filter(Boolean);

  const mapCompanyIds = (values: string[], options: ContactFilterCompany[]): ContactFilterCompany[] => {
    const companies: ContactFilterCompany[] = [];
    values.forEach((value) => {
      const company = options.find((comp) => comp.id === value);
      if (company) {
        companies.push(company);
      }
    });

    return companies;
  };

  // Handle response rate separately
  const min = Number(searchParams.get('responseRateMin')) || defaultFilters.response_rate.min;
  const max = Number(searchParams.get('responseRateMax')) || defaultFilters.response_rate.max;

  const formatters: Record<string, (value: string) => any> = {
    contactName: (value: string) => value,
    companyID: (value: string) => mapCompanyIds(value.split(','), staticOptions.companies),
    isAlum: (value: string) => mapBooleanToYesNo(value),
    isRecruiter: (value: string) => mapBooleanToYesNo(value),
    industries: (value: string) => mapDropdownOptions(value.split(','), staticOptions.industries),
  };

  const newFilters = { ...defaultFilters };
  newFilters.response_rate = { min, max };

  searchParams.forEach((value, key) => {
    if (key in formatters) {
      const formatter = formatters[key];
      const resolvedKey = key === 'companyID' ? 'companies' : key;
      newFilters[resolvedKey as keyof ContactBookFiltersType] = formatter(value);
    }
  });

  return newFilters;
};

export const updateQueryString = (params: URLSearchParams) => {
  window.history.replaceState(null, '', `?${params.toString()}`);
};
