import { Loader } from '@/components/loader/loader';
import { ResumeAdvisorListingBox } from '@/domains/core/resume';
import './student-resumes-tab.scss';

type Props = {
  studentID: string;
  studentName: string;
  isLoading?: boolean;
  // TODO: what is the type here?
  resumes: any[];
};

export const StudentResumesTab = ({
  studentName,
  resumes,
  isLoading = false,
  studentID,
}: Props) => {
  if (isLoading) {
    return (
      <div id="loader-zone">
        <Loader />
      </div>
    );
  }

  return (
    <div className="student-OB-resumes">
      {Array.isArray(resumes) && resumes.length > 0 ? (
        <div className="student-OB-resumes__list">
          {resumes?.map((resume) => (<ResumeAdvisorListingBox studentID={studentID} key={resume.id} resume={resume} type={resume.type} isValid={resume.is_valid} />))}
        </div>
      ) : (
        <div className="student-OB-resumes__empty">
          <h2 className="student-OB-resumes__empty-title">
            Looks like
            {' '}
            {studentName}
            {' '}
            hasn’t created any resumes yet.
          </h2>
          <p className="student-OB-resumes__empty-subtitle">Let’s get them started on creating their very first resume. You might send them an email to nudge them.</p>
        </div>
      )}
    </div>
  );
};
