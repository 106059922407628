import { useState } from 'react';
import { NavbarItemName } from '@/pages/layout/navbar/types/types';

export const useNavbarContext = () => {
  const [unreadTabs, setUnreadTabs] = useState<NavbarItemName[]>([]);

  const addUnreadTab = (tab: NavbarItemName) => {
    if (!unreadTabs.includes(tab)) {
      setUnreadTabs([...unreadTabs, tab]);
    }
  };

  const removeUnreadTab = (tab: NavbarItemName) => {
    setUnreadTabs(unreadTabs.filter((unreadTab) => unreadTab !== tab));
  };

  return {
    unreadTabs,
    addUnreadTab,
    removeUnreadTab,
  };
};
