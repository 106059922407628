import { useNavigate } from 'react-router-dom';
import { useCallback, useState } from 'react';
import { IconButton } from '@careeros/coco';
import { useScroll } from '@/services/hooks/use-scroll';
import './kanban-board.scss';
import { Counter } from '@/components/counter/counter';
import { StudentKanbanTile } from '@/career-advisor-v3/components/student-tile/student-tile';
import { KanbanBoardLayout } from '@/career-advisor-v3/types/kanban';
import { Tooltip } from '@/components/tooltip/tooltip';
import { MiniAdvisor, StudentStatus } from '@/career-advisor-v3/types';

export interface KanbanBoardProps {
  layout: KanbanBoardLayout;
  // TODO: add enum/type here
  warningColumns: string[];
  sendMessagePerColumn?: (stage: StudentStatus) => void;
  messageButtonTooltip?: string,
  universityId: string;
  allAdvisors: MiniAdvisor[];
}

export const KanbanBoard = ({
  layout,
  warningColumns,
  sendMessagePerColumn,
  messageButtonTooltip,
  universityId,
  allAdvisors,
}: KanbanBoardProps) => {
  const [kanbanContainer, handleVerticalScroll] = useScroll();
  const [showRightScrollArrow, setShowRightScrollArrow] = useState(true);
  const [showLeftScrollArrow, setShowLeftScrollArrow] = useState(false);
  const navigate = useNavigate();
  console.log('layout', layout);
  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    // handleScroll here calculates and shows the scroll arrows if needed
    const el = e.currentTarget;
    const allColumnsWidth = kanbanContainer.current.scrollWidth;
    const columnsInViewWidth = kanbanContainer.current.clientWidth;

    if (Math.round(el.scrollLeft + columnsInViewWidth) >= Math.round(allColumnsWidth)) {
      setShowRightScrollArrow(false);
    } else {
      setShowRightScrollArrow(true);
    }
    if (el.scrollLeft === 0) {
      setShowLeftScrollArrow(false);
    } else {
      setShowLeftScrollArrow(true);
    }
  };

  const handleTileClick = useCallback((studentId: string) => navigate(`/app/student-overview/${studentId}/university/${universityId}`), [universityId]);

  return (
    <div className="students-kanban-board">
      <div className="controls-wrapper">
        <div className="students-kanban-board__controls">
          <IconButton
            icon="bi bi-chevron-left"
            mode="rounded"
            size="medium"
            outlined
            handleClick={() => handleVerticalScroll('left')}
            disabled={!showLeftScrollArrow}
          />
          <IconButton
            icon="bi bi-chevron-right"
            mode="rounded"
            size="medium"
            outlined
            handleClick={() => handleVerticalScroll('right')}
            disabled={!showRightScrollArrow}
          />
        </div>
      </div>
      <div className="students-kanban-board__content" ref={kanbanContainer} onScroll={handleScroll}>
        {
          [...layout.entries()].map(([column, columnValues], index) => (
            <div
              key={column}
              className={`students-kanban-board__column students-kanban-board__column--nr-${index}`}
            >
              <div className="students-kanban-board__column-header">
                <div className={`students-kanban-board__title ${warningColumns.includes(column) ? 'students-kanban-board__title--warning' : ''}`}>
                  <span>{column}</span>
                  <span className="students-kanban-board__counter">
                    <Counter counter={columnValues && columnValues.length} size="medium" mode="default" />
                  </span>
                </div>
                {sendMessagePerColumn && (
                  <div className="students-kanban-board__message-button">
                    <Tooltip
                      label={messageButtonTooltip || 'Send message'}
                      disableHoverListener={!messageButtonTooltip}
                      position="top"
                      withArrow
                    >
                      <IconButton
                        mode="primary"
                        size="small"
                        icon="bi bi-send"
                        outlined
                        handleClick={() => sendMessagePerColumn(column)}
                      />
                    </Tooltip>
                  </div>
                )}
              </div>

              {columnValues && columnValues.map((student) => (
                <div className="students-kanban-board__tile">
                  <StudentKanbanTile
                    key={student.id}
                    id={student.id}
                    image={student.profileImage}
                    title={`${student.firstName} ${student.lastName}`}
                    subtitle={student.cohort?.name}
                    handleTileClick={handleTileClick}
                    universityId={universityId}
                    assignedAdvisors={student.assignedAdvisors || []}
                    allAdvisors={allAdvisors}
                  />
                </div>
              ))}
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default KanbanBoard;
