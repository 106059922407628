import { useQueryClient } from 'react-query';
import { NoteInput } from '@/components/note-input/note-input';
import './student-notes-tab.scss';
import NoteCard from '@/components/note-card/note-card';
import { CAStudentNote } from '@/career-advisor-v3/types';
import { addCAStudentNote, deleteCAStudentNote, editCAStudentNote } from '@/career-advisor-v3/api/note';

type Props = {
  studentId: string;
  notes: CAStudentNote[];
};

// TODO: connect backend
export const StudentNotesTab = ({
  studentId,
  notes,
}: Props) => {
  const queryClient = useQueryClient();
  // const [notes, setNotes] = useState<Note[]>([]);

  const handleAddNote = async (title: string, content: string, successCallback?: () => void) => {
    await addCAStudentNote(studentId, { title, content });

    queryClient.invalidateQueries(['ca-student-notes', studentId]);
    successCallback?.();
  };

  const handleEditNote = async (id: string, title: string, content: string) => {
    await editCAStudentNote(studentId, id, { title, content });

    queryClient.invalidateQueries(['ca-student-notes', studentId]);
  };

  const handleDeleteNote = async (id: string) => {
    await deleteCAStudentNote(studentId, id);

    queryClient.invalidateQueries(['ca-student-notes', studentId]);
  };

  return (
    <div className="notes">
      <NoteInput
        handleSave={handleAddNote}
        saveSilently={handleAddNote}
        parentID={studentId}
      />
      {notes.map((note: CAStudentNote) => (
        <NoteCard
          key={note.id}
          parentID={studentId}
          note={note}
          handleEdit={handleEditNote}
          handleDelete={handleDeleteNote}
        />
      ))}
    </div>
  );
};
