import { useContext, useEffect, useState } from 'react';
import './quest-tile.scss';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useExtensionMessaging } from '@/services/hooks/use-extension-messaging';
import { getApplications } from '@/services/api/application';
import { InfoModal } from '@/domains/generic/modals';
import { ModalContext } from '@/components/modal/modal-provider';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { useIdentitiesNoCache, useSelf, useSelfUniversity } from '@/services/queries/user';
import {
  QuestCompanyType, QuestContactType, QuestJobType, QuestType,
} from '../../../types/quests';
import {
  CTA_LINK, QUEST_DESCRIPTION, QUEST_TITLE,
} from '../quests-data';
import { TrackerContextType, TrackerContext } from '@/domains/core/company';
import { QuestTileDumb } from './quest-tile-dumb';

export type QuestTileProps = {
  id: string;
  type: QuestType;
  company?: QuestCompanyType;
  job?: QuestJobType;
  contact? : QuestContactType;
  dueDate: string | null;
  primary?: boolean;
  createdAt?: string;
  dismissAction: (questID: string, questType: string) => void;
  completeAction: (questID: string, questType: string) => void;
  customCTAClickHandler?: (questType: QuestType, companyID: string, contactID: string) => void;
};

export const QuestTile = ({
  id,
  type,
  dismissAction,
  completeAction,
  company,
  contact,
  job,
  dueDate = null,
  primary = false,
  createdAt,
  customCTAClickHandler,
}: QuestTileProps) => {
  const { checkLinkedInLogin } = useExtensionMessaging();
  const { data: identities, isLoading: identitiesAreLoading } = useIdentitiesNoCache();
  const { openModal, closeModal } = useContext(ModalContext) as any;
  const [isQuestDone, setIsQuestDone] = useState(false);
  const { trackEvent } = useAnalytics();
  const [searchParams] = useSearchParams();
  const isExtensionLogin = searchParams.get('extension_login');
  const { openTracker } = useContext(TrackerContext) as TrackerContextType;
  const { data: university } = useSelfUniversity();
  const { data: self, isLoading: isSelfLoading } = useSelf();
  const navigate = useNavigate();

  const handleApplicationClick = (application: QuestJobType | undefined, companyId: string) => {
    if (application?.linkedin_url !== '') {
      window.open(application?.linkedin_url, '_blank');
      return;
    }

    openTracker(companyId, 'Jobs');
  };

  let title = QUEST_TITLE[type];
  let description = QUEST_DESCRIPTION[type]({ companyName: company?.name, contactName: `${contact?.first_name} ${contact?.last_name}` });

  if (type === QuestType.SaveContact && company && company.name) {
    title = QUEST_TITLE[type](company.name);
  }
  if (type === QuestType.AddNewContact && company && company.name) {
    title = QUEST_TITLE[type](company.name);
  }
  if (type === QuestType.ApplicationDeadline && company && company.name && job && job.title) {
    title = QUEST_TITLE[type](company.name, job.title);
  }
  if (type === QuestType.SendMessage && company && company.name && contact && contact.first_name) {
    title = QUEST_TITLE[type](`${contact.first_name} ${contact.last_name}`, company.name);
  }
  if (type === QuestType.SendConnection && company && company.name && contact && contact.first_name) {
    title = QUEST_TITLE[type](`${contact.first_name} ${contact.last_name}`, company.name);
  }
  if ((type === QuestType.SendFirstEmail || type === QuestType.SendFirstOutreach || type === QuestType.SendSecondOutreach) && company && company.name && contact && contact.first_name) {
    title = QUEST_TITLE[type](`${contact.first_name} ${contact.last_name}`, company.name);
  }
  if (type === QuestType.ScheduleCoffeeChat && company && company.name && contact && contact.first_name) {
    title = QUEST_TITLE[type](`${contact.first_name} ${contact.last_name}`, company.name);
  }
  if (type === QuestType.SendFollowUpEmail && company && company.name && contact && contact.first_name) {
    title = QUEST_TITLE[type](`${contact.first_name} ${contact.last_name}`, company.name);
  }
  if (type === QuestType.RespondToEmail && company && company.name && contact && contact.first_name) {
    title = QUEST_TITLE[type](`${contact.first_name} ${contact.last_name}`, company.name);
  }
  if (type === QuestType.ReplyToContact && company && company.name && contact && contact.first_name) {
    title = QUEST_TITLE[type](`${contact.first_name} ${contact.last_name}`, company.name);
  }
  if (type === QuestType.ApplicationDeadline && dueDate) {
    description = QUEST_DESCRIPTION[type]({ deadline: new Date(dueDate).toLocaleDateString('en-US', { month: 'short', year: 'numeric', day: 'numeric' }) });
  }
  const handleMainCTAClick = (titleClicked: boolean) => {
    if (titleClicked) {
      trackEvent('Quest Title Clicked', {}, {
        type,
        university: university?.name,
        cohort: university?.cohort,
      });
    } else {
      trackEvent('Quest Button Clicked', {}, {
        type,
        university: university?.name,
        cohort: university?.cohort,
      });
    }

    if (customCTAClickHandler) {
      customCTAClickHandler(type, company?.id ?? '', contact?.id ?? '');
      return;
    }
    const isSendMessageQuestType = type === QuestType.SendConnection
      || type === QuestType.SendMessage
      || type === QuestType.SendFirstEmail
      || type === QuestType.SendFollowUpEmail
      || type === QuestType.SendFirstOutreach
      || type === QuestType.SendSecondOutreach
      || type === QuestType.RespondToEmail
      || type === QuestType.ScheduleCoffeeChat
      || type === QuestType.ReplyToContact;

    if (type === QuestType.DownloadExtension) {
      window.open(CTA_LINK[type], '_blank');
      setIsQuestDone(true);
    } else if (type === QuestType.ApplicationDeadline) {
      handleApplicationClick(job, company?.id ?? '');
    } else if (type === QuestType.SaveContact || type === QuestType.AddNewContact) {
      openTracker(company?.id ?? '', 'Contacts');
    } else if (isSendMessageQuestType) {
      const url = CTA_LINK[type](contact?.id ?? '');
      navigate(url);
    } else {
      navigate(CTA_LINK[type]);
    }
  };

  const openAreYouSureDismiss = (taskID: string) => {
    openModal(
      <InfoModal
        handleButtonClick={() => { dismissAction(taskID, type); closeModal(); }}
        handleSecondaryButtonClick={closeModal}
        title="Are you sure? "
        description="Dismissing this task will remove it from your dashboard and you will not be able to complete it."
        buttonLabel="Dismiss"
        secondaryButtonLabel="Cancel"
      />,
    );
  };

  const openAreYouSureComplete = (taskID: string) => {
    openModal(
      <InfoModal
        handleButtonClick={() => { completeAction(taskID, type); closeModal(); }}
        handleSecondaryButtonClick={closeModal}
        title="Are you sure? "
        description="Completing this task will mark it as done and remove it from your dashboard."
        buttonLabel="Complete"
        secondaryButtonLabel="Cancel"
      />,
    );
  };
  const checkIfQuestIsCompleted = async () => {
    if (type === QuestType.DownloadExtension) {
      if (isExtensionLogin) {
        return;
      }
      const linkedInLoginStatus = await checkLinkedInLogin();
      if (linkedInLoginStatus) {
        completeAction(id, type);
      }
    }
    if (type === QuestType.SaveFirstCompany) {
      const companiesResponse = await getApplications();
      const companies = companiesResponse ?? [];
      if (companies.length > 1) { // 1 because CareerOS is saved by default
        completeAction(id, type);
      }
    }

    if (type === QuestType.ConnectEmail && !identitiesAreLoading) {
      if (identities && identities.length > 0) {
        completeAction(id, type);
      }
    }

    if (type === QuestType.CompleteDemographicsForm && !isSelfLoading) {
      if (self?.has_completed_demographics_form) {
        completeAction(id, type);
      }
    }
  };

  useEffect(() => {
    checkIfQuestIsCompleted();
  }, [identities, identitiesAreLoading, self, isSelfLoading]);

  return (
    <QuestTileDumb
      id={id}
      title={title}
      description={description}
      type={type}
      completed={isQuestDone}
      primary={primary}
      companyLogo={company?.logo_url}
      contactLogo={contact?.profile_image}
      contactName={`${contact?.first_name} ${contact?.last_name}`}
      companyName={company?.name}
      createdAt={createdAt}
      openAreYouSureComplete={openAreYouSureComplete}
      openAreYouSureDismiss={openAreYouSureDismiss}
      handleMainCTAClick={handleMainCTAClick}
    />
  );
};
