import { Link } from 'react-router-dom';
import './cohorts-navigation.scss';
import { CohortLinkDataType } from '../../types';

type Props = {
  links: CohortLinkDataType[];
};

export const CohortsNavigation = ({ links }: Props) => (
  <div className="cohorts-navigation">
    {links.map((link) => (
      <Link
        key={link.url}
        to={link.url}
        className={`cohorts-navigation__item ${
          window.location.pathname === link.url ? 'cohorts-navigation__item--active' : ''
        }`}
      >
        {link.label}
      </Link>
    ))}
  </div>
);
