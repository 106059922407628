import ReactQuill from 'react-quill';
import { Button } from '@careeros/coco';
import './comment-input-box.scss';
import {
  useContext, useMemo, useRef, useState,
} from 'react';
import { DefaultLogo } from '@/components/default-logo/default-logo';
import '@/services/helpers/quill-config';
import { ModalContext } from '@/components/modal/modal-provider';
import { CustomTemplatesModal } from '@/domains/core/netwoking/components';
import { ContactPlaceholders } from '@/domains/core/netwoking/types';
// import { convertNewLinesToHtml } from '@/services/helpers/messages';
// import { GenerateCommentOptions } from '@/career-advisor-v3/constants/templates';
// import { AITemplatesButton } from '@/domains/core/student/components/use-ai-button/templates-button';
import { InfoModal } from '@/domains/generic/modals';

type Props = {
  handleSendComment: (comment: string) => void;
  companyName?: string;
  companyLogo?: string;
  title: string;
  studentFirstName: string,
  studentLastName: string,
  closeCommentBox: () => void;
};

export const CommentInputBox = ({
  handleSendComment,
  companyName,
  companyLogo,
  studentFirstName,
  studentLastName,
  title,
  closeCommentBox,
}: Props) => {
  const { openModal, closeModal } = useContext(ModalContext) as any;
  const [comment, setComment] = useState('');
  const reactQuillRef = useRef<ReactQuill | null>(null);
  const studentPlaceholders = useMemo<ContactPlaceholders>(() => ({
    FirstName: studentFirstName,
    LastName: studentLastName,
  }), []);
  const commentLength = useMemo(() => reactQuillRef.current?.getEditor().getText().trim().length || 0, [comment, reactQuillRef.current]);

  const handleSendCommentClick = () => {
    handleSendComment(comment);
    setComment('');
  };

  const applyTemplate = (template: string) => {
    setComment(template);
    closeModal();
  };

  // const applyAITempalte = (message: string) => {
  //   setComment(convertNewLinesToHtml(message));
  // };

  const handleCustomTemplatesClick = () => {
    openModal(
      <CustomTemplatesModal
        contactPlaceholders={studentPlaceholders}
        previewType="comment"
        selectTemplate={applyTemplate}
      />,
      {
        closeOnClickOutside: false,
      },
    );
  };

  const handleSubmitCommentRemoval = () => {
    closeCommentBox();
    closeModal();
  };

  const beforeCloseCommentBox = () => {
    if (commentLength) {
      openModal(
        <InfoModal
          title="Discard Message"
          description="Looks like you’re about to discard your message. If you discard now, your message will be permanently lost. How about giving it a quick review and sending it off?"
          icon="bi bi-exclamation-triangle"
          buttonLabel="Keep Writing"
          buttonIcon="bi bi-pencil-square"
          secondaryButtonIcon="bi bi-trash"
          secondaryButtonLabel="Delete Message"
          isSecondButtonDanger
          handleButtonClick={closeModal}
          handleSecondaryButtonClick={handleSubmitCommentRemoval}
          isImageDanger
          isButtonOutlined
          orientation="horizontal"
        />,
      );
    } else {
      closeCommentBox();
    }
  };

  return (
    <div className="comment-input-box">
      <div className="comment-input-box__close-button" onClick={beforeCloseCommentBox}>
        <i className="bi bi-x-lg" />
      </div>
      <div className="comment-input-box__header">
        <div className="comment-input-box__image">
          {(companyLogo && companyName) ? (
            <DefaultLogo
              source={companyLogo}
              name={companyName || 'Company'}
              size={24}
              type="company"
              className="comment-input-box__header-logo"
            />
          ) : (
            <i className="comment-input-box__header-icon bi bi-chat-square-text" />
          )}
        </div>
        <div className="comment-input-box__title">{title}</div>
      </div>
      <div className="comment-input-box__text-field">
        <ReactQuill
          ref={reactQuillRef}
          modules={{ toolbar: null }}
          theme="snow"
          value={comment}
          placeholder="Write comment"
          onChange={setComment}
          style={{
            border: 'none',
            padding: '0',
            height: '240px',
          }}
        />
      </div>
      <div className="comment-input-box__template-buttons">
        {/* <AITemplatesButton
          options={GenerateCommentOptions}
          size="small"
          buttonMode="blue"
          listPosition="left"
          handleOptionClick={applyAITempalte}
          isLoading={false}
          buttonIcon="bi bi-file-text"
          buttonLabel="Add Template"
          isOutlined
        /> */}
        <Button
          label="Custom Templates"
          icon="bi bi-tools"
          handleClick={handleCustomTemplatesClick}
          mode="blue"
          size="medium"
          outlined
        />
      </div>
      <div className="comment-input-box__separator" />
      <div className="comment-input-box__send-button">
        <Button
          label="Send Comment"
          icon="bi bi-send"
          handleClick={handleSendCommentClick}
          disabled={!commentLength}
          mode="primary"
          size="medium"
        />
      </div>
    </div>
  );
};
