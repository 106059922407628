import { useEffect, useState } from 'react';
import { RangeBadge } from '@/components/badge/badge';
import { ConsistencySection } from '../../../types';
import { ResumeBanner } from '../../resume-banner/resume-banner';

type SectionConsistencyProps = {
  data?: ConsistencySection;
  showBanner?: boolean;
};

export const SectionConsistency = ({ data, showBanner = true }: SectionConsistencyProps) => {
  const [allBulletsEndWithAPeriodVisible, setAllBulletsEndWithAPeriodVisible] = useState(true);
  const [overusageOfActionVerbsInBulletsVisible, setOverusageOfActionVerbsInBulletsVisible] = useState(true);
  const [avoidedWordsUsedInBulletsVisible, setAvoidedWordsUsedInBulletsVisible] = useState(true);
  const [allBulletsStartWithACapitalLetterVisible, setAllBulletsStartWithACapitalLetterVisible] = useState(true);

  useEffect(() => {
    setAllBulletsEndWithAPeriodVisible(true);
    setOverusageOfActionVerbsInBulletsVisible(true);
    setAvoidedWordsUsedInBulletsVisible(true);
    setAllBulletsStartWithACapitalLetterVisible(true);
  }, [data]);
  return (
    <div className="score-info-improvement">
      <div className="score-info-improvement__row">
        <div className="score-info-improvement__progress">
          <RangeBadge label={`${data?.all_bullets_start_with_capital_letter.percentage_score === 100 ? 'Yes' : 'No'}`} percentageOfCompletion={data?.all_bullets_start_with_capital_letter.percentage_score || 0} rangeColors={['#ff8585', '#ffe59e', '#7bcd8c']} />
        </div>
        <div className="score-info-improvement__label">
          All bullets start with a capital letter
        </div>
      </div>
      {showBanner && (
        <div className="score-info-improvement__row score-info-improvement__row--full">
          {allBulletsStartWithACapitalLetterVisible && <ResumeBanner title="" text={data?.all_bullets_start_with_capital_letter.feedback} fragments={data?.all_bullets_start_with_capital_letter.fragments} isClosable handleClose={() => setAllBulletsStartWithACapitalLetterVisible(false)} />}
        </div>
      )}
      <div className="score-info-improvement__row">
        <div className="score-info-improvement__progress">
          <RangeBadge label={`${data?.all_bullets_end_with_period_or_none.percentage_score === 100 ? 'Yes' : 'No'}`} percentageOfCompletion={data?.all_bullets_end_with_period_or_none.percentage_score || 0} rangeColors={['#ff8585', '#ffe59e', '#7bcd8c']} />
        </div>
        <div className="score-info-improvement__label">
          All bullets end with period or none
        </div>
      </div>
      {showBanner && (
        <div className="score-info-improvement__row score-info-improvement__row--full">
          {allBulletsEndWithAPeriodVisible && <ResumeBanner title="" text={data?.all_bullets_end_with_period_or_none.feedback} fragments={data?.all_bullets_end_with_period_or_none.fragments} isClosable handleClose={() => setAllBulletsEndWithAPeriodVisible(false)} />}
        </div>
      )}
      <div className="score-info-improvement__row">
        <div className="score-info-improvement__progress">
          <RangeBadge label={`${data?.overuse_of_action_verbs_in_bullets.value}`} percentageOfCompletion={data?.overuse_of_action_verbs_in_bullets.percentage_score || 0} rangeColors={['#ff8585', '#ffe59e', '#7bcd8c']} />
        </div>
        <div className="score-info-improvement__label">
          Overusage of Action Verbs in Bullets
        </div>
      </div>
      {showBanner && (
        <div className="score-info-improvement__row score-info-improvement__row--full">
          {overusageOfActionVerbsInBulletsVisible && <ResumeBanner title="" text={data?.overuse_of_action_verbs_in_bullets.feedback} fragments={data?.overuse_of_action_verbs_in_bullets.fragments} isClosable handleClose={() => setOverusageOfActionVerbsInBulletsVisible(false)} />}
        </div>
      )}
      <div className="score-info-improvement__row">
        <div className="score-info-improvement__progress">
          <RangeBadge label={`${data?.avoided_words_used_in_bullets.value}`} percentageOfCompletion={data?.avoided_words_used_in_bullets.percentage_score || 0} rangeColors={['#ff8585', '#ffe59e', '#7bcd8c']} />
        </div>
        <div className="score-info-improvement__label">
          Avoided Words used in Bullets
        </div>
      </div>
      {showBanner && (
        <div className="score-info-improvement__row score-info-improvement__row--full">
          {avoidedWordsUsedInBulletsVisible && <ResumeBanner title="" text={data?.avoided_words_used_in_bullets.feedback} fragments={data?.avoided_words_used_in_bullets.fragments} isClosable handleClose={() => setAvoidedWordsUsedInBulletsVisible(false)} />}
        </div>
      )}
    </div>
  );
};
