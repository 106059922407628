import { useEffect, useState } from 'react';
import { useLoaderData, useSearchParams } from 'react-router-dom';
import { IconButton } from '@careeros/coco';
import { Loader } from '@/components/loader/loader';
import {
  CompanyCard,
  EmptySearchResult,
} from '@/domains/core/company';
import { SearchResponse } from '@/domains/generic/search/types';
import './styles/search.scss';
import { NUMBER_OF_COMPANIES_WITH_PULSE_ANIMATION } from '@/services/helpers/company';
import { useApplications, useArchivedCompanies } from '@/services/queries/application';
import CustomWithAuthenticationRequired from './auth/custom-protected-route';
import { useCheckExtension } from '@/services/hooks/use-check-extension';
import { Company } from '@/domains/core/company/types';

const SearchPage = () => {
  const {
    foundCompanies,
  } = useLoaderData() as SearchResponse;
  const [searchParams] = useSearchParams();
  const [hasNotSavedCompanies, setHasNotSavedCompanies] = useState(false);
  const [numberOfSavedCompanies, setNumberOfSavedCompanies] = useState(0);
  const { data: applications = [] } = useApplications();
  const { data: archivedCompanies = [] } = useArchivedCompanies();
  const query = searchParams.get('query') || '';
  const { checkUserExtension } = useCheckExtension();

  const goToLinkedinSearch = () => {
    window.open(`https://www.linkedin.com/search/results/companies/?keywords=${query}&spellCorrectionEnabled=false`, '_blank');
  };

  const handleEmptyTileClick = async () => {
    const isExtensionInstalled = await checkUserExtension();

    if (!isExtensionInstalled) {
      return;
    }

    goToLinkedinSearch();
  };

  useEffect(() => {
    setHasNotSavedCompanies(numberOfSavedCompanies < NUMBER_OF_COMPANIES_WITH_PULSE_ANIMATION);
  }, [numberOfSavedCompanies]);

  useEffect(() => {
    setNumberOfSavedCompanies(applications.length + archivedCompanies.length);
    if ((applications.length + archivedCompanies.length) < NUMBER_OF_COMPANIES_WITH_PULSE_ANIMATION) {
      setHasNotSavedCompanies(true);
    }
  }, [applications.length, archivedCompanies.length]);

  return (
    <div>
      <div className="search-page-results">
        {foundCompanies.length ? (
          <ul className="companies-listing">
            {foundCompanies.map((company: Company) => (
              <CompanyCard
                key={company.id}
                id={company.id}
                companyName={company.name}
                companyIndustry={company.careeros_industry}
                officeLocations={company.locations || []}
                companySize={company.company_size}
                companyDescription={company.short_description ? company.short_description : company.overview}
                imageURL={company.logo_url}
                tags={company.tags}
                companyApplicationId={applications?.find((application) => application.company_id === company.id)?.id || ''}
                hasSavedAnimation={hasNotSavedCompanies}
                setHasNotSavedCompanies={() => setNumberOfSavedCompanies(prevNumber => prevNumber + 1)}
              />
            ))}
            <div
              className="companies-listing__empty-tile"
              onClick={handleEmptyTileClick}
            >
              <IconButton
                icon="bi bi-plus-lg"
                mode="rounded"
                outlined
                size="medium"
              />
              <span>Add a company</span>
            </div>
          </ul>
        ) : (
          <EmptySearchResult
            linkedInURLSearchParams={`?keywords=${query}&spellCorrectionEnabled=false`}
            checkExtension={checkUserExtension}
            title={`No result found for “${query}”`}
            subtitle="We couldn’t find the company you searched for in our database. But don’t worry! You can head to LinkedIn to save the company directly using our Chrome Extension."
          />
        )}
      </div>
    </div>
  );
};

export default CustomWithAuthenticationRequired(SearchPage, {
  onRedirecting: () => (
    <div id="loader-zone">
      <Loader />
    </div>
  ),
});
