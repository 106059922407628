import { useRevalidator } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Button } from '@careeros/coco';
import { FilterNameType, FilterOption } from '@/domains/core/company/types';
import HeartEyesEmoji from '@/assets/images/icons/emojis/smiling-face-with-heart-shaped-eyes.png';
import SmilingEmoji from '@/assets/images/icons/emojis/smiling-face-with-smiling-eyes.png';
import NeutralEmoji from '@/assets/images/icons/emojis/neutral-face.png';
import DottedEmoji from '@/assets/images/icons/emojis/dotted-line-face.png';
import { Input } from '@/components/input/input';
import { MultiSelect } from '@/components/multi-select/multi-select';

import './filter-bar.scss';
import { APIstatuses, statuses } from '@/domains/core/tracker-data';

const hypeLevelOptions = [
  {
    name: <img src={HeartEyesEmoji} alt="" />,
    value: 3,
  },
  {
    name: <img src={SmilingEmoji} alt="" />,
    value: 2,
  },
  {
    name: <img src={NeutralEmoji} alt="" />,
    value: 1,
  },
  {
    name: <img src={DottedEmoji} alt="" />,
    value: 0,
  },
];

const activeStatusOptions = Object.entries(statuses).filter(([key]) => key !== 'Archived');
const activeStatuses = Object.fromEntries(activeStatusOptions);

const defaultFilters = {
  industry: [],
  hype_level: [],
  contacts_saved: [],
  jobs_saved: [],
  status: [],
};

type Props = {
  handleFiltersChange: (filters: { [name in FilterNameType]: FilterOption[] }, searchQuery: string) => void;
  industries: string[];
  withStatusFilter?: boolean;
};

export const KanbanFilterBar = ({
  handleFiltersChange,
  industries,
  withStatusFilter = false,
}: Props) => {
  const revalidator = useRevalidator();
  const [searchValue, setSearchValue] = useState('');
  const [selectedFilters, setSelectedFilters] = useState<{ [name in FilterNameType]: FilterOption[] }>(defaultFilters);

  const handleSearchValueChange = (value: string) => {
    setSearchValue(value);
    handleFiltersChange(selectedFilters, value);

    const newSearchParams = new URLSearchParams(window.location.search);

    newSearchParams.delete('search');

    if (value) {
      newSearchParams.append('search', encodeURIComponent(value));
    }

    window.history.replaceState(null, '', `?${newSearchParams.toString()}`);
  };

  const handleFilterChange = (name: FilterNameType, options: FilterOption[]) => {
    const newFilters = { ...selectedFilters, [name]: options };
    revalidator.revalidate();
    setSelectedFilters(newFilters);
    handleFiltersChange(newFilters, searchValue);

    const newSearchParams = new URLSearchParams(window.location.search);

    newSearchParams.delete(name);

    if (options.length > 0) {
      newSearchParams.append(name, options.map((option) => encodeURIComponent(option.value)).join(','));
    } else {
      newSearchParams.delete(name);
    }

    window.history.replaceState(null, '', `?${newSearchParams.toString()}`);
  };

  const handleFilterReset = () => {
    setSelectedFilters(defaultFilters);
    setSearchValue('');
    handleFiltersChange(defaultFilters, '');

    const newSearchParams = new URLSearchParams(window.location.search);

    Object.keys(defaultFilters).forEach((key) => {
      newSearchParams.delete(key);
    });

    window.history.replaceState(null, '', `?${newSearchParams.toString()}`);
  };

  const handleFiltersInSearchParams = () => {
    const { search } = window.location;

    const filtersFromParams = new URLSearchParams(search);
    const selectedFiltersFromParams = {
      industry: filtersFromParams.get('industry')?.split(',')
        .filter(industry => industries.includes(decodeURIComponent(industry)))
        .map((industry) => ({ name: decodeURIComponent(industry), value: decodeURIComponent(industry) }))
        || [],
      hype_level: filtersFromParams.get('hype_level')
        ? hypeLevelOptions
          .filter(o => filtersFromParams.get('hype_level')?.split(',').includes(o.value.toString()))
        : [],
      contacts_saved: filtersFromParams.get('contacts_saved')?.split(',')
        .map((v) => (v === 'true' ? ({ value: true, name: 'Yes' }) : ({ value: false, name: 'No' })))
        || [],
      jobs_saved: filtersFromParams.get('jobs_saved')?.split(',')
        .map((v) => (v === 'true' ? ({ value: true, name: 'Yes' }) : ({ value: false, name: 'No' })))
        || [],
      status: filtersFromParams.get('status')?.split(',')
        .filter(stat => Object.values(activeStatuses).includes(decodeURIComponent(stat)))
        .map((stat) => ({ name: APIstatuses[decodeURIComponent(stat)], value: decodeURIComponent(stat) }))
        || [],
    };

    const searchQuery = filtersFromParams.get('search') || '';
    setSearchValue(decodeURIComponent(searchQuery));

    setSelectedFilters(selectedFiltersFromParams);
    handleFiltersChange(selectedFiltersFromParams, searchValue);
  };

  useEffect(() => {
    handleFiltersInSearchParams();
  }, [industries.length]);

  return (
    <>
      <div className="kanban-filters-bar__search">
        <Input
          placeholder="Search"
          value={searchValue}
          id="overview-search"
          handleValueChange={handleSearchValueChange}
          label=""
          icon="bi bi-search"
        />
      </div>
      <div className="kanban-filters-bar__filter">
        <MultiSelect
          labelType="list"
          handleSelectedOptions={(options: FilterOption[]) => handleFilterChange('industry', options)}
          withSelectAllOption
          placeholder="Industry"
          selected={selectedFilters.industry}
          options={industries.map((industry) => ({ name: industry, value: industry }))}
        />
      </div>
      <div className="kanban-filters-bar__filter">
        <MultiSelect
          selected={selectedFilters.hype_level}
          labelType="number"
          handleSelectedOptions={(options: FilterOption[]) => handleFilterChange('hype_level', options)}
          withSelectAllOption
          placeholder="Hype Level"
          size="small"
          options={hypeLevelOptions}
          width="fit-box"
        />
      </div>
      <div className="kanban-filters-bar__filter">
        <MultiSelect
          selected={selectedFilters.contacts_saved}
          labelType="filterAndOptions"
          handleSelectedOptions={(options: FilterOption[]) => handleFilterChange('contacts_saved', options)}
          placeholder="Contacts Saved"
          options={[{ name: 'Yes', value: true }, { name: 'No', value: false }]}
          width="fit-box"
        />
      </div>
      <div className="kanban-filters-bar__filter">
        <MultiSelect
          selected={selectedFilters.jobs_saved}
          labelType="filterAndOptions"
          handleSelectedOptions={(options: FilterOption[]) => handleFilterChange('jobs_saved', options)}
          placeholder="Jobs Saved"
          options={[{ name: 'Yes', value: true }, { name: 'No', value: false }]}
          width="fit-box"
        />
      </div>
      {withStatusFilter && (
        <div className="kanban-filters-bar__filter">
          <MultiSelect
            selected={selectedFilters.status}
            labelType="list"
            handleSelectedOptions={(options: FilterOption[]) => handleFilterChange('status', options)}
            placeholder="Status"
            options={Object.entries(activeStatuses).map(([key, value]) => ({ name: key, value }))}
            width="fit-box"
          />
        </div>
      )}

      {(Object.values(selectedFilters).some(val => val.length > 0) || !!searchValue.length) && (
        <Button mode="primary" outlined size="medium" label="Reset" handleClick={handleFilterReset} />
      )}
    </>
  );
};
