/* eslint-disable react/no-array-index-key */
import { DefaultLogo } from '@/components/default-logo/default-logo';
import { WorkExperienceType } from '../../types/types';
import './work-experience.scss';

type Props = {
  workExperience: WorkExperienceType[];
};

export const WorkExperience = ({ workExperience }: Props) => (
  <div className="work-experience">
    <div className="work-experience__title">Work Experience</div>
    <div className="work-experience__list">
      {workExperience?.map((work) => (
        <div className="work-experience__item" key={work.id}>
          <DefaultLogo
            type="company"
            source={work.company_icon_url}
            name={work.company_name}
            className="work-experience__item-icon"
            size={40}
          />
          <div className="work-experience__item-content">
            <div className="work-experience__item-title">{work.position}</div>
            <div className="work-experience__item-company">{work.company_name}</div>
            <div className="work-experience__item-location">{work.location}</div>
            <div className="work-experience__item-dates">
              {`${work.start_date} - ${work.end_date}`}
            </div>
            <ul className="work-experience__item-bullets">
              {work.bullets.map((bullet, index) => (
                <li key={`bullet-${index}`}>{bullet}</li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </div>
  </div>
);
