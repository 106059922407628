import './loader.scss';
// import careerOSLogoImage from '@/assets/images/icons/Logo_without_arrow.svg';
// import careerOSLogoMessage from '@/assets/images/icons/logo-message.svg';
import careerOSLogoImage from '@/assets/images/icons/logo-x-mas-small.svg';
import careerOSLogoMessage from '@/assets/images/icons/logo-arrow-x-mas.svg';
/**
 * Primary UI component for the loader
 */
export const Loader = (background?: any) => (
  <div data-testid="loader-wrapper" className={background ? 'loader-wrapper loader-wrapper--white' : 'loader-wrapper'}>
    <div className="loader">
      <div className="loader__logo">
        <img
          data-testid="loader-image"
          className="loader__image"
          src={careerOSLogoImage}
          role="presentation"
          alt=""
        />
      </div>
      <div data-testid="loader-active" className="loader__active">
        <img
          className="loader__image"
          src={careerOSLogoMessage}
          role="presentation"
          alt=""
        />
      </div>
    </div>
  </div>
);
