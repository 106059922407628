import {
  ApiStudent,
} from '@/career-advisor-v3/types/api/student';
import { Student } from '@/career-advisor-v3/types';
import { convertFromApiStudents } from '@/career-advisor-v3/api/model/student';
import { http } from '@/services/api/http';

export const getStudents = async (universityID: string, lastViewedAt: Date): Promise<Student[]> => {
  const url = `/university/students?last_viewed_at=${encodeURIComponent(lastViewedAt.toISOString())}`;

  const res: ApiStudent[] = await http.get(url, 'v2');

  return convertFromApiStudents(res);
};

export const sendMassEmail = async (message: string, subject: string, emails: string[]): Promise<any> => http.post('/university/advisor/sendmail', {
  body: message,
  subject,
  emails,
}, undefined, 'v2');
