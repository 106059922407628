import {
  useMemo, useState,
} from 'react';
import { Button, IconButton } from '@careeros/coco';

import { EmailThreadData, FirebaseEmailMessage, Message } from '../../types/messages';
import './email-thread.scss';
import { SingleMessage } from '@/domains/core/contact/components/message/message';
import { useActiveEmail } from '@/services/hooks/use-active-email';
import { useInboxContext } from '@/pages/context/inbox';
import { useSelf } from '@/services/queries/user';
import { validateEmail } from '@/services/helpers/contact';

type EmailThreadProps = {
  messages: FirebaseEmailMessage[];
  threadData: EmailThreadData | undefined;
  handleCloseThread: () => void;
};

export const EmailThread = ({
  messages,
  threadData,
  handleCloseThread,
}: EmailThreadProps) => {
  const [isThreadOpen, setIsThreadOpen] = useState(false);
  const activeEmail = useActiveEmail();
  const { data: self } = useSelf();
  const { handleReply, currentContact } = useInboxContext() || {};
  const threadLength = useMemo(() => messages.length, [messages.length]);
  const computeMessage = (msg: FirebaseEmailMessage): Message => ({
    type: 'email',
    fromContactID: msg.fromContactID || undefined,
    from: msg.from,
    to: msg.to,
    subject: msg.subject,
    ID: msg.ID,
    createdAt: msg.sentDateTime,
    text: msg.text,
  });

  const checkIfMessageIsFromUser = (msg: FirebaseEmailMessage) => {
    if (validateEmail(msg.to)) {
      return !msg.to.includes(activeEmail);
    }

    if (validateEmail(msg.from)) {
      return !msg.from.includes(activeEmail);
    }

    return msg.from === activeEmail
      || (currentContact?.email && msg.to.includes(currentContact?.email))
      || (currentContact?.secondary_email && msg.to.includes(currentContact?.secondary_email))
      || msg.fromContactID === self?.id
      || (msg.from.includes(self?.last_name || '') && msg.from.includes(self?.last_name || ''));
  };

  return (
    <div className="email-thread" data-testid="email-thread">
      <div className="email-thread__header">
        <IconButton
          handleClick={handleCloseThread}
          icon="bi bi-arrow-left"
          mode="unset"
          size="medium"
          data-testid="email-thread-close-button"
        />
        <h3 className="email-thread__thread-subject" data-testid="email-thread-subject">{threadData?.subject}</h3>
      </div>
      <div className="email-thread__messages" data-testid="email-thread-messages">
        {threadLength > 4 ? (
          <>
            <div className="email-thread__item" data-testid="email-thread-item">
              <SingleMessage
                handleReplyMessage={handleReply || (() => {})}
                message={computeMessage(messages[0])}
                contactFirstName={`${currentContact?.first_name || ''} ${currentContact?.last_name || ''}`}
                contactImage={currentContact?.career_summary.basics.image || ''}
                isRepliable={messages[0].from === activeEmail || messages[0].to === activeEmail}
                isMessageFromUser={checkIfMessageIsFromUser(messages[0])}
                contactEmail={currentContact?.email || ''}
              />
            </div>

            {isThreadOpen ? (
              <>
                {messages.slice(1, messages.length - 2).map((msg) => (
                  <div className="email-thread__item" data-testid="email-thread-item">
                    <SingleMessage
                      handleReplyMessage={handleReply || (() => {})}
                      message={computeMessage(msg)}
                      contactFirstName={`${currentContact?.first_name || ''} ${currentContact?.last_name || ''}`}
                      contactImage={currentContact?.career_summary.basics.image || ''}
                      isRepliable={msg.from === activeEmail || msg.to === activeEmail}
                      isMessageFromUser={checkIfMessageIsFromUser(msg)}
                      contactEmail={currentContact?.email || ''}
                    />
                  </div>
                ))}
              </>
            ) : (
              <div className="email-thread__item email-thread__item--expand" data-testid="email-thread-item">
                <div className="email-thread__expand" data-testid="email-thread-expand">
                  <Button
                    label={`View ${threadLength - 3} more messages`}
                    icon="bi bi-arrows-expand"
                    mode="rounded"
                    size="small"
                    outlined
                    handleClick={() => setIsThreadOpen(true)}
                  />
                </div>
              </div>
            )}
            {messages.slice(messages.length - 2, messages.length).map((msg, index) => (
              <div className="email-thread__item" data-testid="email-thread-item">
                <SingleMessage
                  handleReplyMessage={handleReply || (() => {})}
                  message={computeMessage(msg)}
                  contactFirstName={`${currentContact?.first_name || ''} ${currentContact?.last_name || ''}`}
                  contactImage={currentContact?.career_summary.basics.image || ''}
                  isRepliable={msg.from === activeEmail || msg.to === activeEmail}
                  isMessageFromUser={checkIfMessageIsFromUser(msg)}
                  contactEmail={currentContact?.email || ''}
                  isInitialMessage={index === 1}
                />
              </div>
            ))}

          </>
        ) : (
          <>
            {messages.map((msg, index) => (
              <div className="email-thread__item" data-testid="email-thread-item">
                <SingleMessage
                  handleReplyMessage={handleReply || (() => {})}
                  message={computeMessage(msg)}
                  contactFirstName={`${currentContact?.first_name || ''} ${currentContact?.last_name || ''}`}
                  contactImage={currentContact?.career_summary.basics.image || ''}
                  isRepliable={msg.from === activeEmail || msg.to === activeEmail}
                  isMessageFromUser={checkIfMessageIsFromUser(msg)}
                  contactEmail={currentContact?.email || ''}
                  isInitialMessage={index === messages.length - 1}
                />
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};
