import { useEffect, useRef, useState } from 'react';
import { Tooltip } from '@/components/tooltip/tooltip';
import HeartEyesEmoji from '@/assets/images/icons/emojis/smiling-face-with-heart-shaped-eyes.png';
import SmilingEmoji from '@/assets/images/icons/emojis/smiling-face-with-smiling-eyes.png';
import NeutralEmoji from '@/assets/images/icons/emojis/neutral-face.png';
import DottedEmoji from '@/assets/images/icons/emojis/dotted-line-face.png';
import './rate-button.scss';

const ratingEmojis: ArrayLike<string> = {
  0: DottedEmoji,
  1: NeutralEmoji,
  2: SmilingEmoji,
  3: HeartEyesEmoji,
  length: 4,
};

type Props = {
  currentRate: number;
  readOnly?: boolean;
  handleCompanyRate?: (rate: number) => Promise<void>;
};

export const CompanyRating = ({ currentRate, handleCompanyRate, readOnly = false }: Props) => {
  const [isSelecting, setIsSelecting] = useState(false);
  const [updatedRating, setUpdatedRating] = useState(currentRate);
  const boxRef = useRef<HTMLDivElement>(null);

  const handleRate = async (event: any, rating: number) => {
    event.stopPropagation();
    setIsSelecting(false);

    if (rating !== updatedRating && handleCompanyRate) {
      await handleCompanyRate(rating);
      setUpdatedRating(rating);
    }
  };

  const handleClick = (event: any) => {
    event.stopPropagation();
    if (readOnly) return;
    setIsSelecting(true);
  };

  const handleBlur = (e: any) => {
    const { target } = e;

    if (!boxRef.current?.contains(target)) {
      setIsSelecting(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleBlur);

    return () => document.removeEventListener('mousedown', handleBlur);
  }, []);

  useEffect(() => {
    setUpdatedRating(currentRate);
  }, [currentRate]);

  return (
    <div tabIndex={-1} className="company-rate" ref={boxRef}>
      {isSelecting ? (
        <div className="company-rate__emojis-container">
          <div className="company-rate__options">
            {Array.from(ratingEmojis).map((emoji, id) => (
              <img
                src={emoji}
                className="company-rate__emoji"
                onClick={(ev) => handleRate(ev, id)}
                key={emoji}
                alt="emoji rating"
              />
            ))}
          </div>
        </div>
      ) : (
        <Tooltip label="Select Hype Level" position="top" withArrow disableHoverListener={readOnly}>
          <img
            src={ratingEmojis[updatedRating]}
            onClick={(ev) => handleClick(ev)}
            className={`company-rate__emoji ${readOnly ? 'company-rate__emoji--readonly' : ''}`}
            alt="current emoji rating"
          />
        </Tooltip>
      )}
    </div>
  );
};
