import { useContext, useEffect, useState } from 'react';
import { useNavigate, useRevalidator } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';

import {
  AIToolsTab,
  changeApplicationStatus, Shortcuts, TrackerHeader, TrackerInfoTab, TrackerJobTab,
  TrackerQuests,
} from '@/domains/core/company';

import {
  APIstatuses, defaultTrackerTabsState, statuses, trackerData, TrackerModalProps, trackerTabs, TrackerTabsWithStates,
} from '@/domains/core/tracker-data';
import { CompanyStepper } from '@/domains/core/company/components/company-stepper/company-stepper';
import { InfoModal } from '@/domains/generic/modals';
import { TabsContent, TabsHeader } from '@/components/tabs';
import { Loader } from '@/components/loader/loader';
import { ModalContext } from '@/components/modal/modal-provider';
import './tracker-modal.scss';

import { useHash } from '@/services/hooks/use-hash';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { useSelfUniversity } from '@/services/queries/user';
import TrackerNotes from '@/domains/core/company/components/tracker-notes/tracker-notes';
import { TrackerContactTab } from '@/domains/core/company/components/tracker-contact-tab/tracker-contact-tab';
import { TrackerTipsTab } from '@/domains/core/company/components/tracker-tips-tab/tracker-tips-tab';
import { useTabs, withTabs } from '@/components/tabs/TabsContext';
import { QuestTileType, QuestType } from '@/domains/core/student/types';
import { getTemplates } from '@/services/api/template';
import { getNotesByCompany } from '@/services/api/company';
import { getCompanyContacts } from '@/services/api/contact';
import { Contact } from '@/domains/core/contact/types';
import { Note } from '../../types';
import { TrackerContextType, TrackerContext } from '../tracker-provider/tracker-context';
import { ToastContext } from '@/components/toast/toast-provider';
import { AddToastType } from '@/domains/generic/toasts/types';
import { sortQuests } from '@/services/helpers/quest';
import { useGetQuests } from '@/services/queries/quest';
import { COMPANY_STEPS } from '../../constants';

const SingleCompanyTrackerModal: React.FC<TrackerModalProps> = ({
  company, status, companyID, revalidate, openTab,
}: TrackerModalProps) => {
  const { user } = useAuth0();
  const navigate = useNavigate();
  const { closeTracker } = useContext(TrackerContext) as TrackerContextType;
  const { trackEvent } = useAnalytics();
  const revalidator = useRevalidator();
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [nonArchivedContacts, setNonArchivedContacts] = useState<Contact[]>([]);
  const [notes, setNotes] = useState<Note[]>([]);
  const [templates, setTemplates] = useState<any[]>([]);
  const defaultData: any = trackerData(company.name);
  const { currentTab, setCurrentTab } = useTabs();
  const [activeState, setActiveState] = useState(defaultData[status]);
  const [totalJobsCount, setTotalJobsCount] = useState<number>(0);
  const tabs = ['Tips & Tricks', 'AI Tools', 'Contacts', 'Jobs', 'Notes', 'Company'];
  const tabsIcons = ['bi bi-lightbulb', 'bi bi-stars', 'bi bi-person', 'bi bi-briefcase', 'bi bi-file-earmark-text', 'bi bi-building'];
  const [tabsStates, setTabsStates] = useState<TrackerTabsWithStates>(defaultTrackerTabsState);
  const { openModal, closeModal } = useContext(ModalContext) as any;
  const [tasks, setTasks] = useState([]);
  const [unreadTabs, setUnreadTabs] = useState<string[]>([]);
  const { data: university, isLoading: isUniversityLoading } = useSelfUniversity();
  const { handleHashChange } = useHash({
    company, status, templates, activeState, university: university?.name,
  });
  const { addToast } = useContext(ToastContext) as AddToastType;
  const [defaultTab, setDefaultTab] = useState<typeof trackerTabs[number]>(openTab || 'Tips & Tricks');
  const [quests, setQuests] = useState<QuestTileType[]>([]);
  const [networkingQuests, setNetworkingQuests] = useState<QuestTileType[]>([]);
  const [jobQuests, setJobQuests] = useState<QuestTileType[]>([]);
  const { hash, search } = window.location;
  const readTipsStatusesValueInStorage = `readTipsStatuses-${company.id}`;
  const { data: questResponses, isLoading: areQuestsLoading, isRefetching: areQuestsReloading } = useGetQuests();
  const queryClient = useQueryClient();

  const filterCompanyQuests = (questsToFilter: QuestTileType[]) => questsToFilter.filter((quest) => quest.company?.id === company.id);

  const filterNetworkingQuests = (questsToFilter: QuestTileType[]) => {
    const networkingQuestsTypes = [
      QuestType.AddNewContact,
      QuestType.RespondToEmail,
      QuestType.SaveContact,
      QuestType.SendConnection,
      QuestType.SendFirstEmail,
      QuestType.SendFollowUpEmail,
      QuestType.SendMessage,
      QuestType.ScheduleCoffeeChat,
      QuestType.SendFirstOutreach,
      QuestType.SendSecondOutreach,
      QuestType.ReplyToContact,
    ];
    return questsToFilter.filter((quest) => networkingQuestsTypes.includes(quest.type));
  };

  const filterJobQuests = (questsToFilter: QuestTileType[]) => {
    const jobQuestsTypes = [QuestType.ApplicationDeadline];
    return questsToFilter.filter((quest) => jobQuestsTypes.includes(quest.type));
  };

  const prepareQuests = async () => {
    const responseQuests = questResponses.quests ?? [];
    const companyQuests = filterCompanyQuests(responseQuests);
    const sortedAndFilteredQuests = sortQuests(companyQuests);
    const networkingQuestsRes = filterNetworkingQuests(sortedAndFilteredQuests);
    const jobQuestsRes = filterJobQuests(sortedAndFilteredQuests);

    setQuests(sortedAndFilteredQuests);
    setNetworkingQuests(networkingQuestsRes);
    setJobQuests(jobQuestsRes);
  };

  const updateContacts = async () => {
    const contactsResponse = await getCompanyContacts(companyID);

    if (Array.isArray(contactsResponse)) {
      setContacts(contactsResponse);
    } else {
      setContacts([]);
    }

    queryClient.invalidateQueries('quests');
    queryClient.invalidateQueries('contacts');
    queryClient.invalidateQueries('contacts-no-cache');
  };

  const revalidateJobsTab = () => {
    revalidate();

    queryClient.invalidateQueries(['applications']);
    queryClient.invalidateQueries('quests');
  };

  const handleQuestDelete = (questID: string) => {
    const filteredQuests = quests.filter((quest) => quest.id !== questID);
    setQuests(filteredQuests);
  };

  const trackerLoader = async () => {
    const [templatesResponse, contactsResponse, notesResponse] = await Promise.all([
      getTemplates(),
      getCompanyContacts(companyID),
      getNotesByCompany(companyID),
    ]);

    setContacts(contactsResponse || []);
    setNotes(notesResponse || []);
    setTemplates(templatesResponse || []);
  };

  const handleOfferRejection = async () => {
    try {
      closeModal();
      await changeApplicationStatus(company.application_id || '', statuses.Rejected, company.position || 0);
      revalidate();
      revalidator.revalidate();
      queryClient.invalidateQueries(['applications']);
      queryClient.invalidateQueries(['applications-no-cache']);
      trackEvent('Tracker page: Rejected offer', user, { company: company.name });
      closeTracker();
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const archiveCompany = async (rememberAction: boolean) => {
    try {
      if (rememberAction) {
        localStorage.setItem('rememberArchiveCompany', 'true');
      }
      closeModal();
      await changeApplicationStatus(company.application_id || '', statuses.Archived, company.position || 0);
      revalidate();
      revalidator.revalidate();
      await queryClient.invalidateQueries(['archive']);
      await queryClient.invalidateQueries(['applications']);
      closeTracker();
      navigate('/app/archive');
    } catch (error) {
      console.error('Error archiving company:', error);
      // Handle error here
    }
  };

  const openAreYouSureYouWantToArchive = () => {
    const rememberAction = localStorage.getItem('rememberArchiveCompany');
    if (!rememberAction) {
      openModal(
        <InfoModal
          icon="bi bi-exclamation-triangle-fill"
          handleButtonClick={archiveCompany}
          handleSecondaryButtonClick={closeModal}
          title="Are you sure?"
          description="Archiving will stop your progress with this company and remove it from your Overview Board. It will be moved to your Archive List."
          buttonLabel="Archive"
          secondaryButtonLabel="Cancel"
          rememberText="Remember my choice"
          isButtonDanger
        />,
      );
    } else {
      archiveCompany(false);
    }
  };

  const goToApplyingStage = async () => {
    try {
      const applicationID = company.application_id || '';
      const newStatus = 'applying';
      await changeApplicationStatus(applicationID, newStatus, company.position || 0);
      const properties = {
        newStatus,
        company: company.name,
      };
      trackEvent('Tracker page: Moved to applying', user, properties);
      revalidate();
      closeModal();
      window.scrollTo(0, 0);
      await queryClient.invalidateQueries(['archive']);
      await queryClient.invalidateQueries(['applications']);
    } catch (error) {
      addToast({
        type: 'error',
        message: 'Error',
        additionalMessage: 'Something went wrong. Please try again later.',
      });
    }
  };

  const checkUnreadTipsTabs = (newStatus: string) => {
    const readTipsStatusesFromStorage = localStorage.getItem(readTipsStatusesValueInStorage);
    const readTipsStatuses = readTipsStatusesFromStorage ? JSON.parse(readTipsStatusesFromStorage) : [];
    if (currentTab === 'Tips & Tricks') {
      localStorage.setItem(readTipsStatusesValueInStorage, JSON.stringify([...readTipsStatuses, status]));

      return;
    }

    if (!readTipsStatuses.includes(newStatus)) {
      setUnreadTabs((prev) => [...prev, 'Tips & Tricks']);
    }
  };

  const updateUnreadTabs = () => {
    if (currentTab && unreadTabs.includes(currentTab)) {
      const newUnreadTabs = unreadTabs.filter((tab) => tab !== currentTab || tab === 'Jobs');
      setUnreadTabs(newUnreadTabs);

      const readTipsStatusesFromStorage = localStorage.getItem(readTipsStatusesValueInStorage);
      const readTipsStatuses = readTipsStatusesFromStorage ? JSON.parse(readTipsStatusesFromStorage) : [];

      localStorage.setItem(readTipsStatusesValueInStorage, JSON.stringify([...readTipsStatuses, status]));
    }
  };

  const moveToApplying = () => {
    openModal(
      <InfoModal
        title="Do you want to move the company to Applying stage?"
        handleButtonClick={goToApplyingStage}
        handleSecondaryButtonClick={closeModal}
        secondaryButtonLabel="Keep it as it is"
        buttonLabel="Move to Applying"
        description="If you are working on an application for this company, you can move it to the Applying stage. This will help you get tips and tricks for the applying stage."
      />,
    );
  };

  const trackStatusChange = (prevStatus: string, newStatus: string) => {
    if (prevStatus === newStatus) {
      return;
    }

    if (newStatus !== 'Company Saved') {
      trackEvent(`Company moved to ${newStatus}`, user, { company: company.name });
    }

    const properties = {
      newStatus,
      company: company.name,
    };

    trackEvent('Tracker page: Move to next step', user, properties);
  };

  const updateJobsTabState = (unread: boolean) => {
    if (unread) {
      setUnreadTabs((prev) => [...prev, 'Jobs']);
    } else {
      setUnreadTabs((prev) => prev.filter((tab) => tab !== 'Jobs'));
    }
  };

  const handleStatusChange = async (newStatus: typeof COMPANY_STEPS[number]) => {
    if (status === statuses[newStatus]) {
      return;
    }

    try {
      await changeApplicationStatus(company.application_id || '', statuses[newStatus], company.position || 0);
      trackStatusChange(status, newStatus);

      addToast({
        type: 'success',
        message: 'Company status updated',
        additionalMessage: 'The company status has been successfully updated.',
      });
      revalidate();
      revalidator.revalidate();
      queryClient.invalidateQueries(['applications']);
      queryClient.invalidateQueries(['company-profile', company.id]);
      queryClient.invalidateQueries(['company', company.id]);
      queryClient.invalidateQueries(['applications-no-cache']);
    } catch (error) {
      addToast({
        type: 'error',
        message: 'Error',
        additionalMessage: 'Something went wrong. Please try again later.',
      });
    }
  };

  const handleTabStateChange = (tab: string, state?: string, setTabAsCurrent?: boolean) => {
    setTabsStates((prev) => ({ ...prev, [tab]: state }));
    if (setTabAsCurrent) {
      setCurrentTab(tab);
      // setDefaultTab(tab);
    }
  };

  const handleTabClick = (tab: string) => {
    if (tab === currentTab) {
      setTabsStates((prev) => ({ ...prev, [tab]: undefined }));
    }
  };

  useEffect(() => {
    if (defaultData[status]) {
      setActiveState(defaultData[status]);
      setTasks(defaultData[status].actions);
    }

    checkUnreadTipsTabs(status);
  }, [status]);

  useEffect(() => {
    trackerLoader();

    const searchParams = new URLSearchParams(search);

    if (searchParams.has('company-tracker-tab')) {
      setDefaultTab(decodeURIComponent(searchParams.get('company-tracker-tab') || defaultTab));
    }
  }, []);

  useEffect(() => {
    setNetworkingQuests(filterNetworkingQuests(quests));
    setJobQuests(filterJobQuests(quests));
  }, [quests]);

  useEffect(() => {
    if (contacts && Array.isArray(contacts)) {
      setNonArchivedContacts(contacts.filter((contact) => contact.kanban_position.status !== 'archive'));
    } else {
      setNonArchivedContacts([]);
    }
  }, [contacts]);

  useEffect(() => {
    handleHashChange();
    if (hash === '#startApplying') {
      if (status !== 'applying') {
        moveToApplying();
      }
    }

    if (hash === '#addContact') {
      handleTabStateChange('Contacts', 'addContact', true);
    }
    if (hash === '#applicationDeadline') {
      handleTabStateChange('Jobs', undefined, true);
    }
  }, [hash]);

  useEffect(() => {
    updateUnreadTabs();
  }, [currentTab]);

  useEffect(() => {
    if (!areQuestsLoading && !areQuestsReloading && questResponses) {
      prepareQuests();
    }
  }, [areQuestsLoading, areQuestsReloading]);

  if (!company || isUniversityLoading) {
    return (
      <div id="loader-zone">
        <Loader />
      </div>
    );
  }

  return (
    <div id="main" className="tracker-modal">
      {company && company.id ? (
        <>
          <TrackerHeader
            logo={company.logo_url}
            name={company.name}
          />
          <div className="tracker-modal__stepper">
            <CompanyStepper handleStepClick={handleStatusChange} activeState={APIstatuses[status]} />
          </div>
          <div className="tracker-modal__tabs-header">
            <TabsHeader
              withBorder
              onTabClick={handleTabClick}
              tabs={tabs}
              icons={tabsIcons}
              defaultTab={defaultTab}
              unreadTabs={unreadTabs}
              tabCounters={{ Contacts: nonArchivedContacts?.length, Jobs: totalJobsCount || 0, Notes: notes?.length || 0 }}
              containerName="company-tracker"
              counterTooltips={{ Jobs: 'Job Postings' }}
            />
          </div>
          <div className={`tracker-modal__grid ${currentTab === 'Jobs' ? 'tracker-modal__grid--no-columns' : ''}`}>
            <div className="width-18/24">
              <TabsContent>
                <div data-tab="Tips & Tricks">
                  {(quests?.length > 0 && !tabsStates['Tips & Tricks']) && (
                    <TrackerQuests
                      quests={quests}
                      handleQuestDelete={handleQuestDelete}
                      changeTab={handleTabStateChange}
                    />
                  )}
                  {!isUniversityLoading && (
                  <TrackerTipsTab
                    changeTab={handleTabStateChange}
                    tabState={tabsStates['Tips & Tricks']}
                    applicationId={company.application_id || ''}
                    activeState={activeState}
                    status={status}
                    tasks={tasks}
                    university={university?.name}
                    handleRejection={handleOfferRejection}
                  />
                  )}
                </div>
                {tabs.includes('AI Tools') && (
                  <div data-tab="AI Tools">
                    <AIToolsTab
                      handleNoteAdded={(note: Note) => setNotes((prev) => [note, ...prev])}
                      companyID={company.id}
                      preselectedTool={tabsStates['AI Tools']}
                      companyName={company.name}
                      changeTab={(tab, state) => handleTabStateChange(tab, state, true)}
                      jobs={company.jobs || []}
                      companyLinkedIn={company.linkedin_url}
                    />
                  </div>
                )}
                <div data-tab="Contacts">
                  {networkingQuests?.length > 0 && (
                    <TrackerQuests
                      quests={networkingQuests}
                      handleQuestDelete={handleQuestDelete}
                      changeTab={handleTabStateChange}
                    />
                  )}
                  <TrackerContactTab
                    changeTab={handleTabStateChange}
                    tabState={tabsStates.Contacts}
                    updateContacts={updateContacts}
                    contacts={nonArchivedContacts || []}
                    companyLinkedIn={company.linkedin_url}
                    companyId={company.id}
                    companyName={company.name}
                    allContactsUrls={Array.isArray(contacts) ? contacts.map((contact) => contact.linkedin_url) : []}
                  />
                </div>
                <div data-tab="Company">
                  <TrackerInfoTab
                    aboutCompany={company.overview}
                    name={company.name}
                    logoUrl={company.logo_url}
                    website={company.company_url}
                    industry={company.industry || company.careeros_industry}
                    location={company.hq_location}
                  />
                </div>
                <div data-tab="Jobs">
                  {(jobQuests?.length > 0 && !tabsStates.Jobs) && (
                    <TrackerQuests
                      quests={jobQuests}
                      handleQuestDelete={handleQuestDelete}
                      changeTab={handleTabStateChange}
                    />
                  )}
                  <TrackerJobTab
                    revalidate={revalidateJobsTab}
                    tabState={tabsStates.Jobs}
                    changeTab={handleTabStateChange}
                    setTotalJobsCount={setTotalJobsCount}
                    user={user}
                    companyName={company.name}
                    companyId={company.id}
                    companyLinkedIn={company.linkedin_url}
                    companyLogo={company.logo_url}
                    updateUnreadIndicator={updateJobsTabState}
                  />
                </div>
                <div className="notes-tab" data-tab="Notes">
                  <TrackerNotes
                    setNotes={setNotes}
                    notes={notes}
                    companyId={company.id}
                  />
                </div>
              </TabsContent>
            </div>
            {currentTab !== 'Jobs' && (
              <div className="width-6/24">
                <div className="tracker-modal__shortcuts">
                  <Shortcuts
                    changeTab={handleTabStateChange}
                    companyLinkedIn={company.linkedin_url}
                    handleArchiveClicked={openAreYouSureYouWantToArchive}
                  />
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <p>
          <i>Waves hand! This is not the company you are looking for!</i>
        </p>
      )}
    </div>
  );
};

export default withTabs(SingleCompanyTrackerModal);
