import Lottie from 'react-lottie';
import LoaderGif from '@/assets/images/ai-loader.json';

import './ai-tool-loader.scss';

type Props = {
  text?: string | React.ReactNode;
};

export const AIToolLoader = ({ text }: Props) => (
  <div className="ai-tool-loader">
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: LoaderGif,
      }}
      height={150}
      width={150}
      style={{
        background: 'transparent',
      }}
    />
    {text && (
      <span className="ai-tool-loader__text">
        {text}
      </span>
    )}
  </div>
);
