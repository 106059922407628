import { http } from '@/services/api/http';

export const getContactsForContactsBook = async (
  cohortID: string = '',
  page: number = 1,
  bookmarked?: boolean,
  searchQuery?: string,
) => {
  const url = `/university/students/contacts?page=${page}${
    bookmarked ? '&bookmarked=true' : ''
  }${searchQuery ? `&${decodeURIComponent(searchQuery)}` : ''}${
    cohortID !== '' ? `&cohortID=${cohortID}` : ''
  }`;

  const res = await http.get(url, 'v2');

  return res;
};

export const getContactInfoById = async (contactID: string) => {
  const url = `/university/students/contacts/${contactID}`;

  const res = await http.get(url, 'v2');

  return res;
};
export const getCompaniesAndIndustries = async () => {
  const url = '/university/students/contacts/companies';

  const res = await http.get(url, 'v2');

  return res;
};
export const toggleSaveContact = async (
  contactID: string,
  advisorID: string,
) => {
  const url = `/university/contacts/${contactID}/assign/${advisorID}`;

  const res = await http.post(url, {}, undefined, 'v2');

  return res;
};
