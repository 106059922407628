import {
  useDeferredValue, useEffect, useMemo, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import '@/pages/styles/contact.scss';
import '@/pages/styles/dashboard.scss';
import { Checkbox } from '@careeros/coco';
import { Input } from '@/components/input/input';
import CustomWithAuthenticationRequired from '../../pages/auth/custom-protected-route';
import { ToggleViews } from '@/components/toggle-views/toggle-views';
import { Loader } from '@/components/loader/loader';
import { CohortsNavigation } from '@/domains/core/advisor/components/cohorts-navigation/cohorts-navigation';
import { MiniAdvisor, Student, StudentStatus } from '@/career-advisor-v3/types';
import StudentKanban from '@/career-advisor-v3/containers/student-kanban/student-kanban';
import { useGetCohortsByUniversityID, useGetStudentsByUniversity } from '@/services/queries/student';
import { useSelf, useSelfUniversity } from '@/services/queries/user';
import { Cohort, CohortLinkDataType } from '@/domains/core/advisor/types';
import { ExportStudentData } from '@/domains/core/advisor/components/export-student-data/export-student-data';
import StudentTable from '@/career-advisor-v3/containers/student-table/student-table';
import { useGetUniversityTags } from '@/services/queries/tag';
import { MultiSelect } from '@/components/multi-select/multi-select';
import { FilterOption } from '@/domains/core/company/types';
import { buildUniversityCohortListLinks } from '../cohorts';
import { TagType } from '@/components/tag/tag';
import { useLastViewedAt } from '@/career-advisor-v3/lastview';
import { useGetAllAdvisors } from '@/services/queries/advisor';
import './styles/students-board.scss';

const columns: StudentStatus[] = [
  'Inactive',
  'Companies Saved',
  'Networking',
  'Applying',
  'Interviewing',
  'Offer',
];

type FilterState = {
  searchQuery: string,
  selectedCohort: string | null,
  assignedToMe: boolean,
  selectedTags: FilterOption[],
};

const StudentsOverviewPage = () => {
  const [selectedView, setSelectedView] = useState<'list' | 'kanban'>('kanban');
  const [filteredStudents, setFilteredStudents] = useState<Student[]>([]);
  const lastViewedAt = useLastViewedAt();
  // TODO: create type for filters
  const [filters, setFilters] = useState<FilterState>({
    searchQuery: '',
    selectedCohort: null,
    assignedToMe: false,
    selectedTags: [],
  });

  const { universityID: universityIDFromParams } = useParams();
  const { data: university } = useSelfUniversity();
  const deferredQuery = useDeferredValue(filters.searchQuery);

  const universityID = useMemo(() => {
    if (universityIDFromParams) {
      return universityIDFromParams;
    }

    if (!university) {
      return undefined;
    }

    return university.id;
  }, [universityIDFromParams, university]);

  const shouldFetchUniversityData = { enabled: !!universityID };

  const {
    data: students,
    isLoading: areStudentsLoading,
  } = useGetStudentsByUniversity(universityID || '', lastViewedAt, shouldFetchUniversityData);

  const {
    data: cohorts,
    isLoading: areCohortsLoading,
  } = useGetCohortsByUniversityID(universityID || '', shouldFetchUniversityData);

  const {
    data: tags,
    isLoading: areTagsLoading,
  } = useGetUniversityTags(universityID || '', shouldFetchUniversityData);

  const {
    data: allAdvisors,
  } = useGetAllAdvisors(universityID || '');

  const { cohortID } = useParams();
  const {
    data: self,
    isLoading: isSelfLoading,
  } = useSelf();

  const validatedStudents = useMemo<Student[]>(() => (Array.isArray(students) ? students : []), [students]);
  const validatedCohorts = useMemo<Cohort[]>(() => (Array.isArray(cohorts) ? cohorts : []), [cohorts]);
  const validatedTags = useMemo<TagType[]>(() => (Array.isArray(tags) ? tags : []), [tags]);
  const validatedAdvisorList = useMemo<MiniAdvisor[]>(() => (Array.isArray(allAdvisors) ? allAdvisors : []), [allAdvisors]);
  const tagOptions = useMemo<FilterOption[]>(() => validatedTags.map((tag): FilterOption => ({
    name: tag.label,
    value: tag.id,
  })), [validatedTags]);

  const cohortLinks: CohortLinkDataType[] = useMemo(() => {
    if (!universityID) {
      return [];
    }

    // does jot include filters - on redirect to cohort page, filters will be reset
    return buildUniversityCohortListLinks('/app/advisor/students', universityID, validatedCohorts);
  }, [validatedCohorts, universityID]);

  const filterByCohort = (studentsArray: Student[], cohortId: string | null) => {
    if (!cohortId) return studentsArray;
    return studentsArray.filter(student => student.cohort?.id === cohortId);
  };

  const filterBySearch = (studentsArray: Student[], query: string) => {
    if (!query) return studentsArray;
    return studentsArray.filter(student => `${student.firstName} ${student.lastName}`
      .toLowerCase()
      .includes(query.toLowerCase()));
  };

  const filterByAssignedAdvisor = (studentsArray: Student[], assignedToMe: boolean, selfId: string) => {
    if (!assignedToMe) return studentsArray;
    return studentsArray.filter(student => student.assignedAdvisors?.some(assignee => assignee.id === selfId));
  };

  const filterByTags = (studentsArray: Student[], selectedTags: FilterOption[]) => {
    if (selectedTags.length === 0) return studentsArray;
    return studentsArray.filter(student => selectedTags.some(tag => student.tags?.some(studentTag => studentTag.id === tag.value)));
  };

  const applyFilters = () => {
    let result = [...validatedStudents];

    result = filterByCohort(result, filters.selectedCohort);
    result = filterBySearch(result, deferredQuery);
    result = filterByAssignedAdvisor(result, filters.assignedToMe, self?.id || '');
    result = filterByTags(result, filters.selectedTags);

    setFilteredStudents(result);
  };

  const handleSearchChange = (query: string) => {
    setFilters(prev => ({ ...prev, searchQuery: query }));
  };

  const handleCohortChange = (cohortId: string | null) => {
    setFilters(prev => ({ ...prev, selectedCohort: cohortId }));
  };

  const handleAssignedToMeChange = (assigned: boolean) => {
    setFilters(prev => ({ ...prev, assignedToMe: assigned }));
  };

  const handleTagsChange = (tagsArray: FilterOption[]) => {
    setFilters(prev => ({ ...prev, selectedTags: tagsArray }));
  };

  useEffect(() => {
    applyFilters();
  }, [validatedStudents, deferredQuery, filters, self]);

  useEffect(() => {
    if (cohortID) {
      handleCohortChange(cohortID);
    } else {
      handleCohortChange(null);
    }
  }, [cohortID]);

  if (areStudentsLoading || areCohortsLoading || isSelfLoading || areTagsLoading) {
    return (
      <div id="loader">
        <Loader />
      </div>
    );
  }

  return (
    <div className="contacts-page" id="main">
      <div className="contacts-page__top-bar">
        <ToggleViews
          labels={['Kanban View', 'List View']}
          size="small"
          selectedValue={selectedView === 'kanban' ? 0 : 1}
          emitSelectedValue={(value) => setSelectedView(value === 0 ? 'kanban' : 'list')}
          icons={['bi-kanban', 'bi-grid-3x3']}
          iconsSelected={['bi-kanban-fill', 'bi-table']}
        />

        <div className="contacts-page__search">
          <Input
            icon="bi bi-search"
            inputSize="medium"
            placeholder="Search by student's first or last name"
            value={filters.searchQuery}
            id="contacts-search"
            label=""
            handleValueChange={handleSearchChange}
          />
        </div>

        {selectedView === 'list' ? (
          <div className="width-6/24">
            <MultiSelect
              handleSelectedOptions={handleTagsChange}
              labelType="number"
              options={tagOptions}
              placeholder="Filter by tags"
              selected={filters.selectedTags}
              size="full"
              withSelectAllOption
            />
          </div>
        ) : null}

        <Checkbox
          id="assigned-to-me"
          label="Show only students assigned to me"
          checked={filters.assignedToMe}
          onChange={() => handleAssignedToMeChange(!filters.assignedToMe)}
        />

        <ExportStudentData universityID={universityID} cohortID={cohortID} />
      </div>

      <div className="students-board__top-container">
        <div className="students-board__top">
          <CohortsNavigation links={cohortLinks} />
        </div>
      </div>

      {selectedView === 'kanban'
        ? (
          <StudentKanban universityID={universityID || ''} columns={columns} studentsData={filteredStudents} allAdvisors={validatedAdvisorList} />
        ) : (
          <StudentTable universityID={universityID || ''} tags={validatedTags} students={filteredStudents} allAdvisors={validatedAdvisorList} />
        )}
    </div>
  );
};

export default CustomWithAuthenticationRequired(StudentsOverviewPage);
