import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import './styles/login.scss';
import { router } from '@/main';
import { Loader } from '@/components/loader/loader';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { sendMessageToExtension } from '@/services/hooks/use-extension-messaging';
import { Benefits } from '../domains/generic/auth/benefits/benefits';
import { LoginInfo } from '../domains/generic/auth/login-info/login-info';
import { routeByRole } from '@/services/helpers/user';

export default function LoginPage() {
  const {
    isAuthenticated, isLoading, getAccessTokenSilently, user,
  } = useAuth0();
  const [searchParams] = useSearchParams();
  const isExtensionLogin = searchParams.get('extension_login');
  const { trackEvent } = useAnalytics();

  const loginToExtension = async () => {
    const accessToken = await getAccessTokenSilently();

    try {
      await sendMessageToExtension('Set access token', accessToken);
      trackEvent('Successful Login in Chrome Extension');
    } catch (error) {
      console.log('access token not sent: %s', error);
      trackEvent('Failed Login in Chrome Extension');
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    if (isExtensionLogin) {
      loginToExtension();
    }

    const defaultRoute = user ? routeByRole(user) : '/app/dashboard';
    trackEvent('Successful login', {});
    router.navigate(defaultRoute);
  }, [isAuthenticated]);

  return (
    <div>
      {isLoading ? (
        <div id="loader-zone">
          <Loader />
        </div>
      ) : (
        <div className="auth-screen">
          <div className="career-os-grid">
            <div className="width-10/24">
              <LoginInfo />
            </div>
            <Benefits />
          </div>
        </div>
      )}
    </div>
  );
}
