import { useQuery } from 'react-query';
import { getCAStudentNotes } from '../api/note';
import { CAStudentNote } from '../types/note';

export const useStudentNotes = (studentID: string, options = {}) => useQuery(
  ['ca-student-notes', studentID],
  async () => {
    const studentNotes: CAStudentNote[] = await getCAStudentNotes(studentID);

    return Array.isArray(studentNotes) ? studentNotes : [];
  },
  {
    retry: 0,
    // should be refetched in the background every 8 hours
    staleTime: 1000 * 60 * 60 * 1,
    ...options, // Spread additional options
  },
);
