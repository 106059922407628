import { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import { DefaultLogo } from '@/components/default-logo/default-logo';
import { SearchBar } from '@/domains/generic/search';

import './topbar.scss';

type Props = {
  title: string | ReactElement;
  withSearch?: boolean;
  withProfile?: boolean;
  profileImage?: string;
  userName?: string;
};

export const TopbarDumb = ({
  title,
  withSearch = false,
  withProfile = false,
  profileImage,
  userName,
}: Props) => (
  <div className="topbar">
    <div className="topbar__title">
      {title}
    </div>
    <div className="topbar__search">{withSearch && <SearchBar />}</div>
    <div id="topbar-menu" className="topbar__menu">
      {/* <div className="topbar__menu__notifications">
          <img src={NotificationsIcon} alt="" />
        </div> */}
      {withProfile && (
        <div className="topbar__profile">
          <NavLink to="profile">
            <DefaultLogo
              size={32}
              type="contact"
              source={profileImage || ''}
              className=""
              name={userName || 'U'}
              icon="bi bi-gear"
            />
            <i className="topbar__profile-settings bi bi-gear-fill" />
          </NavLink>
        </div>
      )}
    </div>
  </div>
);
