import { LinkType, NavbarItemName } from '../../types/types';
import { NavbarCollapse } from '../collapse-button/collapse-button';
import { NavbarLinks } from '../navbar-links/navbar-links';
import { NavbarLogo } from '../navbar-logo/navbar-logo';

import './navbar-view.scss';

interface SidebarViewProps {
  collapsed: boolean;
  isCookieBannerVisible: boolean;
  showContent: boolean;
  logoLink: string;
  links: LinkType[];
  splitBefore?: string;
  unreadTabs?: NavbarItemName[];
  handleLinkClick: (link: string) => void;
  handleToggle: () => void;
  hasStudentAppView?: boolean;
  studentLinks?: LinkType[];
}

export const NavbarView = ({
  collapsed,
  isCookieBannerVisible,
  showContent,
  logoLink,
  links,
  splitBefore,
  handleToggle,
  handleLinkClick,
  unreadTabs = [],
  hasStudentAppView = false,
  studentLinks,
}: SidebarViewProps) => (
  <aside
    className={[
      'sidebar',
      collapsed ? 'sidebar--collapsed' : '',
      isCookieBannerVisible ? 'sidebar--with-cookie-banner' : '',
    ].join(' ')}
  >
    {showContent && <NavbarLogo logoLink={logoLink} collapsed={collapsed} />}
    {showContent && (
      <nav className="sidebar__navigation">
        <NavbarLinks
          links={links}
          collapsed={collapsed}
          handleLinkClick={handleLinkClick}
          splitBefore={splitBefore}
          unreadTabs={unreadTabs}
          hasStudentAppView={hasStudentAppView}
          studentLinks={studentLinks}
        />
        <NavbarCollapse
          handleCollapse={handleToggle}
          isCollapsed={collapsed}
        />
      </nav>
    )}
  </aside>
);
