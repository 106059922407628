import { IconButton } from '@careeros/coco';
import { TableRow } from '@/components/table/table-types';
import { TagType } from '@/components/tag/tag';
import { addTagToStudent, deleteTagFromUniversity, removeTagFromStudent } from '../api/advisor';
import { Cohort, Student } from '@/domains/core/advisor/types';
import { Tooltip } from '@/components/tooltip/tooltip';
import { Badge } from '@/components/badge/badge';
import { Alumn } from '@/types/alumn';

function findLengthByUserID(data: any, userID: string) {
  // Iterate over each sub-array
  // eslint-disable-next-line no-restricted-syntax
  for (const subArray of data) {
    // Check if the sub-array is an array and contains objects with the specified userID
    if (Array.isArray(subArray)) {
      const filteredData = subArray.filter(item => item.user_id === userID);
      if (filteredData.length > 0) {
        // Return the length of the first matching sub-array
        return filteredData.length;
      }
    }
  }

  // Return 0 if no matching sub-array is found
  return 0;
}

export const mapCompaniesSavedByStudentsToTableData = (companies: any[], universityId: string, cohortId: string): TableRow[] => companies.map((company: any) => ({
  id: company.company_id,
  items: {
    company_name: {
      label: company.company_name,
      value: company.company_name,
    },
    industry: {
      label: company.industry,
      value: company.industry,
    },
    count: {
      label: company.count,
      value: company.count,
    },
    company_id: {
      label: company.company_id,
      value: company.company_id,
    },
    logo: {
      label: '',
      value: '',
      type: 'image',
      src: company.logo_url,
      alt: 'Networking Icon',
    },
    actions: {
      label: '',
      value: '',
      type: 'link-icon',
      icon: 'bi bi-eye',
      tooltip: 'See Engagement',
      to: cohortId && cohortId !== ''
        ? `/app/company-overview/${company.company_id}/cohort/${cohortId}`
        : `/app/company-overview/${company.company_id}/university/${universityId}`,
    },
  },
}));

const handleAddTag = async (tag: TagType, student: Student) => {
  await addTagToStudent(tag, student.id, student.university_id);
};

const handleRemoveTag = async (tag: TagType, student: Student) => {
  await removeTagFromStudent(tag, student.id, student.university_id);
};

const handleDeleteTagFromUniversity = async (tagId: string, universityId: string) => {
  await deleteTagFromUniversity(tagId, universityId);
};

export const mapStudentsToResumeTableData = (students: Student[]): TableRow[] => students.map((student: any) => ({
  id: student.id,
  items: {
    full_name: {
      label: student.name,
      value: student.name,
    },
    base_resumes: {
      label: student.base_resume_count,
      value: student.base_resume_count,
    },
    tailored_resumes: {
      label: student.tailored_resume_count,
      value: student.tailored_resume_count,
    },
    resumes: {
      label: student.base_resume_count + (student.tailored_resume_count),
      value: student.base_resume_count + (student.tailored_resume_count),
    },
    actions: {
      type: 'link-icon',
      icon: 'bi bi-eye',
      tooltip: 'See Student Resumes',
      to: `/app/resume-os/student-resumes/${student.id}`,
      state: { name: student.name, message: 'Hello from Home!' },
      label: '',
      value: '',
    },
  },
}));

const extractCurrentWorkPosition = (careerSummary: string) => {
  if (!careerSummary) return null;

  const parsedJSON = JSON.parse(careerSummary);
  const needsMoreParsing = parsedJSON && typeof parsedJSON === 'string';

  const parsedCareerSummary = needsMoreParsing ? JSON.parse(parsedJSON) : parsedJSON;

  if (!parsedCareerSummary?.work) {
    return null;
  }

  return parsedCareerSummary?.work[0].position;
};

const extractCurrentWorkCompany = (careerSummary: string) => {
  if (!careerSummary) return null;

  const parsedJSON = JSON.parse(careerSummary);
  const needsMoreParsing = parsedJSON && typeof parsedJSON === 'string';

  const parsedCareerSummary = needsMoreParsing ? JSON.parse(parsedJSON) : parsedJSON;

  if (!parsedCareerSummary?.work) {
    return null;
  }

  return parsedCareerSummary?.work[0].name;
};

const extractLanguages = (careerSummary: string) => {
  if (!careerSummary) return null;

  const parsedJSON = JSON.parse(careerSummary);
  const needsMoreParsing = parsedJSON && typeof parsedJSON === 'string';
  const parsedCareerSummary = needsMoreParsing ? JSON.parse(parsedJSON) : parsedJSON;

  if (!parsedCareerSummary?.languages) {
    return null;
  }

  return parsedCareerSummary.languages
    .map((lang: { language: string }) => lang.language)
    .join(', ');
};

const extractLocation = (careerSummary: string) => {
  if (!careerSummary) return null;

  const parsedJSON = JSON.parse(careerSummary);
  const needsMoreParsing = parsedJSON && typeof parsedJSON === 'string';
  const parsedCareerSummary = needsMoreParsing ? JSON.parse(parsedJSON) : parsedJSON;

  if (!parsedCareerSummary?.basics?.location?.address) {
    return null;
  }

  return parsedCareerSummary.basics.location.address;
};

const extractPreviousWork = (careerSummary: string) => {
  if (!careerSummary) return null;

  const parsedJSON = JSON.parse(careerSummary);
  const needsMoreParsing = parsedJSON && typeof parsedJSON === 'string';
  const parsedCareerSummary = needsMoreParsing ? JSON.parse(parsedJSON) : parsedJSON;

  if (!parsedCareerSummary?.work || parsedCareerSummary.work.length <= 1) {
    return null;
  }

  // Get the second most recent position (index 1)
  const previousWork = parsedCareerSummary.work[1];
  return `${previousWork.position} at ${previousWork.name}`;
};

const extractEducation = (careerSummary: string, index: number = 0) => {
  if (!careerSummary) return null;

  const parsedJSON = JSON.parse(careerSummary);
  const needsMoreParsing = parsedJSON && typeof parsedJSON === 'string';
  const parsedCareerSummary = needsMoreParsing ? JSON.parse(parsedJSON) : parsedJSON;

  if (!parsedCareerSummary?.education || !parsedCareerSummary.education[index]) {
    return null;
  }

  const education = parsedCareerSummary.education[index];
  return `${education.studyType} in ${education.area || ''} at ${education.institution}`;
};

export const mapStudentsToAlumniTableData = (alumni: Alumn[]): TableRow[] => alumni.map((alumn: Alumn) => ({
  id: alumn.id,
  items: {
    full_name: {
      label: `${alumn.first_name} ${alumn.last_name}`,
      value: `${alumn.first_name} ${alumn.last_name}`,
    },
    current_company: {
      label: extractCurrentWorkCompany(alumn.career_summary.profile) || 'No Data',
      value: extractCurrentWorkCompany(alumn.career_summary.profile) || 'No Data',
    },
    current_position: {
      label: extractCurrentWorkPosition(alumn.career_summary.profile) || 'No Data',
      value: extractCurrentWorkPosition(alumn.career_summary.profile) || 'No Data',
    },
    job_after_graduation: {
      label: extractCurrentWorkCompany(alumn.career_summary.profile) ? (
        <div className="contacts-page__statuses">
          <Badge color="light-purple" label="Yes" />
        </div>
      ) : (
        <div className="contacts-page__statuses">
          <Badge color="dark-grey" label="No" />
        </div>
      ),
      value: 'Yes',
    },
    professional_email: {
      label: alumn.email || 'No Data',
      value: alumn.email,
    },
    phone_number: {
      label: 'No Data', // Since phone number is not available in the provided data
      value: 'No Data',
    },
    current_industry: {
      label: 'No Data', // Industry is not directly available in the provided data
      value: 'No Data',
    },
    current_location: {
      label: extractLocation(alumn.career_summary.profile) || 'No Data',
      value: extractLocation(alumn.career_summary.profile) || 'No Data',
    },
    languages: {
      label: extractLanguages(alumn.career_summary.profile) || 'No Data',
      value: extractLanguages(alumn.career_summary.profile) || 'No Data',
    },
    previous_work: {
      label: extractPreviousWork(alumn.career_summary.profile) || 'No Data',
      value: extractPreviousWork(alumn.career_summary.profile) || 'No Data',
    },
    last_education: {
      label: extractEducation(alumn.career_summary.profile, 0) || 'No Data',
      value: extractEducation(alumn.career_summary.profile, 0) || 'No Data',
    },
    previous_education: {
      label: extractEducation(alumn.career_summary.profile, 1) || 'No Data',
      value: extractEducation(alumn.career_summary.profile, 1) || 'No Data',
    },
    personal_email: {
      label: alumn.email || 'No Data',
      value: alumn.email,
    },
    actions: {
      label: (
        <Tooltip label="View profile" position="top" withArrow>
          <IconButton
            icon="bi bi-linkedin"
            mode="rounded"
            size="medium"
            outlined
            handleClick={() => window.open(`https://www.linkedin.com/in/${alumn.linkedin_slug}`, '_blank')}
            hoverIcon="bi bi-box-arrow-up-right"
          />
        </Tooltip>
      ),
      value: '',
    },
  },
}));

function sumCountOfContacts(data: any, targetUserID:string) {
  // Flatten the nested array structure
  const flattenedData = data.flat();

  // Filter companies by userID
  const userCompanies = flattenedData.filter((company: any) => company.user_id === targetUserID);

  // Sum the count_of_contacts
  const totalContacts = userCompanies.reduce((sum: number, company: any) => sum + company.count_of_contacts, 0);

  return totalContacts;
}

export const mapStudentsToTableData = (students: Student[], tiles: any, tags: TagType[]): TableRow[] => students.map((student: any) => ({
  id: student.id,
  items: {
    full_name: {
      label: student.name,
      value: student.name,
    },
    email: {
      label: student.email,
      value: student.email,
    },
    networking: {
      type: 'icon',
      src: student?.messages_count?.messages_sent > 0 ? 'bi bi-check-circle-fill' : 'bi bi-x-circle',
      alt: 'Networking Icon',
      label: 'networking',
      value: student.messages_count.messages_sent,
    },
    companies: {
      label: findLengthByUserID(tiles, student.id),
      value: findLengthByUserID(tiles, student.id),
    },
    contacts: {
      label: sumCountOfContacts(tiles, student.id),
      value: sumCountOfContacts(tiles, student.id),
    },
    actions: {
      type: 'link-icon',
      icon: 'bi bi-eye',
      tooltip: 'See Overview Board',
      to: `/app/student-overview/${student.id}/university/${student.university_id}`,
      label: '',
      value: '',
    },
    action_plan: {
      type: 'link-icon',
      icon: 'bi bi-clipboard-data',
      tooltip: 'See Action Plan',
      to: `/app/action-plan/${student.id}`,
      label: '',
      value: '',
    },
    documents: {
      type: 'link-icon',
      icon: 'bi bi-file-earmark',
      tooltip: 'See Documents',
      to: `/app/student-overview/${student.id}/university/${student.university_id}#Documents`,
      label: '',
      value: '',
    },
    tags: {
      type: 'tags',
      tags: student.tags,
      allTags: tags,
      addTag: (tag) => handleAddTag(tag, student),
      removeTag: (tag) => handleRemoveTag(tag, student),
      deleteTag: (tag) => handleDeleteTagFromUniversity(tag.id, student.university_id),
      student,
      label: '',
      value: '',
      id: student.id,
    },
  },
}));

export const buildCohortsWithAll = (basePath: string, cohorts: Cohort[], universityID: string | undefined, universityDefaultId: string | undefined) => {
  const cohortNames = Array.isArray(cohorts)
    ? cohorts.map((cohort: Cohort) => ({
      label: cohort.name,
      url: `${basePath}/${universityID || universityDefaultId}/${cohort.id}`,
    }))
    : [];

  return [
    {
      label: 'All',
      url: `${basePath}/${universityID || universityDefaultId}`,
    },
    ...cohortNames,
  ];
};
