import { ApiMiniAdvisor } from '@/career-advisor-v3/types/api/advisor';
import { MiniAdvisor } from '@/career-advisor-v3/types';

export const convertFromApiMiniAdvisor = (apiMiniAdvisor: ApiMiniAdvisor): MiniAdvisor => ({
  id: apiMiniAdvisor.id,
  firstName: apiMiniAdvisor.first_name,
  profileImage: apiMiniAdvisor.profile_image,
});

export const convertFromApiMiniAdvisors = (apiMiniAdvisors: ApiMiniAdvisor[]): MiniAdvisor[] => apiMiniAdvisors.map(convertFromApiMiniAdvisor);
