import {
  ReactNode, useEffect, useMemo, useRef, useState,
} from 'react';
import { IconButton, InfoModal } from '@careeros/coco';
import ReactDOM from 'react-dom';
import { Tag, TagType, TagColors } from '../tag/tag';

import './manage-tags.scss';

type Props = {
  id: string; // Add this line
  selectedTags: TagType[];
  existingTags: TagType[];
  handleTagRemove: (tag: TagType) => void;
  withRemoveEntirelyOption?: boolean;
  handleTagRemoveEntirely?: (tag: TagType) => void;
  handleTagAdd: (tag: TagType, createFirst?: boolean) => void;
  handleClose: () => void;
  handleDeleteTagFromUniversity: (tag: TagType) => Promise<void>;
  openModal: (content: ReactNode) => void;
  closeModal: () => void;
};

function getRandomColor() {
  const colors = Object.values(TagColors) as TagColors[];
  const randomColor = Math.floor(Math.random() * colors.length);

  return colors[randomColor];
}

export const ManageTags = ({
  id, // Add this line
  selectedTags,
  existingTags,
  handleTagRemove,
  handleTagRemoveEntirely,
  withRemoveEntirelyOption,
  handleTagAdd,
  handleClose,
  handleDeleteTagFromUniversity,
  openModal,
  closeModal,
}: Props) => {
  const [newSelectedTags, setNewSelectedTags] = useState(selectedTags);
  const [newTag, setNewTag] = useState('');
  const [newTagColor, setNewTagColor] = useState<TagColors>(getRandomColor());
  const wrapperRef = useRef<HTMLDivElement>(null);
  const tagsRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const isTagNotSelected = (tag: TagType, selectedTagsByUser: TagType[]) => !selectedTagsByUser.some((t) => t.id === tag.id);

  const matchesSearchTerm = (tag: TagType, searchTerm: string) => tag.label.toLowerCase().includes(searchTerm.toLowerCase());

  const remainingTags = useMemo(() => existingTags.filter((tag) => isTagNotSelected(tag, newSelectedTags) && matchesSearchTerm(tag, newTag)), [newSelectedTags, selectedTags.length, existingTags.length, newTag]);

  const handleAddNewTag = (tag: TagType) => {
    setNewSelectedTags([...newSelectedTags, tag]);
    handleTagAdd(tag, false);
  };

  const handleRemove = (tag: TagType) => {
    setNewSelectedTags(newSelectedTags.filter((t) => t.id !== tag.id));
    handleTagRemove(tag);
  };

  const rememberSkipModal = (rememberAction: boolean) => {
    if (rememberAction) {
      localStorage.setItem('rememberDeleteTag', 'true');
    }
  };

  const handleDeleteTag = async (rememberAction: boolean, tag: TagType) => {
    rememberSkipModal(rememberAction);
    await handleDeleteTagFromUniversity(tag);
    setNewSelectedTags(newSelectedTags.filter((t) => t.id !== tag.id));
    closeModal();
  };

  const handleDeleteTagModal = (tag: TagType) => {
    const dontShowModal = localStorage.getItem('rememberDeleteTag');
    if (dontShowModal) {
      handleDeleteTag(true, tag);
    } else {
      openModal(
        <InfoModal
          title="Are you sure?"
          description="Removing this tag option might remove it from any student if some students still have this tag assigned."
          buttonLabel="Yes, Remove Tag"
          handleButtonClick={(remember) => handleDeleteTag(remember, tag)}
          secondaryButtonLabel="No, Go Back"
          handleSecondaryButtonClick={closeModal}
          isButtonDanger
          rememberText="Don't ask me again"
        />,
      );
    }
  };

  const handleRemoveEntirely = (tag: TagType) => {
    if (!handleTagRemoveEntirely) {
      return;
    }

    setNewSelectedTags(newSelectedTags.filter((t) => t.id !== tag.id));
    handleTagRemoveEntirely(tag);
  };

  const createNewTag = async () => {
    if (!newTag) return;

    handleTagAdd({ label: newTag, color: newTagColor, id: '' }, true);
    setNewTagColor(getRandomColor());
    setNewTag('');
  };

  const handleEnterClick = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      createNewTag();
    }
  };

  const handleClickOutside = (event: any) => {
    if (tagsRef.current && !tagsRef.current.contains(event.target)) {
      handleClose();
    }
  };
  const updateTooltipPosition = () => {
    if (tagsRef.current && wrapperRef.current) {
      const rect = wrapperRef.current.getBoundingClientRect();
      const boxWidth = tagsRef.current.offsetWidth;

      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      const scrollLeft = window.scrollX || document.documentElement.scrollLeft;

      tagsRef.current.style.top = `${rect.bottom + scrollTop}px`;
      tagsRef.current.style.left = `${rect.left + scrollLeft}px`;

      const boxiVisibleWidth = window.innerWidth - rect.x;
      const boxOverflow = boxWidth - boxiVisibleWidth;
      if (boxOverflow > 0) {
        tagsRef.current.style.width = `${Math.floor(boxiVisibleWidth) - 100}px`;
      }
    }
  };

  const createComponent = (): ReactNode => (
    <div ref={tagsRef} className="manage-tags">
      <label htmlFor={`new-tag-${id}`}>
        <div className="manage-tags__selected">
          {newSelectedTags.map((tag) => (
            <Tag
              key={tag.id}
              label={tag.label}
              color={tag.color}
              handleClose={() => handleRemove(tag)}
            />
          ))}
          <input
            type="text"
            name={`new-tag-${id}`}
            id={`new-tag-${id}`}
            autoComplete="off"
            placeholder="Add tag..."
            ref={inputRef}
            className="manage-tags__new-tag-input"
            value={newTag}
            onChange={(e) => setNewTag(e.target.value)}
            onKeyDown={handleEnterClick}
          />
        </div>
        <div className="manage-tags__existing">
          <span className="manage-tags__hint">Select an option or create one</span>
          {remainingTags.map((tag) => (
            <div
              key={tag.id}
              className="manage-tags__existing-tag"
              onClick={() => handleAddNewTag(tag)}
            >
              <Tag
                label={tag.label}
                color={tag.color}
                handleClose={() => handleDeleteTagModal(tag)}
              />
              {withRemoveEntirelyOption && (
                <div className="manage-tags__remove-button" onClick={e => e.stopPropagation()}>
                  <IconButton
                    icon="bi bi-trash3"
                    type="button"
                    onClick={() => handleRemoveEntirely(tag)}
                    mode="rounded"
                    size="medium"
                    outlined
                  />
                </div>
              )}
            </div>
          ))}

        </div>
        {newTag && (
        <div
          className="manage-tags__new-tag"
          onClick={createNewTag}
        >
          <span>Create</span>
          <Tag
            key={newTag}
            label={newTag}
            color={newTagColor}
          />
        </div>
        )}
      </label>
    </div>
  );

  useEffect(() => {
    setNewSelectedTags(selectedTags);
  }, [selectedTags.length, existingTags.length]);

  useEffect(() => {
    updateTooltipPosition();
    document.addEventListener('mousedown', handleClickOutside);
    inputRef.current?.focus();

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [tagsRef, wrapperRef]);

  return (
    <div
      ref={wrapperRef}
      className="manage-tags-wrapper"
    >
      {ReactDOM.createPortal(createComponent(), document.querySelector('#root') as HTMLElement)}
    </div>
  );
};
