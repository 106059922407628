import { useState } from 'react';
import { useQueryClient } from 'react-query';
import * as Sentry from '@sentry/react';
import { MiniAdvisor, Student } from '@/career-advisor-v3/types';
import { FilterOption } from '@/domains/core/company/types';
import { toggleAdvisorAssigment } from '@/career-advisor-v3/api/advisor';
import { AvatarsGroup } from '@/career-advisor-v3/components/avatars-group/avatars-group';

interface Props {
  student: Student;
  allAdvisors: MiniAdvisor[];
  mappedAdvisors: FilterOption[];
}

export const AdvisorAssignment = ({
  student,
  allAdvisors,
  mappedAdvisors,
}: Props) => {
  const queryClient = useQueryClient();
  const [selectedAdvisors, setSelectedAdvisors] = useState<MiniAdvisor[]>(student.assignedAdvisors || []);

  const personListArray = selectedAdvisors.map((person) => ({
    id: person.id,
    name: person.firstName,
    avatar: person.profileImage,
  }));

  const handleAdvisorAssignment = async (element: FilterOption) => {
    try {
      const selectedAdvisor = allAdvisors.find((advisor) => advisor.id === element.value);
      const isAlreadySelected = selectedAdvisors.find(advisor => advisor.id === element.value);

      let newSelectedAdvisors;
      if (isAlreadySelected) {
        newSelectedAdvisors = selectedAdvisors.filter((advisor) => advisor.id !== element.value);
      } else {
        newSelectedAdvisors = [...selectedAdvisors, selectedAdvisor as MiniAdvisor];
      }

      setSelectedAdvisors(newSelectedAdvisors);
      await toggleAdvisorAssigment(student.id, element.value as string);
      queryClient.invalidateQueries(['allStudentsList']);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    <AvatarsGroup
      withAddButton
      personList={personListArray}
      handleSelectOption={handleAdvisorAssignment}
      fullList={mappedAdvisors}
      addButtonTooltip="Assign an Advisor"
    />
  );
};
