import {
  useCallback, useContext, useState,
} from 'react';
import KanbanBoard from '@/career-advisor-v3/components/kanban-board/kanban-board';
import { MiniAdvisor, Student, StudentStatus } from '@/career-advisor-v3/types';
import { KanbanBoardLayout } from '@/career-advisor-v3/types/kanban';
import { ToastContext } from '@/components/toast/toast-provider';
import { AddToastType } from '@/domains/generic/toasts/types';
import { sendMassEmail } from '@/career-advisor-v3/api/student';
import { EmailBox } from '@/career-advisor-v3/components/email-box/email-box';

export interface StudentKanbanProps {
  columns: StudentStatus[]
  studentsData: Student[]
  universityID: string
  allAdvisors: MiniAdvisor[]
}

const StudentKanban = ({
  columns, studentsData, universityID, allAdvisors,
}: StudentKanbanProps) => {
  const [isEmailBoxOpened, setIsEmailBoxOpened] = useState(false);
  const { addToast }: AddToastType = useContext(ToastContext);
  const [sendMessageToColumn, setSendMessageToColumn] = useState<StudentStatus | null>(null);
  const transformDataToKanban = useCallback((s: Student[]): KanbanBoardLayout => {
    const kanbanBoard = new Map<StudentStatus, Student[]>();

    columns.forEach((column) => {
      const studentsPerColumn = s.filter((student) => student.status === column);

      kanbanBoard.set(column, studentsPerColumn);
    });

    return kanbanBoard;
  }, [studentsData]);

  const handleSendMessagePerColumn = (column: StudentStatus) => {
    setIsEmailBoxOpened(true);
    setSendMessageToColumn(column);
  };

  const sendEmail = async (message: string, subject: string) => {
    addToast(
      {
        type: 'success',
        message: 'Success',
        additionalMessage: 'Your email has been sent.',
      },
    );
    const columnEmails = sendMessageToColumn ? studentsData.filter((student) => student.status === sendMessageToColumn).map((student) => student.email) : [];

    await sendMassEmail(message, subject, columnEmails);
    setIsEmailBoxOpened(false);
  };

  return (
    <>
      <KanbanBoard
        messageButtonTooltip="Send email to all students in this category"
        sendMessagePerColumn={(column) => handleSendMessagePerColumn(column)}
        warningColumns={['Inactive']}
        layout={transformDataToKanban(studentsData)}
        universityId={universityID}
        allAdvisors={allAdvisors}
      />

      {isEmailBoxOpened && (
        <EmailBox
          handleSend={sendEmail}
          setIsBoxOpened={setIsEmailBoxOpened}
          column={sendMessageToColumn}
        />
      )}
    </>
  );
};

export default StudentKanban;
