import { TagColorsType } from '../tag/tag';
import './tag-with-dynamic-content.scss';

type TagProps = {
  label: string;
  color?: TagColorsType;
  image?: string;
  dynamicContent?: JSX.Element;
  handleClose?: () => void;
  handleClick?: React.MouseEventHandler<HTMLDivElement>;
};

/**
 * Primary UI component for tags
 */
export const TagWithDynamicContent = ({
  label,
  color = 'light-purple',
  image,
  dynamicContent,
  handleClick,
  handleClose,
}: TagProps) => {
  const isInteractive = handleClick || handleClose;

  return (
    <div
      data-testid="tag"
      onClick={handleClick}
      className={[
        'careerOS-tag',
        `careerOS-tag--color-${color}`,
        isInteractive ? 'careerOS-tag--clickable' : '',
      ].join(' ')}
    >
      {image && <img className="careerOS-tag__image" src={image} alt={label} />}
      {dynamicContent && (
        <div className="careerOS-tag__dynamic-content">{dynamicContent}</div>
      )}
      <span className="careerOS-tag__label">{label}</span>
      {handleClose && (
        <i
          data-testid="close-icon"
          onClick={(e) => {
            e.stopPropagation();
            handleClose();
          }}
          className="careerOS-tag__icon bi bi-x"
        />
      )}
    </div>
  );
};
