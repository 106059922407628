import './logo.scss';
// import careerOSLogo from '@/assets/images/icons/new-logo.svg';
// import careerOSLogoSmall from '@/assets/images/icons/new-logo-small.svg';
import careerOSLogo from '@/assets/images/icons/logo-x-mas.svg';
import careerOSLogoSmall from '@/assets/images/icons/logo-x-mas-small.svg';

export interface LogoProps {
  /**
   * How large should the logo be?
   */
  size?: 'small' | 'medium' | 'large';
}

/**
 * Primary UI component for the logo
 */
export const Logo = ({ size = 'large', ...props }: LogoProps) => (
  <img
    className={['careerOS-logo', `careerOS-logo--${size}`].join(' ')}
    src={size === 'large' ? careerOSLogo : careerOSLogoSmall}
    alt="CareerOS logo"
    {...props}
  />
);
