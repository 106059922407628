export const actionVerbs = [
  'accelerated',
  'accomplished',
  'accounted',
  'achieved',
  'acquired',
  'acted',
  'activated',
  'adapted',
  'addressed',
  'adjusted',
  'administered',
  'advertised',
  'advised',
  'advocated',
  'aided',
  'allocated',
  'analysed',
  'analyzed',
  'annotated',
  'answered',
  'anticipated',
  'applied',
  'appointed',
  'appraised',
  'approved',
  'arbitrated',
  'arranged',
  'articulated',
  'assembled',
  'assessed',
  'assigned',
  'assisted',
  'attained',
  'attended',
  'audited',
  'authenticated',
  'authored',
  'authorised',
  'authorized',
  'automated',
  'awarded',
  'backed',
  'balanced',
  'benchmarked',
  'boosted',
  'bootstrapped',
  'briefed',
  'brought',
  'budgeted',
  'built',
  'calculated',
  'capitalised',
  'capitalized',
  'captained',
  'captured',
  'cared',
  'carried',
  'catalogued',
  'categorised',
  'categorized',
  'chaired',
  'championed',
  'characterised',
  'characterized',
  'checked',
  'clarified',
  'classified',
  'cleared',
  'co-authored',
  'co-designed',
  'co-developed',
  'co-managed',
  'co-ordinated',
  'co-performed',
  'co-planned',
  'co-wrote',
  'coached',
  'coded',
  'collaborated',
  'collated',
  'collected',
  'combined',
  'communicated',
  'compared',
  'competed',
  'compiled',
  'completed',
  'complied',
  'composed',
  'computed',
  'conceptualised',
  'conceptualized',
  'condensed',
  'conducted',
  'conferred',
  'conserved',
  'considered',
  'consolidated',
  'constructed',
  'consulted',
  'contacted',
  'continued',
  'contracted',
  'contributed',
  'controlled',
  'convened',
  'converted',
  'conveyed',
  'convinced',
  'cooperated',
  'coordinated',
  'corrected',
  'corresponded',
  'counseled',
  'counselled',
  'crafted',
  'created',
  'critiqued',
  'curated',
  'customised',
  'customized',
  'cut',
  'debated',
  'debugged',
  'decided',
  'defined',
  'delegated',
  'deliberated',
  'delivered',
  'demonstrated',
  'deployed',
  'derived',
  'described',
  'designed',
  'detected',
  'determined',
  'developed',
  'devised',
  'diagnosed',
  'digitalised',
  'digitalized',
  'directed',
  'disbursed',
  'discussed',
  'dispatched',
  'distributed',
  'divided',
  'drafted',
  'drew',
  'drove',
  'earned',
  'edited',
  'educated',
  'effected',
  'elaborated',
  'elicited',
  'eliminated',
  'emphasised',
  'emphasized',
  'employed',
  'enabled',
  'enacted',
  'encouraged',
  'enforced',
  'engaged',
  'engineered',
  'enhanced',
  'enlisted',
  'ensured',
  'entered',
  'escalated',
  'established',
  'estimated',
  'evaluated',
  'examined',
  'exceeded',
  'excelled',
  'executed',
  'exhibited',
  'expanded',
  'expedited',
  'experienced',
  'experimented',
  'explained',
  'explored',
  'expressed',
  'extended',
  'extracted',
  'extrapolated',
  'fabricated',
  'facilitated',
  'familiarised',
  'familiarized',
  'fashioned',
  'figured',
  'filed',
  'financed',
  'flagged',
  'focused',
  'forecasted',
  'forged',
  'formalised',
  'formalized',
  'formatted',
  'formed',
  'formulated',
  'fortified',
  'fostered',
  'founded',
  'functioned',
  'furnished',
  'furthered',
  'gained',
  'gathered',
  'generated',
  'goals',
  'governed',
  'graded',
  'graduated',
  'grew',
  'grouped',
  'guided',
  'handled',
  'headed',
  'helped',
  'hired',
  'hosted',
  'ideated',
  'identified',
  'illustrated',
  'immunised',
  'immunized',
  'impacted',
  'implemented',
  'improved',
  'incorporated',
  'increased',
  'influenced',
  'informed',
  'initiated',
  'innovated',
  'inspected',
  'instilled',
  'instituted',
  'instructed',
  'integrated',
  'interacted',
  'interpreted',
  'intervened',
  'interviewed',
  'introduced',
  'invented',
  'invested',
  'investigated',
  'involved',
  'issued',
  'joined',
  'judged',
  'launched',
  'leading',
  'learned',
  'lectured',
  'led',
  'leveraged',
  'liaised',
  'listened',
  'located',
  'logged',
  'maintained',
  'managed',
  'mapped',
  'marketed',
  'mastered',
  'measured',
  'mediated',
  'mentored',
  'merged',
  'modeled',
  'moderated',
  'modified',
  'molded',
  'monitored',
  'motivated',
  'named',
  'navigated',
  'negotiated',
  'netted',
  'observed',
  'obtained',
  'onboarded',
  'operated',
  'optimised',
  'optimized',
  'orchestrated',
  'ordered',
  'organised',
  'organized',
  'originated',
  'outlined',
  'overhauled',
  'oversaw',
  'overviewed',
  'participated',
  'partnered',
  'partook',
  'perceived',
  'performed',
  'persuaded',
  'photographed',
  'piloted',
  'pioneered',
  'pitched',
  'planned',
  'planted',
  'played',
  'prepared',
  'prescribed',
  'presented',
  'presided',
  'prevented',
  'printed',
  'prioritised',
  'prioritized',
  'processed',
  'procured',
  'produced',
  'programmed',
  'projected',
  'promoted',
  'proposed',
  'protected',
  'provided',
  'publicised',
  'publicized',
  'published',
  'purchased',
  'quantified',
  'questioned',
  'raised',
  'rated',
  'reached',
  'received',
  'recognised',
  'recognized',
  'recommended',
  'reconciled',
  'reconfigured',
  'recorded',
  'recouped',
  'recruited',
  'redesigned',
  'reduced',
  'reengineered',
  'referred',
  'refocused',
  'registered',
  'regulated',
  'rehabilitated',
  'reinforced',
  'remodeled',
  'rendered',
  'reorganised',
  'reorganized',
  'repaired',
  'replaced',
  'reported',
  'represented',
  'reproduced',
  'requested',
  'researched',
  'resolved',
  'responded',
  'restored',
  'restructured',
  'resulted',
  'retained',
  'retrieved',
  'revamped',
  'reviewed',
  'revised',
  'revitalised',
  'revitalized',
  'rewrote',
  'routed',
  'schedule',
  'scheduled',
  'scouted',
  'screened',
  'searched',
  'secured',
  'segmented',
  'selected',
  'served',
  'serviced',
  'set',
  'settled',
  'shaped',
  'shared',
  'showed',
  'simplified',
  'sold',
  'solicited',
  'solved',
  'sorted',
  'sourced',
  'spearheaded',
  'specialised',
  'specialized',
  'specified',
  'spent',
  'spoke',
  'staffed',
  'standardised',
  'standardized',
  'steered',
  'steering',
  'stimulated',
  'strategised',
  'strategized',
  'streamlined',
  'strengthened',
  'structured',
  'studied',
  'submitted',
  'substantiated',
  'succeeded',
  'suggested',
  'summarised',
  'summarized',
  'supervised',
  'supplied',
  'supported',
  'surpassed',
  'surveyed',
  'sustained',
  'synthesised',
  'synthesized',
  'systematised',
  'systematized',
  'tabulated',
  'targeted',
  'taught',
  'terminated',
  'tested',
  'took',
  'tracked',
  'trained',
  'transferred',
  'transformed',
  'translated',
  'transmitted',
  'treated',
  'tutored',
  'updated',
  'upgraded',
  'used',
  'utilised',
  'utilized',
  'validated',
  'verified',
  'visited',
  'visualised',
  'visualized',
  'volunteered',
  'won',
  'worked',
  'wrote',
];
