import { useState } from 'react';
import './navbar-join-button.scss';
import { NavbarLink } from '../navbar-link/navbar-link';
import { LinkType, NavbarItemName } from '../../types/types';
import { PopupBoxWrapper } from '@/domains/generic/popup-box/popup-box';

type Props = {
  handleLinkClick: (link: string) => void;
  links?: LinkType[];
  unreadTabs: NavbarItemName[];
  collapsed: boolean;
};

export const NavbarJoinButton = ({
  handleLinkClick,
  links,
  unreadTabs,
  collapsed,
}: Props) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleStudentLinkClick = (linkName: string) => {
    handleLinkClick(linkName);
    setIsDropdownOpen(false);
  };

  const openDropdown = () => setIsDropdownOpen(true);
  const closeDropdown = () => setIsDropdownOpen(false);

  return (
    <div className={`navbar-join-button-container ${collapsed ? 'navbar-join-button-container--collapsed' : ''}`}>
      <button type="button" className={`navbar-join-button ${collapsed ? 'navbar-join-button--collapsed' : ''} ${isDropdownOpen ? 'navbar-join-button--opened' : ''}`} onClick={openDropdown}>
        <div className="navbar-join-button__icon-container">
          <i className="navbar-join-button__icon bi bi-app-indicator" />
        </div>
        <span className="navbar-join-button__label">Student App</span>
      </button>
      {isDropdownOpen && (
        <div className="navbar-join-button-container__popup">
          <PopupBoxWrapper handleBlur={closeDropdown}>
            <div className="navbar-join-button-container__dropdown-container">
              <p className="navbar-join-button-container__dropdown-title">Student App Features</p>
              <ul className="navbar-join-button-container__dropdown">
                {!!links && links.map((link) => (
                  <li key={link.url}>
                    <NavbarLink
                      link={link}
                      collapsed={false}
                      handleLinkClick={() => handleStudentLinkClick(link.label)}
                      isUnread={unreadTabs.includes(link.label as NavbarItemName)}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </PopupBoxWrapper>
        </div>
      )}
    </div>
  );
};
