import { IconButton } from '@careeros/coco';
import {
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import './student-actions-button.scss';
import { PopupBoxWrapper } from '@/domains/generic/popup-box/popup-box';
import { DropdownList } from '@/components/dropdown-list/dropdown-list';

type Props = {
  studentId: string;
  universityId: string;
};

export const StudentActionsButton = ({ studentId, universityId }: Props) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();

  const openDropdown: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    setIsDropdownOpen(true);
  };

  const navigateToStudent = (path: string) => {
    navigate(`/app/student-overview/${studentId}/university/${universityId}?tab=${encodeURIComponent(path)}`);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  return (
    <div className="student-actions-button">
      <IconButton
        icon="bi bi-three-dots"
        mode="rounded"
        size="small"
        outlined
        handleClick={openDropdown}
      />

      {isDropdownOpen && (
        <div className="student-actions-button__dropdown-container">
          <PopupBoxWrapper handleBlur={closeDropdown}>
            <div className="student-actions-button__dropdown">
              <DropdownList
                handleClose={closeDropdown}
                options={[
                  {
                    label: 'See Overview Board',
                    icon: 'bi bi-kanban',
                    onClick: () => navigateToStudent('Overview Board'),
                  },
                  {
                    label: 'See Contacts',
                    icon: 'bi bi-people',
                    onClick: () => navigateToStudent('Contacts'),
                  },
                  {
                    label: 'Access Student Resumes',
                    icon: 'bi bi-file-earmark',
                    onClick: () => navigateToStudent('Resumes'),
                  },
                  {
                    label: 'See Student Documents',
                    icon: 'bi bi-filetype-doc',
                    onClick: () => navigateToStudent('Documents'),
                  },
                  {
                    label: 'See Notes',
                    icon: 'bi bi-file-earmark-text',
                    onClick: () => navigateToStudent('Notes'),
                  },
                ]}
              />
            </div>
          </PopupBoxWrapper>
        </div>
      )}
    </div>
  );
};
