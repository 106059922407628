import { useParams } from 'react-router-dom';
import { Loader } from '@/components/loader/loader';
import './styles/dashboard.scss';
import CustomWithAuthenticationRequired from './auth/custom-protected-route';

function WebinarPage() {
  const { webinarID } = useParams();

  return (
    <div className="student-dashboard student-dashboard--form">
      <div className="career-os-grid">
        <div className="width-24/24">
          <div className="webinar-wrapper webinar-wrapper--learning">
            <div className="webinar-container">
              <iframe
                src={`https://streamyard.com/watch/${webinarID}?embed=true`}
                className="webinar-iframe"
                allow="autoplay; fullscreen"
                title="Webinar"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomWithAuthenticationRequired(WebinarPage, {
  onRedirecting: () => (
    <div id="loader-zone">
      <Loader />
    </div>
  ),
});
