import { useQueryClient } from 'react-query';
import { useEffect, useMemo, useState } from 'react';
import { useCACommentController } from '@/career-advisor-v3/contexts/CA-comment-input/use-CA-comment-input';
import { FilterNameType, FilterOption, Tile } from '@/domains/core/company/types';
import { OverviewContent } from '@/domains/generic';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { CommentInputBox } from '../comment-input-box/comment-input-box';
import { addComment } from '@/services/api/advisor';
import {
  filterTiles, filterTilesByStatus, getAllSavedIndustries, sortByPosition,
} from '@/services/helpers/company';

const companies: string[] = [
  'Company Saved',
  'Networking',
  'Applying',
  'Applied',
  'Interviewing',
  'Offer',
];

type Props = {
  studentFirstName?: string,
  studentLastName?: string,
  tiles: Tile[];
  studentID: string;
};

export const StudentOBTab = ({
  studentFirstName,
  studentLastName,
  studentID,
  tiles,
}: Props) => {
  const { openCACommentBox, closeCACommentBox } = useCACommentController();
  const [kanbanLayout, setKanbanLayout] = useState({});
  const { trackEvent } = useAnalytics();
  const [filteredTiles, setFilteredTiles] = useState<Tile[]>(tiles);
  const [filteredTilesByStatus, setFilteredTilesByStatus] = useState<Tile[]>([]);
  const savedIndustries = useMemo(() => getAllSavedIndustries(tiles), [tiles]);
  const queryClient = useQueryClient();

  const sendComment = async (tileID: string, comment: string) => {
    await addComment(comment, tileID);

    closeCACommentBox();
    trackEvent('CA Left comment');
    queryClient.invalidateQueries(['studentComments', studentID]);
  };

  const openAddCommentModal = (tile: Tile) => {
    openCACommentBox(
      <CommentInputBox
        handleSendComment={(comment) => sendComment(tile.id, comment)}
        companyName={tile.company_name}
        companyLogo={tile.logo_url}
        title={tile.company_name}
        studentFirstName={studentFirstName || '[Student First Name]'}
        studentLastName={studentLastName || '[Student Last Name]'}
        closeCommentBox={closeCACommentBox}
      />,
    );
  };

  const computeKanbanLayout = (allCompanies: Tile[]) => {
    const kanban = {
      'Company Saved': sortByPosition(allCompanies.filter((company: Tile) => company.status === 'saved')),
      Networking: sortByPosition(allCompanies.filter((company: Tile) => company.status === 'networking')),
      Applying: sortByPosition(allCompanies.filter((company: Tile) => company.status === 'applying')),
      Applied: sortByPosition(allCompanies.filter((company: Tile) => company.status === 'applied')),
      Interviewing: sortByPosition(allCompanies.filter((company: Tile) => company.status === 'interviewing')),
      Offer: sortByPosition(allCompanies.filter((company: Tile) => company.status === 'offer')),
    };
    setKanbanLayout(kanban);
  };

  const handleFiltering = (filters: { [name in FilterNameType]: FilterOption[] }, searchQuery: string) => {
    const filteredResult = filterTiles(tiles, filters, searchQuery);
    const filteredResultByStatus = filterTilesByStatus(filteredResult, Object.values(filters.status));

    setFilteredTilesByStatus(filteredResultByStatus);
    setFilteredTiles(filteredResult);
  };

  useEffect(() => {
    computeKanbanLayout(filteredTiles);
  }, [filteredTiles.length]);

  return (
    <OverviewContent
      columns={companies}
      layout={kanbanLayout}
      handleFiltersChange={handleFiltering}
      savedIndustries={savedIndustries}
      tiles={filteredTilesByStatus}
      readOnly
      handleAddComment={openAddCommentModal}
    />
  );
};
