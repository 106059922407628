import { Link, useRouteError } from 'react-router-dom';
import { Button } from '@careeros/coco';

import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';
import { Logo } from '@/components/logo/logo';
// import ErrorImage from '@/assets/images/coffee.svg';
import ErrorImage from '@/assets/images/error-image-x-mas.png';

import './styles/error.scss';

type ErrorResponse = {
  data: any;
  status: number;
  statusText: string;
  message?: string;
};

export default function ErrorPage() {
  const [clickCount, setClickCount] = useState(0);
  const error = useRouteError() as ErrorResponse;

  const goToDashboard = () => {
    window.location.href = '/';
  };

  const goToHelpForm = () => {
    window.open('https://share.hsforms.com/1XspFVBkwT2S7dMKLWzrKqg55xla', '_blank');
  };

  const reloadPage = () => {
    window.location.reload();
  };

  useEffect(() => {
    Sentry.captureException(error);
    // trackEvent('Error Page', {}, properties);
    if (error?.message?.includes('Failed to fetch dynamically imported module') || error?.message?.includes('Importing a module script failed')) {
      window.location.reload();
    }
  }, [error]);

  return (
    <div id="error-page">
      <Link className="CareerOS-logo" to="/">
        <Logo size="large" />
      </Link>

      <div className="error-box">
        <div className="error-box__content">
          {clickCount === 5 && (
            <p className="error-message">
              <i>{error.statusText || error.message}</i>
            </p>
          )}
          <h3 className="error-box__title">Lost in a Winter Wonderland!</h3>
          {/* <h3 className="error-box__subtitle">Don&apos;t cry over spilled coffee ☕</h3> */}
          <p className="error-box__text">
            It looks like this page took a wrong turn on its sleigh ride and ended up in the North Pole!
            <br />
            <br />
            Don’t worry, we can guide you back:
          </p>
          <div className="error-box__cta">
            <Button
              handleClick={reloadPage}
              mode="primary"
              size="medium"
              label="Refresh the Magic"
              icon="bi bi-arrow-clockwise"
              outlined
            />
            <Button
              handleClick={goToDashboard}
              mode="primary"
              size="medium"
              label="Back to Santa’s Workshop "
              icon="bi bi-house-door"
              outlined
            />
          </div>
          <Button
            handleClick={goToHelpForm}
            mode="text"
            size="medium"
            label="Or contact us through our help form. ↗︎"
          />
        </div>
        <img onClick={() => setClickCount(clickCount + 1)} src={ErrorImage} className="error-box__image" alt="" />
      </div>
    </div>
  );
}
