import { CohortLinkDataType } from '@/domains/core/advisor/types';
import { Cohort } from './types/cohort';

export const buildUniversityCohortListLinks = (basePath: string, universityID: string, cohorts: Cohort[]): CohortLinkDataType[] => {
  const cohortNames = cohorts.map((cohort: Cohort) => ({
    label: cohort.name,
    url: `${basePath}/${universityID}/${cohort.id}`,
  }));

  return [
    {
      label: 'All',
      url: `${basePath}/${universityID}`,
    },
    ...cohortNames,
  ];
};
