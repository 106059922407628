/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useState } from 'react';
import { RangeBadge } from '@/components/badge/badge';
import { BulletsBalanceSection } from '../../../types';
import { ResumeBanner } from '../../resume-banner/resume-banner';

type SectionBulletsBalanceProps = {
  data?: BulletsBalanceSection;
  showBanner?: boolean;
};

export const SectionBulletsBalance = ({ data, showBanner = true }: SectionBulletsBalanceProps) => {
  const [averageBulletsPerPositionVisible, setAverageBulletsPerPositionVisible] = useState(true);
  const [averageWordsPerBulletVisible, setAverageWordsPerBulletVisible] = useState(true);

  useEffect(() => {
    setAverageBulletsPerPositionVisible(true);
    setAverageWordsPerBulletVisible(true);
  }, [data]);

  return (
    <div className="score-info-improvement">
      <div className="score-info-improvement__row">
        <div className="score-info-improvement__progress">
          <RangeBadge label={`${data?.average_bullets_per_position.value}`} percentageOfCompletion={data?.average_bullets_per_position.percentage_score || 0} rangeColors={['#ff8585', '#ffe59e', '#7bcd8c']} />
        </div>
        <div className="score-info-improvement__label">
          Average bullets per position
        </div>
      </div>
      {showBanner && (
        <div className="score-info-improvement__row score-info-improvement__row--full">
          {averageBulletsPerPositionVisible && <ResumeBanner title="" text={data?.average_bullets_per_position.feedback} fragments={data?.average_bullets_per_position.fragments} isClosable handleClose={() => setAverageBulletsPerPositionVisible(false)} />}
        </div>
      )}
      <div className="score-info-improvement__row">
        <div className="score-info-improvement__progress">
          <RangeBadge label={`${data?.average_words_per_bullet.value}`} percentageOfCompletion={data?.average_words_per_bullet.percentage_score || 0} rangeColors={['#ff8585', '#ffe59e', '#7bcd8c']} />
        </div>
        <div className="score-info-improvement__label">
          Average words per bullet
        </div>
      </div>
      {showBanner && (
        <div className="score-info-improvement__row score-info-improvement__row--full">
          {averageWordsPerBulletVisible && <ResumeBanner title="" text={data?.average_words_per_bullet.feedback} fragments={data?.average_words_per_bullet.fragments} isClosable handleClose={() => setAverageWordsPerBulletVisible(false)} />}
        </div>
      )}
    </div>
  );
};
