/* eslint-disable no-restricted-globals */
import {
  useEffect, useRef, useState,
} from 'react';

import './company-tile.scss';
import { Tooltip } from '@/components/tooltip/tooltip';

interface CompanyTileProps {
  tile: any;
  isReadOnly?: boolean;
}

/**
 * Primary UI component for the kanban board
 */
export const CompanyTile = ({ tile, isReadOnly }: CompanyTileProps) => {
  const [isNameOverflow, setIsNameOverflow] = useState(false);
  const nameElementRef = useRef<any>(null);

  useEffect(() => {
    if (nameElementRef.current) {
      setIsNameOverflow(nameElementRef.current.scrollWidth > nameElementRef.current.clientWidth);
    }
  }, []);
  return (
    <div
      className={`company-kanban-tile ${isReadOnly ? 'company-kanban-tile--read-only' : ''} card`}
    >
      <div className="company-kanban-tile__logo">
        {tile.initials}
      </div>
      <Tooltip label={tile.company_name} position="bottom" disableHoverListener={!isNameOverflow}>
        <h3 ref={nameElementRef} className="company-kanban-tile__name">
          {tile.company_name}
        </h3>
      </Tooltip>
      <p className="company-kanban-tile__industry">{tile.industry}</p>
    </div>
  );
};
