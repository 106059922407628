import { useMemo, useRef, useState } from 'react';
import { Button } from '@careeros/coco';
import { DropdownList } from '@/components/dropdown-list/dropdown-list';

import './custom-templates-insert-button.scss';
import { ContactPlaceholders } from '../../types';

type Props = {
  handleVariableInsert: (variable: string) => void;
  availiblePlaceholders: ContactPlaceholders;
};

export const CustomTemplatesInsertButton = ({ handleVariableInsert, availiblePlaceholders }: Props) => {
  const [isInsertOptionsVisible, setIsInsertOptionsVisible] = useState(false);
  const insertButtonRef = useRef<HTMLDivElement | null>(null);

  const handleOptionClick = (variable: string) => {
    handleVariableInsert(variable);
    setIsInsertOptionsVisible(false);
  };

  const handleOpenButtonClick = () => {
    setIsInsertOptionsVisible(!isInsertOptionsVisible);
  };

  const templateVaraibles: {
    [K in keyof ContactPlaceholders]: {
      label: string | JSX.Element;
      icon?: string;
      image?: string;
      onClick: () => Promise<void> | void;
    };
  } = {
    FirstName: {
      label: (
        <>
          <span className="insert-button__placeholder">[FirstName]</span>
          {' '}
          - First Name
        </>
      ),
      onClick: () => handleOptionClick('[FirstName]'),
    },
    LastName: {
      label: (
        <>
          <span className="insert-button__placeholder">[LastName]</span>
          {' '}
          - Last Name
        </>
      ),
      onClick: () => handleOptionClick('[LastName]'),
    },
    Company: {
      label: (
        <>
          <span className="insert-button__placeholder">[Company]</span>
          {' '}
          - Company
        </>
      ),
      onClick: () => handleOptionClick('[Company]'),
    },
  };

  const availibleVars = useMemo(
    () => Object.keys(availiblePlaceholders)
      .map(key => templateVaraibles[key as keyof ContactPlaceholders])
      .filter(opt => opt !== undefined),
    [availiblePlaceholders],
  );

  return (
    <div ref={insertButtonRef} className="insert-button">
      {isInsertOptionsVisible && (
        <div className="insert-button__options">
          <DropdownList
            options={availibleVars.filter(opt => opt !== undefined) as any}
            parentRef={insertButtonRef}
            handleClose={() => setIsInsertOptionsVisible(false)}
          />
        </div>
      )}
      <Button
        mode="primary"
        outlined
        size="medium"
        label="Insert Variable"
        icon={isInsertOptionsVisible ? 'bi bi-chevron-down' : 'bi bi-chevron-up'}
        iconPosition="right"
        onClick={handleOpenButtonClick}
        data-testid="insert-button"
      />
    </div>
  );
};
