import { AITemplateType } from '@/domains/core/student/types';

export const GenerateCommentOptions: AITemplateType[] = [
  {
    name: '💤 Nudge inactive students',
    type: 'CA Comment',
  },
  {
    name: '📅 Message before 1:1',
    type: 'CA Comment',
  },
  {
    name: '💬 Nudge to network',
    type: 'CA Comment',
  },
  {
    name: '📩 Nudge to send first outreach',
    type: 'CA Comment',
  },
  {
    name: '☕️ Nudge towards coffee chats',
    type: 'CA Comment',
  },
  {
    name: '👥 Share contacts',
    type: 'CA Comment',
  },
  {
    name: '💼 Share job opportunities',
    type: 'CA Comment',
  },
  {
    name: '🧑‍🎓 Nudge to complete profile',
    type: 'CA Comment',
  },
];
