/* eslint-disable @typescript-eslint/naming-convention */
import { useContext } from 'react';
import {
  Certifications, Diversity, Values, CompanyMedia, Card,
} from '@careeros/coco';
import { ModalContext } from '@/components/modal/modal-provider';
import { prettifyHTML } from '@/services/helpers/processHtml';
import { CompanyCulture, CompanyFeatures } from '../../types';
import './company-culture.scss';
import { isMobileDevice } from '@/services/helpers/responsive';

type CompanyCultureProps = {
  culture: CompanyCulture;
  features: CompanyFeatures;
};

const CompanyCultureContainer = ({ culture, features }: CompanyCultureProps) => {
  const {
    mission, vision, culture_description, values, diversity_statistics, glassdoor_url, kununu_url, photos_videos,
  } = culture;
  const {
    show_certifications, show_company_mission, show_company_values, show_company_vision, show_culture_description, show_diversity_statistics, show_glassdoor, show_kununu, show_culture_media,
  } = features;

  const { openModal } = useContext(ModalContext) as any;

  const hasReviewsOrCertificates = (show_glassdoor && glassdoor_url) || (show_kununu && kununu_url);
  const hasMedia = show_culture_media && photos_videos && photos_videos?.length > 0;

  return (

    <div className="culture">
      <section className="culture__info-section">
        {show_diversity_statistics && diversity_statistics && diversity_statistics.length > 0 ? (
          <Diversity diversity_statistics={diversity_statistics} />
        ) : null}

        {show_company_mission && mission ? (
          <Card>
            <Card.Header>
              Mission
            </Card.Header>
            <Card.Content>
              <div dangerouslySetInnerHTML={{ __html: prettifyHTML(mission) }} />
            </Card.Content>
          </Card>
        ) : null}

        {show_company_values && values && values.length > 0 ? (
          <Values values={values} />
        ) : null}

        {show_company_vision && vision ? (
          <Card>
            <Card.Header>
              Vision
            </Card.Header>
            <Card.Content>
              <div dangerouslySetInnerHTML={{ __html: prettifyHTML(vision) }} />
            </Card.Content>
          </Card>
        ) : null}

        {show_culture_description && culture_description ? (
          <Card>
            <Card.Header>
              Culture
            </Card.Header>
            <Card.Content>
              <div dangerouslySetInnerHTML={{ __html: prettifyHTML(culture_description) }} />
            </Card.Content>
          </Card>
        ) : null}
      </section>
      {hasReviewsOrCertificates || hasMedia
        ? (
          <section className="culture__about-section">
            {hasReviewsOrCertificates
              ? <Certifications show_glassdoor={show_glassdoor} show_kununu={show_kununu} show_certifications={show_certifications} glassdoor_url={glassdoor_url} kununu_url={kununu_url} />
              : null}

            {show_culture_media && hasMedia
              ? (
                <Card>
                  <Card.Header>
                    Culture in Pictures
                  </Card.Header>
                  <Card.Content>
                    <CompanyMedia media={photos_videos} openModal={openModal} column={!isMobileDevice} />
                  </Card.Content>
                </Card>
              )
              : null}

          </section>
        )
        : null}

    </div>
  );
};

export default CompanyCultureContainer;
