import { TooltipIcon } from '@/components/tooltip-icon/tooltip-icon';
import './all-contacts-tab.scss';
import { ContactType } from '@/career-advisor-v3/types/contacts-book';
import { ContactBookTile } from '../contact-book-tile/contact-book-tile';

type Props = {
  contactsList: ContactType[];
  observerTarget: React.RefObject<HTMLDivElement>;
  totalContactsCount: number;
  isNewPageLoading: boolean;
  currentActiveId: string;
  currentLoadingId: string;
  handleSaveAction: (contactsId: string) => void;
  handleClick: (contactsId: string) => void;
  handleUnsaveAction: (contactsId: string) => void;
};

export const SavedContactsTab = ({
  contactsList,
  totalContactsCount,
  observerTarget,
  isNewPageLoading,
  currentActiveId,
  currentLoadingId,
  handleSaveAction,
  handleClick,
  handleUnsaveAction,
}: Props) => {
  const handleContactSaveToggle = (item: ContactType) => (item.is_bookmarked
    ? () => handleUnsaveAction(item.id)
    : () => handleSaveAction(item.id));

  return (
    <>
      <div className="contact-tabs__top-info">
        <div className="contact-tabs__count">
          <p className="contact-tabs__count-number">{`${totalContactsCount?.toLocaleString()} contacts found`}</p>
          {totalContactsCount > 0 && (
            <TooltipIcon tooltipLabel="These are your saved contacts." />
          )}
        </div>
      </div>
      <div className="contact-tabs__items">
        {contactsList.map((item) => (
          <ContactBookTile
            key={item.id}
            contact={item}
            isActive={currentActiveId === item.id}
            isLoading={currentLoadingId === item.id}
            isSaved={item.is_bookmarked}
            onContactSaveToggle={handleContactSaveToggle(item)}
            onSelect={handleClick}
          />
        ))}
        <div ref={observerTarget} />
        {isNewPageLoading && <div className="contact-tabs__loader" />}
      </div>
    </>
  );
};
