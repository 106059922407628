import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  DragDropContext, Droppable, Draggable, OnDragEndResponder, DropResult,
} from 'react-beautiful-dnd';
import { IconButton } from '@careeros/coco';
import { useScroll } from '@/services/hooks/use-scroll';
import './contacts-kanban.scss';
import { Counter } from '@/components/counter/counter';
import { ContactApiStatus, ContactWithNetworkingData } from '../../types';
import { ContactKanbanTile } from '@/domains/generic/kanban/contact-tile/contact-tile';
import { MessageType } from '@/domains/core/netwoking/types';

interface ContactsKanbanProps {
  columns: string[];
  layout: any;
  onChange: any;
  isLoadingMessages?: boolean;
  readOnly?: boolean;
  handleContactArchive: (contactId: string, currentStatus: ContactApiStatus, shouldArchive: boolean) => void;
}

export const ContactsKanbanBoard = ({
  columns,
  layout,
  onChange,
  isLoadingMessages = false,
  readOnly = false,
  handleContactArchive,
}: ContactsKanbanProps) => {
  const [kanbanContainer, handleVerticalScroll] = useScroll();
  const [showRightScrollArrow, setShowRightScrollArrow] = useState(true);
  const [showLeftScrollArrow, setShowLeftScrollArrow] = useState(false);
  const navigate = useNavigate();

  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const el = e.currentTarget;
    const allColumnsWidth = kanbanContainer.current.scrollWidth;
    const columnsInViewWidth = kanbanContainer.current.clientWidth;

    if (Math.round(el.scrollLeft + columnsInViewWidth) >= Math.round(allColumnsWidth)) {
      setShowRightScrollArrow(false);
    } else {
      setShowRightScrollArrow(true);
    }
    if (el.scrollLeft === 0) {
      setShowLeftScrollArrow(false);
    } else {
      setShowLeftScrollArrow(true);
    }
  };

  const onDragEnd: OnDragEndResponder = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const { source, destination, draggableId } = result;

    const payload = {
      id: draggableId,
      status: source.droppableId,
      dropPosition: destination.index,
    };

    onChange(payload, destination.droppableId);
  };

  const getCommunicationChannel = (lastMessageType: MessageType | undefined, contactEmail: string | null) => {
    if (!lastMessageType && contactEmail) {
      return 'email';
    }
    if (lastMessageType === 'email') {
      return 'email';
    }
    return 'linkedin';
  };

  return (
    <div className="kanban-board-contacts">
      <div className="controls-wrapper">
        <div className="kanban-board-contacts__controls">
          <IconButton
            icon="bi bi-chevron-left"
            mode="rounded"
            size="medium"
            outlined
            handleClick={() => handleVerticalScroll('left')}
            disabled={!showLeftScrollArrow}
          />
          <IconButton
            icon="bi bi-chevron-right"
            mode="rounded"
            size="medium"
            outlined
            handleClick={() => handleVerticalScroll('right')}
            disabled={!showRightScrollArrow}
          />
        </div>
      </div>
      <div className="kanban-board-contacts__content" ref={kanbanContainer} onScroll={handleScroll}>
        <DragDropContext onDragEnd={onDragEnd}>
          {columns.map((column, index) => (
            <div
              key={column}
              className={`kanban-board-contacts__column kanban-board-contacts__column--nr-${index}`}
            >
              <div className={`kanban-board-contacts__title ${column === 'Archived' ? 'kanban-board-contacts__title--archive' : ''}`}>
                <span>{column}</span>
                <span className="kanban-board-contacts__counter">
                  <Counter counter={layout[column] && layout[column].length} size="medium" mode="default" />
                </span>
              </div>

              <Droppable key={column} droppableId={column}>
                {(providedDrop) => (
                  <div
                    className="kanban-board-contacts__drop-zone"
                    ref={providedDrop.innerRef}
                    {...providedDrop.droppableProps}
                  >
                    {layout[column] && layout[column].map((contact: ContactWithNetworkingData, idx: number) => (
                      <Draggable key={contact.id} draggableId={contact.id} index={idx} isDragDisabled={readOnly}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div className="kanban-board-contacts__tile">
                              <ContactKanbanTile
                                key={contact.id}
                                image={contact.career_summary.basics.image}
                                name={contact.career_summary.basics.name}
                                companyName={contact.current_position.company}
                                linkedInConnectionStatus={contact.linkedin_connection_status || 'notConnected'}
                                communicationChannel={getCommunicationChannel(contact.last_message?.type, contact.email)}
                                handleTileClick={() => navigate(`/app/inbox/${contact.id}?view=${getCommunicationChannel(contact.last_message?.type, contact.email)}`)}
                                message={contact.last_message?.text || contact.last_message?.subject || ''}
                                isLoadingSteps={isLoadingMessages}
                                isArchived={contact.kanban_position.status === 'archive'}
                                handleArchive={(shouldArchive: boolean) => handleContactArchive(contact.id, contact.kanban_position.status, shouldArchive)}
                              />
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {providedDrop.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          ))}
        </DragDropContext>
      </div>
    </div>
  );
};
