import { useQueryClient } from 'react-query';
import { Button } from '@careeros/coco';
import { useCallback, useContext, useMemo } from 'react';
import { useCACommentController } from '@/career-advisor-v3/contexts/CA-comment-input/use-CA-comment-input';
import { useStudentContext } from '@/career-advisor-v3/contexts/student-context/student-context-provider';
import { CommentInputBox } from '../comment-input-box/comment-input-box';
import { SidebarContext } from '@/components/sidebar/sidebar-provider';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { CommentPreviewBox } from '../comment-preview-box/comment-preview-box';
import { CommentsHistorySidebar } from '@/domains/generic/sidebars';
import { CommentFromCA } from '@/career-advisor-v3/types';
import { Tooltip } from '@/components/tooltip/tooltip';
import { addStudentGeneralComment } from '@/career-advisor-v3/api/comment';

type Props = {
  comments: CommentFromCA[];
};

export const StudentOverviewActions = ({ comments }: Props) => {
  const { openCACommentBox, closeCACommentBox } = useCACommentController();
  // TODO ELINA: remove as any after BE is connected
  const { student } = useStudentContext() || {} as any;
  const { openSidebar, closeSidebar } = useContext(SidebarContext) as any;
  const { trackEvent } = useAnalytics();
  const sortedComments = useMemo(() => comments?.sort((a, b) => {
    const dateA: any = new Date(a.created_at);
    const dateB: any = new Date(b.created_at);
    return dateB - dateA;
  }), [comments]);
  const queryClient = useQueryClient();

  const sendGeneralComment = async (comment: string) => {
    await addStudentGeneralComment(student.id, comment);

    // TODO ELINA: revalidate comments query
    closeCACommentBox();
    trackEvent('CA Left comment');
    queryClient.invalidateQueries('studentComments', student.id);
  };

  const openAddCommentBox = useCallback(() => {
    openCACommentBox(
      <CommentInputBox
        handleSendComment={sendGeneralComment}
        title="General Comment"
        studentFirstName={student?.first_name || '[Student First Name]'}
        studentLastName={student?.last_name || '[Student Last Name]'}
        closeCommentBox={closeCACommentBox}
      />,
    );
  }, [openCACommentBox, closeCACommentBox, student]);

  const handleWriteCommentClickFromSidebar = useCallback(() => {
    openAddCommentBox();
    closeSidebar();
  }, [openAddCommentBox, closeSidebar]);

  const handleSeeCommentClick = useCallback((comment: CommentFromCA) => {
    const title = comment.application?.company.name || 'General Comment';
    closeSidebar();
    openCACommentBox(
      <CommentPreviewBox
        companyName={comment.application?.company.name}
        companyLogo={comment.application?.company.logo_url}
        title={title}
        closeCommentBox={closeCACommentBox}
        commentText={comment.comment}
        id={comment.id}
        withCloseButton
        isResolved={comment.resolved}
      />,
    );
  }, [openCACommentBox, closeCACommentBox, student]);

  const openFullCommentHistory = useCallback(() => {
    openSidebar(
      <CommentsHistorySidebar
        studentID={student.id}
        withFilter
        title="Full Comments History"
        subtitle="All comments for student’s entire Overview Board."
        onWriteCommentClick={handleWriteCommentClickFromSidebar}
        onSeeCommentClick={handleSeeCommentClick}
        isResolveDisabled
      />,
    );
  }, [sortedComments, openSidebar, handleWriteCommentClickFromSidebar]);

  return (
    <>
      <Button
        mode="invisible"
        size="medium"
        icon="bi bi-arrow-bar-left"
        label="Comments History"
        handleClick={openFullCommentHistory}
      />
      <div>
        <Tooltip label="Write a general comment" position="top" withArrow>
          <Button
            mode="primary"
            size="medium"
            icon="bi bi-plus-circle"
            label="Write a Comment"
            handleClick={openAddCommentBox}
          />
        </Tooltip>
      </div>
    </>
  );
};
