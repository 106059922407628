import { useQuery } from 'react-query';
import { getAllAdvisors } from '@/career-advisor-v3/api/advisor';

export const useGetAllAdvisors = (universityID: string, options = {}) => useQuery(
  ['all-advisors', universityID],
  async () => {
    const response = await getAllAdvisors(universityID);
    return (!response || !Array.isArray(response)) ? [] : response;
  },
  {
    enabled: !!universityID,
    staleTime: 1000 * 60 * 60 * 8, // 8 hours
    ...options,
  },
);
