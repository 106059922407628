import { useState } from 'react';
import { Button } from '@careeros/coco';
import { AITemplateType } from '../../types';
import './use-ai-button.scss';
import { Tooltip } from '@/components/tooltip/tooltip';

type Props = {
  options: AITemplateType[]
  size?: 'small' | 'medium' | 'large';
  buttonMode?: 'blue' | 'special';
  listPosition?: 'left' | 'right';
  isLoading: boolean;
  handleOptionClick: (option: string, outreachType: string) => void;
  isOutlined?: boolean;
  buttonLabel?: string;
  buttonIcon?: string;
  tooltipLabel?: string;
};

export const AITemplatesButton = ({
  options,
  size = 'large',
  buttonMode = 'special',
  isLoading,
  handleOptionClick,
  listPosition = 'right',
  isOutlined = false,
  buttonLabel = 'AI',
  buttonIcon = 'bi bi-stars',
  tooltipLabel,
}: Props) => {
  const [showOptions, setShowOptions] = useState(false);
  const handleButtonClick = () => {
    setShowOptions(!showOptions);
  };

  const onOptionClick = (option: string, type: string) => {
    handleOptionClick(option, type);
    setShowOptions(false);
  };

  return (
    <div className="use-ai-button-wrapper">
      {showOptions && (
        <div className={`use-ai-button-options use-ai-button-options--${listPosition} use-ai-button-options--${size}`}>
          {options.map((option) => (
            <div key={option.name} className="use-ai-button-options__option">
              <Button
                label={option.name}
                onClick={() => onOptionClick(option.name, option.type)}
                mode={buttonMode}
                size="medium"
                outlined={isOutlined}
              />
            </div>
          ))}
        </div>
      )}
      <div className="use-ai-button">
        <Tooltip label={tooltipLabel || buttonLabel} position="top" withArrow disableHoverListener={!tooltipLabel}>
          <Button
            label={buttonLabel}
            icon={buttonIcon}
            onClick={handleButtonClick}
            mode={buttonMode}
            size="medium"
            isLoading={isLoading}
            outlined={isOutlined}
          />
        </Tooltip>
      </div>
    </div>
  );
};
