/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Banner } from '@careeros/coco';
import { ScoreFeedbackObjectFragment } from '@/domains/core/resume/types';
import { useScoringAnchors } from '@/pages/context/scoring-anchors-resume';
import { ActionVerbModal } from '../action-verb-modal/action-verb-modal';
import './resume-banner.scss';

type Props = {
  title: string;
  text?: any; // Changed back to 'any' to match original definition
  buttonLabel?: string;
  handleButtonClick?: () => void;
  handleClose?: () => void;
  buttonIcon?: string;
  buttonIconPosition?: 'left' | 'right';
  buttonPlacement?: 'horizontal' | 'vertical';
  isButtonOutlined?: boolean;
  isClosable?: boolean;
  image?: string;
  backgroundColor?: 'default' | 'white';
  fragments?: ScoreFeedbackObjectFragment[];
};

export const ResumeBannerActionVerbs = ({
  title,
  text,
  buttonLabel,
  handleButtonClick,
  handleClose,
  isClosable = false,
  image,
  buttonPlacement = 'vertical',
  isButtonOutlined = false,
  backgroundColor = 'default',
  buttonIcon,
  buttonIconPosition,
  fragments,
}: Props) => {
  const { handleScoringAnchor } = useScoringAnchors();

  const handleFragmentClick = (e: React.MouseEvent, anchor: string) => {
    e.preventDefault();
    e.stopPropagation();
    if (anchor && handleScoringAnchor) {
      handleScoringAnchor(anchor);
    }
  };

  const replacePlaceholders = (content: any) => {
    if (!content || !fragments) return content;

    if (typeof content !== 'string') {
      // If content is not a string, return it as is
      return content;
    }

    const regex = /\$(\d+)/g;
    const parts = content.split(regex);

    return parts.map((part, index) => {
      if (index % 2 === 0) {
        return part;
      }
      const fragmentIndex = parseInt(part, 10);
      if (fragmentIndex < fragments.length) {
        const fragment = fragments[fragmentIndex];
        return (
          <a
            key={`${fragment.anchor}-${index}`}
            href={fragment.anchor}
            onClick={(e) => handleFragmentClick(e, fragment.anchor)}
          >
            {fragment.text}
          </a>
        );
      }
      return `${part}`;
    });
  };

  const combinedText = (
    <>
      {text && (
        <span className="banner__description">
          {replacePlaceholders(text)}
        </span>
      )}
      {fragments && typeof text === 'string' && !/\$\d+/.test(text) && fragments.map((fragment) => (
        <span className="resume-banner__description-link-container">
          <a
            key={fragment.anchor}
            className="resume-banner__description-link"
            href={fragment.anchor}
            onClick={(e) => handleFragmentClick(e, fragment.anchor)}
          >
            {fragment.text}
          </a>
          {(fragment.text === 'Add action verb ➔' || fragment.text === 'Add action verbs ➔') && (
            <ActionVerbModal />
          )}
        </span>
      ))}
    </>
  );

  return (
    <Banner
      title={title}
      text={combinedText}
      buttonLabel={buttonLabel}
      handleButtonClick={handleButtonClick}
      handleClose={handleClose}
      isClosable={isClosable}
      image={image}
      buttonPlacement={buttonPlacement}
      isButtonOutlined={isButtonOutlined}
      backgroundColor={backgroundColor}
      buttonIcon={buttonIcon}
      buttonIconPosition={buttonIconPosition}
    />
  );
};
