import { useDeferredValue, useEffect, useState } from 'react';
import { Loader } from '@/components/loader/loader';

import './student-contacts-tab.scss';
import { Contact, ContactWithNetworkingData } from '@/domains/core/contact/types';
import { ContactsTableColumns, ContactsTableColumnsWithoutStatuses, mapContacts } from '@/domains/core/netwoking/constants/contact-table';
import { contactKanbanColumns, filterPresavedContacts, getContactsKanbanLayout } from '@/domains/core/netwoking/constants/contacts-kanban';
import { TableRow } from '@/components/table/table-types';
import { useNetworkingStatuses } from '@/services/hooks/use-networking-statuses';
import { ToggleViews } from '@/components/toggle-views/toggle-views';
import { Input } from '@/components/input/input';
import Table from '@/components/table/table';
import { ContactsKanbanBoard } from '@/domains/core/contact/components';
import { useSelf } from '@/services/queries/user';
import { useGetStudentContact } from '@/services/queries/student';

type Props = {
  studentID: string;
  studentName: string;
};

type ColumnName = typeof contactKanbanColumns[number];

type KanbanBoard = {
  [K in ColumnName]: ContactWithNetworkingData[];
};

export const StudentContactsTab = ({ studentID, studentName }: Props) => {
  const { data: contacts, isLoading: areContactsLoading, isRefetching: isRefetchingContacts } = useGetStudentContact(studentID ?? '', {
    enabled: !!studentID,
  });

  const [selectedView, setSelectedView] = useState<'list' | 'kanban'>('kanban');
  const [preparedContacts, setPreparedContacts] = useState<ContactWithNetworkingData[]>([]);
  const [tableData, setTableData] = useState<TableRow[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [kanbanLayout, setKanbanLayout] = useState<KanbanBoard | null>(null);
  const [nonPresavedContacts, setNonPresavedContacts] = useState<Contact[]>(filterPresavedContacts(contacts));
  const { data: self } = useSelf();
  const deferredQuery = useDeferredValue(searchQuery);
  const { mapAllConnectionStatuses, isLoading } = useNetworkingStatuses();
  const computeKanbanLayout = (allContacts: Contact[]) => {
    setKanbanLayout(getContactsKanbanLayout(allContacts));
  };

  const filterContacts = (queryString: string, contactsToFilter: ContactWithNetworkingData[] | undefined) => {
    const filteredResults = Array.isArray(contactsToFilter) ? contactsToFilter?.filter((contact) => {
      const fullName = `${contact.first_name} ${contact.last_name}`;
      return fullName.toLowerCase().includes(queryString.toLowerCase())
        || contact.current_position.company.toLowerCase().includes(queryString.toLowerCase())
        || contact.current_position.title.toLowerCase().includes(queryString.toLowerCase());
    }) : [];
    const filteredNonArchived = filteredResults?.filter((contact) => contact.kanban_position.status !== 'archive');

    setTableData(mapContacts(filteredNonArchived || [], () => {}, () => {}));
    computeKanbanLayout(filteredResults || []);
  };

  const handleViewChange = (value: number) => {
    setSelectedView(value === 0 ? 'kanban' : 'list');
  };

  const processContactsWithStatuses = async (allContacts: Contact[]) => {
    if (!self?.id) {
      return;
    }

    const contactsWithStatus = await mapAllConnectionStatuses(allContacts, self?.id);
    setPreparedContacts(contactsWithStatus);
  };

  useEffect(() => {
    const contactsToFilter = preparedContacts.length ? preparedContacts : contacts;
    if (deferredQuery) {
      filterContacts(deferredQuery.trim(), contactsToFilter);
    } else {
      filterContacts('', contactsToFilter);
    }
  }, [deferredQuery]);

  useEffect(() => {
    if (preparedContacts.length) {
      filterContacts(searchQuery, preparedContacts);
    }
  }, [preparedContacts]);

  useEffect(() => {
    if (self && !areContactsLoading && !isRefetchingContacts && contacts) {
      setNonPresavedContacts(filterPresavedContacts(contacts));
      filterContacts(searchQuery, contacts);
      processContactsWithStatuses(contacts);
    }
  }, [contacts?.length, self?.id, areContactsLoading, isRefetchingContacts]);

  if (areContactsLoading || !kanbanLayout) {
    return (
      <div id="loader">
        <Loader />
      </div>
    );
  }

  return (
    <div className="student-OB-contacts">
      {Array.isArray(contacts) && kanbanLayout && nonPresavedContacts.length > 0 ? (
        <>
          <div className="student-OB-contacts__top-bar">
            <ToggleViews
              labels={['Kanban View', 'List View']}
              size="small"
              selectedValue={selectedView === 'kanban' ? 0 : 1}
              emitSelectedValue={handleViewChange}
              icons={['bi-kanban', 'bi-grid-3x3']}
              iconsSelected={['bi-kanban-fill', 'bi-table']}
            />
            <div className="student-OB-contacts__search">
              <Input
                icon="bi bi-search"
                inputSize="medium"
                placeholder="Type by contact name, company or job title"
                value={searchQuery}
                id="contacts-search"
                label=""
                handleValueChange={setSearchQuery}
              />
            </div>
          </div>
          {selectedView === 'list'
            ? <Table columns={isLoading ? ContactsTableColumnsWithoutStatuses : ContactsTableColumns} data={tableData} isRowClickable />
            : (
              <ContactsKanbanBoard
                isLoadingMessages={isLoading}
                onChange={() => {}}
                columns={contactKanbanColumns}
                layout={kanbanLayout}
                readOnly
                handleContactArchive={() => {}}
              />
            )}
        </>
      ) : (
        <div className="student-OB-resumes__empty">
          <h2 className="student-OB-resumes__empty-title">
            Looks like
            {' '}
            {studentName}
            {' '}
            hasn’t save any contacts yet.
          </h2>
          <p className="student-OB-resumes__empty-subtitle">Let’s get them started on networking. You might need to send them an email to nudge them.</p>
        </div>
      )}
    </div>
  );
};
