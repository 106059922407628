/* eslint-disable consistent-return */
import React, { useContext, useState } from 'react';
import { useNavigate, useRevalidator } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { useAuth0 } from '@auth0/auth0-react';
import { DropdownList, IconButton, Tooltip } from '@careeros/coco';
import { BaseResumeListingResponse, TailoredResumeListingResponse } from '../../types';
import './resume-listing-box.scss';
import { formatDateDeltaFromNow } from '@/services/helpers/date';
import { InfoModal } from '@/domains/generic/modals';
import { ModalContext } from '@/components/modal/modal-provider';
import {
  deleteBaseResume,
  deleteTailoredResume,
  duplicateBaseResume,
} from '@/services/api/resume';
import { Tag } from '@/components/tag/tag';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { AddToastType } from '@/domains/generic/toasts/types';
import { ToastContext } from '@/components/toast/toast-provider';
import { RangeBadge } from '@/components/badge/badge';
import { downloadPDF } from '../../helpers/save-resume';

type ResumeListingBoxProps = {
  resume: BaseResumeListingResponse | TailoredResumeListingResponse;
  type: 'base' | 'tailored';
  isValid: boolean;
};

type ResumeAdvisorListingBoxProps = {
  studentID?: string;
  resume: BaseResumeListingResponse;
  type: 'base' | 'tailored';
  isValid: boolean;
};

export const ResumeListingBox = ({ resume, type, isValid }: ResumeListingBoxProps) => {
  const { openModal, closeModal } = useContext(ModalContext) as any;
  const revalidator = useRevalidator();
  const navigate = useNavigate();
  const { user } = useAuth0();
  const { trackEvent } = useAnalytics();
  const { addToast }: AddToastType = useContext(ToastContext);
  const [isActionListOpen, setIsActionListOpen] = useState(false);

  const editResume = () => {
    if (type === 'tailored') {
      navigate(`/app/resume-os/tailored/${resume.id}`);
      return;
    }
    navigate(`/app/resume-os/base/${resume.id}`);
  };

  const downloadResume = async (url: string) => {
    try {
      const fileName = resume.title;
      await downloadPDF(url, fileName);

      trackEvent('Resume Downloaded', user, {
        source: 'Resume Listing',
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const duplicateResume = async () => {
    try {
      const duplicatedResume = await duplicateBaseResume(resume.id);
      addToast({
        type: 'success',
        message: 'Copy created!',
        additionalMessage: 'Successfully duplicated your Base Resume.',
      });
      trackEvent('Resume Duplicated', user, {
        source: 'Resume Listing',
      });
      revalidator.revalidate();
      return duplicatedResume;
    } catch (error) {
      Sentry.captureException(error);
      addToast({
        type: 'error',
        message: 'Something went wrong.',
        additionalMessage: 'Couldn\'t duplicate resume. Please try again or click "🛟 Help" in the top right corner to contact us.',
      });
    }
  };

  const rememberDeleteResume = (rememberAction: boolean) => {
    if (rememberAction) {
      localStorage.setItem('rememberDeleteResume', 'true');
    }
  };

  const deleteResumeConfirmed = async (rememberAction: boolean) => {
    rememberDeleteResume(rememberAction);
    if (type === 'base') {
      await deleteBaseResume(resume.id);
    } else {
      await deleteTailoredResume(resume.id);
    }
    addToast({
      type: 'success',
      message: 'Resume Successfully Deleted!',
      additionalMessage: 'You threw out the trash. Feel free to now make a new resume if you like.',
    });
    trackEvent('Resume Deleted', user, {
      source: 'Resume Listing',
    });
    closeModal();
    revalidator.revalidate();
  };

  const deleteResume = () => {
    const rememberAction = localStorage.getItem('rememberDeleteResume');
    if (rememberAction) {
      deleteResumeConfirmed(true);
    } else {
      openModal(
        <InfoModal
          handleSecondaryButtonClick={closeModal}
          handleButtonClick={deleteResumeConfirmed}
          title="Delete Resume"
          description={`The Resume ${resume.title} will be deleted forever. Are you sure?`}
          secondaryButtonLabel="Cancel"
          buttonLabel="Delete"
          isButtonDanger
          rememberText="Don't ask me again"
        />,
      );
    }
  };

  const editOption = {
    label: 'Edit',
    icon: 'bi bi-pencil',
    onClick: () => editResume(),
  };

  const duplicateOption = {
    label: 'Duplicate Resume',
    icon: 'bi bi-copy',
    onClick: () => duplicateResume(),
  };

  const downloadOption = {
    label: 'Download as PDF',
    icon: 'bi bi-download',
    onClick: () => downloadResume(resume.source_url),
  };

  const deleteOption = {
    label: 'Delete',
    icon: 'bi bi-trash3',
    onClick: () => deleteResume(),
    isDanger: true,
  };

  const baseOptions = [editOption, duplicateOption, downloadOption, deleteOption];
  const tailoredOptions = [editOption, downloadOption, deleteOption];

  const options = type === 'base' ? baseOptions : tailoredOptions;

  const toggleDropdown = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsActionListOpen(!isActionListOpen);
  };

  return (
    <div className="resume-listing-box" onClick={editResume}>
      <div className="resume-listing-box__image-container">
        <img className="resume-listing-box__image" src={resume?.preview_url || resume.resume_template.preview_image} alt="Template" />
      </div>
      <div className="resume-listing-box__title">{resume.title}</div>
      <div className="resume-listing-box__subtitle">
        Updated
        {' '}
        {formatDateDeltaFromNow(resume.updated_at)}
      </div>
      <div className="resume-listing-box__comments">
        <i className="bi bi-chat-left-dots" />
        {resume.comments_count}
        {' '}
        Open Comment(s)
      </div>
      {!isValid && type === 'base' && (
        <div className="resume-listing-box__type">
          <Tooltip label="Won’t be considered in Resume Book. Edit Resume to Fix" position="top">
            <Tag label="Invalid - Requires Fix" color="red" />
          </Tooltip>
        </div>
      )}
      {type === 'base' && (
        <div className="resume-listing-box__score">
          <RangeBadge label={`${resume.score || '0'} pts`} percentageOfCompletion={resume.score || 0} rangeColors={['#ff8585', '#ffe59e', '#7bcd8c']} />
        </div>
      )}
      <div className="resume-listing-box__actions">
        <IconButton icon="bi bi-three-dots-vertical" outlined mode="rounded" handleClick={toggleDropdown} size="small" />
      </div>
      {isActionListOpen && (
        <div className="resume-listing-box__dropdown" onClick={(e) => e.stopPropagation()}>
          <DropdownList
            options={options}
            handleClose={() => toggleDropdown}
            iconSize="small"
          />
        </div>
      )}
    </div>
  );
};

export const ResumeAdvisorListingBox = ({
  resume,
  type,
  studentID,
  isValid,
}: ResumeAdvisorListingBoxProps) => {
  const navigate = useNavigate();
  const { user } = useAuth0();
  const { trackEvent } = useAnalytics();

  const seeResume = () => {
    if (type === 'tailored') {
      navigate(`/app/resume-os/student/${studentID}/tailored/${resume.id}`);
      return;
    }
    navigate(`/app/resume-os/student/${studentID}/base/${resume.id}`);
  };

  const downloadResume = async (url: string, event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    try {
      const fileName = resume.title;
      await downloadPDF(url, fileName);

      trackEvent('Resume Downloaded Career Advisor', user, {
        source: 'Resume Listing',
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    <div className="resume-listing-box resume-listing-box--advisor" onClick={seeResume}>
      <div className="resume-listing-box__image-container">
        <img className="resume-listing-box__image" src={resume?.preview_url || resume.resume_template.preview_image} alt="Template" />
      </div>
      <div className="resume-listing-box__title">{resume.title}</div>
      <div className="resume-listing-box__subtitle">
        Updated
        {' '}
        {formatDateDeltaFromNow(resume.updated_at)}
      </div>
      <div className="resume-listing-box__comments">
        <i className="bi bi-chat-left-dots" />
        {resume.comments_count}
        {' '}
        Open Comment(s)
      </div>
      <div className="resume-listing-box__type">
        {!isValid && type === 'base' && (
          <Tooltip label="Cannot be considered in Resume Book" position="top">
            <Tag label="Invalid - Requires Fix" color="red" />
          </Tooltip>
        )}
        {type === 'base' ? <Tag label="Base Resume" color="blue-green" /> : <Tag label="Tailored Resume" color="light-blue" />}
      </div>
      {type === 'tailored' && resume.job && (
      <div className="resume-listing-box__job">
        {resume.job.title}
      </div>
      )}
      {type === 'base' && (
        <div className="resume-listing-box__score">
          <RangeBadge label={`${resume.score || '0'} pts`} percentageOfCompletion={resume.score || 0} rangeColors={['#ff8585', '#ffe59e', '#7bcd8c']} />
        </div>
      )}
      <div className="resume-listing-box__actions">
        <Tooltip withArrow label="Add Comment" position="top">
          <IconButton icon="bi bi-chat-right-dots" outlined mode="rounded" handleClick={seeResume} size="medium" />
        </Tooltip>
        <Tooltip withArrow label="Download Resume" position="top">
          <IconButton icon="bi bi-download" onClick={(event) => downloadResume(resume.source_url, event)} outlined mode="rounded" size="medium" />
        </Tooltip>
      </div>
    </div>
  );
};
