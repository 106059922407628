import { useContext, useMemo, useState } from 'react';
import { IconButton } from '@careeros/coco';
import './chat-box-header.scss';
import { ToggleViews } from '@/components/toggle-views/toggle-views';
import { CompletedSequenceStep, MessageType, SequenceStepName } from '../../types';
import { SequenceBanner } from '../sequence-banner/sequence-banner';
import { QuestType } from '@/domains/core/student/types';
import { CompletedQuestPerContact } from '@/domains/core/contact/types';
import { getSortedSequenceStepNamesFromQuests, isSequenceStepNameType } from '../../constants';
import { TrackerTip } from '@/domains/generic/modals';
import { ModalContext } from '@/components/modal/modal-provider';
import { trackerTabs } from '@/domains/core/tracker-data';

type Props = {
  handleWidthToggle: () => void;
  handleChatChange: (value: number) => void;
  handleCompanyClick: (tab?: (typeof trackerTabs)[number]) => void;
  selectedChat: MessageType;
  isFullWidth: boolean;
  contactName: string;
  contactPosition: string;
  contactCompany: string;
  contactCompletedQuests: CompletedQuestPerContact[] | null;
  contactActiveQuests: QuestType[] | null;
  isResponseReceived?: boolean;
  isLoadingMessages: boolean;
};

export const ChatBoxHeader = ({
  selectedChat,
  handleWidthToggle,
  handleChatChange,
  handleCompanyClick,
  isFullWidth,
  contactName,
  contactPosition,
  contactCompany,
  contactCompletedQuests,
  contactActiveQuests,
  isResponseReceived,
  isLoadingMessages,
}: Props) => {
  const { openModal } = useContext(ModalContext) as any;
  const [showSequenceBanner, setShowSequenceBanner] = useState(true);

  const sortedCompletedSteps = useMemo<SequenceStepName[]>(
    () => getSortedSequenceStepNamesFromQuests(contactCompletedQuests).map((quest) => quest.quest_type) as SequenceStepName[],
    [contactCompletedQuests],
  );

  const lastCompletedStep = useMemo<CompletedSequenceStep | null>(() => {
    if (!contactCompletedQuests) {
      return null;
    }

    const filteredAndSortedSequenceSteps = getSortedSequenceStepNamesFromQuests(contactCompletedQuests);

    const lastCompletedQuest = filteredAndSortedSequenceSteps[0]?.quest_type;

    if (lastCompletedQuest === QuestType.ScheduleCoffeeChat || lastCompletedQuest === QuestType.AddNewContact) {
      return null;
    }

    return filteredAndSortedSequenceSteps[0] as CompletedSequenceStep || null;
  }, [contactCompletedQuests]);

  const activeStep = useMemo<SequenceStepName | null>(() => {
    if (!contactActiveQuests) {
      return null;
    }

    const filteredSteps = contactActiveQuests.filter((quest) => isSequenceStepNameType(quest));

    return filteredSteps[0] as SequenceStepName || null;
  }, [contactActiveQuests]);

  const isBannerHidden = useMemo(() => {
    if (sortedCompletedSteps.some((step) => step === QuestType.ScheduleCoffeeChat || step === QuestType.AddNewContact)) {
      return true;
    }

    return isLoadingMessages || !showSequenceBanner || (!activeStep && !lastCompletedStep);
  }, [activeStep, lastCompletedStep, showSequenceBanner, isLoadingMessages, sortedCompletedSteps]);

  const handleCoffeChatClick = () => {
    openModal(<TrackerTip title="Coffee Chat Tips" activeState="networking" tipLocation="#openTipModal" />);
  };

  const handleAddNewContactClick = () => {
    window.location.hash = '#addContact';
    handleCompanyClick('Contacts');
  };

  return (
    <div className="chat-box-header">
      <div className="chat-box-header__info">
        <h2 className="chat-box-header__name" onClick={handleWidthToggle}>{contactName}</h2>
        <h3 className="chat-box-header__position">
          {`${contactPosition} at `}
          <span
            className="chat-box-header__company"
            onClick={() => handleCompanyClick()}
          >
            {contactCompany}
          </span>
        </h3>
      </div>
      <div className="chat-box-header__actions">
        <ToggleViews
          size="small"
          selectedValue={selectedChat === 'linkedin' ? 0 : 1}
          emitSelectedValue={handleChatChange}
          labels={['Linkedin', 'Email']}
          icons={['bi-linkedin', 'bi-envelope']}
          iconsSelected={['bi-linkedin', 'bi-envelope-fill']}
        />
        <IconButton
          icon={`bi ${isFullWidth ? 'bi-arrow-bar-right' : 'bi-arrow-bar-left'}`}
          onClick={handleWidthToggle}
          outlined
          mode="rounded"
          size="large"
        />
      </div>
      {!isBannerHidden && (
        <div className="chat-box-header__banner">
          <SequenceBanner
            currentStep={activeStep}
            lastCompletedStepName={lastCompletedStep?.quest_type}
            lastCompletedStepTime={lastCompletedStep?.updated_at || ''}
            completedSteps={sortedCompletedSteps}
            handleClose={() => setShowSequenceBanner(false)}
            handleAddNewContactClick={handleAddNewContactClick}
            handleCoffechatClick={handleCoffeChatClick}
            isResponseReceived={contactActiveQuests?.includes(QuestType.ScheduleCoffeeChat) || isResponseReceived || false}
          />
        </div>
      )}
    </div>
  );
};
