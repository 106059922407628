import { useEffect, useState } from 'react';
import './select.scss';

interface SelectProps {
  value: string;
  id: string;
  label?: string;
  options: string[];
  handleValueChange: (value: any) => void;
  isInvalid?: boolean;
  warningMessage?: string;
  placeholder?: string;
  hint?: string;
  icon?: { [k: string]: string; };
  showIcon?: boolean;
  textSize?: 'large';
  width?: 'full' | 'fit-box';
}
/**
 * Primary UI component for the Select
 */
export const Select = ({
  label,
  id,
  value = '',
  options = [],
  handleValueChange,
  placeholder,
  isInvalid = false,
  warningMessage,
  hint,
  icon,
  showIcon,
  textSize,
  width = 'full',
}: SelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const showValue = value === '' ? placeholder : value;
  const handleOptionClick = (option: string) => {
    handleValueChange(option);
    setIsOpen(false);
  };
  const handleBlur = (e: any) => {
    if (!e.target.classList.contains('select-wrapper__options') && !e.target.classList.contains('select-wrapper__select')
    && !e.target.classList.contains('select-wrapper__option') && !e.target.classList.contains('select-wrapper__label')
    && !e.target.classList.contains('select-wrapper__name')) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleBlur);

    return () => document.removeEventListener('mousedown', handleBlur);
  }, []);

  return (
    <div>
      <div className={`select-wrapper select-wrapper--${textSize}`}>
        {label && (
          <label className="select-wrapper__label" htmlFor={id} onClick={() => setIsOpen(!isOpen)}>
            {label}
          </label>
        )}
        <div
          className={`select-wrapper__select select-wrapper__select--${width} ${!options.includes(value) ? 'select-wrapper__select--default' : ''} ${isInvalid ? 'select-wrapper__select--invalid' : ''}`}
          id={id}
          onClick={() => setIsOpen(!isOpen)}
        >
          {showIcon && icon && value && (
            <img
              src={icon[value]}
              alt=""
              className="select-wrapper__item-image"
            />
          )}
          <div className="select-wrapper__name">
            {showValue || placeholder || 'Select an option'}
          </div>
          <i
            className={`select-wrapper__icon ${isOpen ? 'select-wrapper__icon--opened' : ''} bi bi-caret-down-fill`}
          />
          {isOpen && (
            <ul className="select-wrapper__options">
              {options.map(option => (
                <li
                  key={option}
                  className="select-wrapper__option"
                  data-name={option}
                  onClick={() => handleOptionClick(option)}
                >
                  {showIcon && icon && (
                    <img
                      src={icon[option]}
                      alt=""
                      className="select-wrapper__item-image"
                    />
                  )}
                  {option}
                </li>
              ))}
            </ul>
          )}
        </div>
        {isInvalid && <span className="select-wrapper__warning">{warningMessage}</span>}
      </div>
      {hint && (
        <div className="select-wrapper__hint">
          <span className="select-wrapper__hint-text">{hint}</span>
        </div>
      )}
    </div>
  );
};
