import { Link } from 'react-router-dom';
import { Logo } from '@/components/logo/logo';
import './navbar-logo.scss';

interface SidebarLogoProps {
  logoLink: string;
  collapsed : boolean;
}
export const NavbarLogo = ({ logoLink, collapsed }: SidebarLogoProps) => (
  <section className={collapsed ? 'sidebar-logo sidebar__logo--collapsed' : 'sidebar__logo'}>
    <Link to={logoLink}>
      <Logo size={collapsed ? 'small' : 'large'} />
      {' '}
    </Link>
  </section>
);
