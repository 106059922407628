import { Link, NavLink } from 'react-router-dom';
import './navbar-link.scss';
import { LinkType } from '../../types/types';

type Props = {
  link: LinkType;
  collapsed: boolean;
  handleLinkClick: () => void;
  isUnread?: boolean;
};

export const NavbarLink = ({
  link,
  collapsed,
  handleLinkClick,
  isUnread = false,
}: Props) => (
  link.isExternal ? (
    <Link
      to={link.url}
      target="_blank"
      aria-label={link.label}
      onClick={handleLinkClick}
      className={collapsed ? 'sidebar-link sidebar-link--collapsed' : 'sidebar-link'}
    >
      <div className="sidebar-link__icon-container">
        <i className={`sidebar-link__icon ${link.icon}`} />
        <i className={`sidebar-link__icon sidebar-link__icon--selected ${link.iconSelected}`} />
        {isUnread && <div className="sidebar-link__unread" />}
      </div>
      <span className="sidebar-link__label">
        {link.label}
      </span>
    </Link>
  ) : (
    <NavLink
      to={link.url}
      onClick={handleLinkClick}
      aria-label={link.label}
      id={`sidebar-${link.label}`}
      className={({ isActive }) => ((isActive || (link.url === 'companies/explore' && window.location.pathname.includes('/app/companies'))) && link.label !== 'Student App Features'
        ? 'sidebar-link sidebar-link--active'
        : 'sidebar-link') + (collapsed ? ' sidebar-link--collapsed' : '')}
    >
      <div className="sidebar-link__icon-container">
        <i className={`sidebar-link__icon ${link.icon}`} />
        <i className={`sidebar-link__icon sidebar-link__icon--selected ${link.iconSelected}`} />
        {isUnread && <div className="sidebar-link__unread" />}
      </div>
      <span className="sidebar-link__label">
        {link.label}
      </span>
    </NavLink>
  )
);
