import { useEffect, useState } from 'react';
import { RangeBadge } from '@/components/badge/badge';
import { SkillsSection } from '../../../types';
import { ResumeBanner } from '../../resume-banner/resume-banner';

type SectionSkillsProps = {
  data?: SkillsSection;
  showBanner?: boolean;
};

export const SectionSkills = ({ data, showBanner = true }: SectionSkillsProps) => {
  const [numberOfTotalSkillsVisible, setNumberOfTotalSkillsVisible] = useState(true);

  useEffect(() => {
    setNumberOfTotalSkillsVisible(true);
  }, [data]);
  return (
    <div className="score-info-improvement">
      <div className="score-info-improvement__row">
        <div className="score-info-improvement__progress">
          <RangeBadge label={`${data?.number_of_total_skills_listed.value}`} percentageOfCompletion={data?.number_of_total_skills_listed.percentage_score || 0} rangeColors={['#ff8585', '#ffe59e', '#7bcd8c']} />
        </div>
        <div className="score-info-improvement__label">
          Number of total skills listed
        </div>
      </div>
      {showBanner && (
        <div className="score-info-improvement__row score-info-improvement__row--full">
          {numberOfTotalSkillsVisible && <ResumeBanner title="" text={data?.number_of_total_skills_listed.feedback} fragments={data?.number_of_total_skills_listed.fragments} isClosable handleClose={() => setNumberOfTotalSkillsVisible(false)} />}
        </div>
      )}
    </div>
  );
};
