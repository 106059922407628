/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  useRef,
  useState,
  useEffect,
  useContext,
  useMemo,
} from 'react';
import ReactQuill from 'react-quill';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, IconButton } from '@careeros/coco';
import useDebounceEffect from '@/services/hooks/use-debounce';
import {
  convertNewLinesToHtml,
  encodeName,
  findTextInBrackets,
  transformEmailHTML,
} from '@/services/helpers/messages';
import { EditorToolbar } from '@/domains/core/contact/components/editor-toolbar/editor-toolbar';
import { GenerateMessageOptions } from '@/domains/core/contact';
import { UseAIButton } from '@/domains/core/student';
import { AddToastType } from '@/domains/generic/toasts/types';
import { ToastContext } from '@/components/toast/toast-provider';
import { useAnalytics } from '@/services/hooks/use-analytics';
import 'react-quill/dist/quill.snow.css';
import './email-actions.scss';
import '@/services/helpers/quill-config';
import { useInboxContext } from '@/pages/context/inbox';
import { EmailRecipient } from '@/domains/core/contact/components';
import { OutreachScenarios } from '../outreach-scenarios/outreach-scenarios';
import { useOutreachScenario } from '@/services/hooks/use-outreach-scenario';
import { ContactPlaceholders, OutreachScenarioType, TemplateRequestPayload } from '../../types';
import { CustomTemplatesModal } from '../custom-templates-modal/custom-templates-modal';
import { ModalContext } from '@/components/modal/modal-provider';
import { TemplatesButton } from '../templates-button/templates-button';
import { prettifyHTML, stripHTML } from '@/services/helpers/processHtml';
import { useIdentitiesNoCache, useSelf, useSelfUniversity } from '@/services/queries/user';
import { CatolicaTemplate } from '../outreach-scenarios/scenarios';

type EmailActionsProps = {
  primaryEmail: string;
  emailAddresses: string[];
  contactID: string;
  contactName: string;
  handleSend: (message: string, subject: string, to: string[], originalMessageID?: string | null | undefined, handleClose?: () => void, aiUsed?: boolean) => void;
  isFirstOutreach: boolean;
  regenerateData: TemplateRequestPayload;
  isBoxOpened: boolean;
  setIsBoxOpened: (value: boolean) => void;
};

export const EmailActions = ({
  primaryEmail,
  emailAddresses,
  contactID,
  contactName,
  handleSend,
  isFirstOutreach,
  regenerateData,
  setIsBoxOpened,
  isBoxOpened,
}: EmailActionsProps) => {
  const { user } = useAuth0();
  const { data: self } = useSelf();
  const { openModal, closeModal } = useContext(ModalContext) as any;
  const reactQuillRef = useRef<ReactQuill | null>(null);
  const reactQuillSubjectRef = useRef<ReactQuill | null>(null);
  const [selectedEmails, setSelectedEmails] = useState([primaryEmail]);
  const defaultSubject = useMemo(
    () => `${encodeName(contactName)} <> ${user?.given_name || self?.first_name || '[your name]'} | Career Insights `,
    [contactName, user, self],
  );
  const [subject, setSubject] = useState<string>(defaultSubject);
  const [AIUsed, setAIUsed] = useState(false);
  const [originalMessageID, setOriginalMessageID] = useState<string | null>(null);
  const [message, setMessage] = useState('');
  const { data: univeristy } = useSelfUniversity();
  const { addToast }: AddToastType = useContext(ToastContext);
  const {
    replyParams,
    handleReply,
    currentContact,
  } = useInboxContext() || {};
  const { trackEvent } = useAnalytics();
  const {
    showScenarios,
    regenerateScenario,
    isLoadingOutreachScenario,
    hideScenarios,
    toggleScenarioVisibility,
    scenarioTemplate,
    showRegenerateScenario,
    displayScenarios,
    cancelRegenerateScenario,
  } = useOutreachScenario();
  const hasValidEmail = useMemo(() => emailAddresses.some((email) => !!email), [emailAddresses]);
  const [contactPlaceholders, setContactPlaceholders] = useState<ContactPlaceholders>();
  const { data: identities } = useIdentitiesNoCache();

  const checkForCatolica = (sc: OutreachScenarioType) => {
    if (sc === 'meet_at_in_person_career_fair' && univeristy?.name === 'Católica Lisbon School of Business') {
      setMessage(prettifyHTML(CatolicaTemplate(regenerateData?.studentName || '[Your First Name]')));
      hideScenarios();
      return;
    }
    setAIUsed(true);
    regenerateScenario(regenerateData, sc);
  };

  const applyTemplate = (template: string, subjectTemplate?: string) => {
    hideScenarios();
    closeModal();
    setAIUsed(false);
    setMessage(template);

    if (subjectTemplate) {
      setSubject(stripHTML(subjectTemplate));
    } else {
      setSubject(defaultSubject);
    }
  };

  const handleCustomTemplatesClick = () => {
    if (!contactPlaceholders) {
      return;
    }
    openModal(
      <CustomTemplatesModal
        contactPlaceholders={contactPlaceholders}
        previewType="email"
        contactEmail={selectedEmails[0]}
        selectTemplate={applyTemplate}
      />,
      {
        closeOnClickOutside: false,
      },
    );
  };

  const checkTextForPlaceholders = () => {
    const hasPlaceholders = message.match(/\[[^[\]]*\]/g) || subject.match(/\[[^[\]]*\]/g);

    if (hasPlaceholders) {
      addToast({
        type: 'error',
        message: 'Please fill in all placeholders',
        additionalMessage:
          'Please fill in all placeholders before sending the email.',
      });
    }

    return hasPlaceholders;
  };

  const handleWriteMessageSelection = () => {
    hideScenarios();
    setMessage('');
  };

  const handleClose = () => {
    setIsBoxOpened(false);
    setSubject('');
    setMessage('');
    setSelectedEmails([primaryEmail]);
  };

  const handleSendClick = () => {
    const havePlaceholders = checkTextForPlaceholders();
    if (havePlaceholders) return;
    const messageSubject = reactQuillSubjectRef.current?.editor?.getText().trim();
    if (selectedEmails.length && message.length && messageSubject?.length) {
      handleSend(transformEmailHTML(message), messageSubject, selectedEmails, originalMessageID, handleClose, AIUsed);
    } else {
      addToast({
        type: 'error',
        message: 'Please fill in all fields',
        additionalMessage:
          'You cannot send an empty message or with an empty subject.',
      });
    }
  };

  const handleEmailClose = () => {
    handleClose();
  };

  const selectEmailFromList = (emails: string[]) => {
    setSelectedEmails(emails);
  };

  const highlightPlaceholders = () => {
    if (!reactQuillRef.current) {
      return;
    }
    const quillEditor = reactQuillRef.current.getEditor();
    const bracketedParts = findTextInBrackets(quillEditor.getText());
    quillEditor.formatText(0, quillEditor.getText().length, {
      color: false,
    });
    bracketedParts.forEach((part) => {
      quillEditor.formatText(part.index, part.length, {
        color: 'var(--purpose-information)',
      });
    });
  };

  const handleAIResponse = (AImessage: string) => {
    setMessage(convertNewLinesToHtml(AImessage));
    setAIUsed(true);
    trackEvent('Inbox Linkedin Email AI Generated');
  };

  const handleComposeClick = () => {
    setIsBoxOpened(true);
  };

  useDebounceEffect(() => {
    if (message) {
      highlightPlaceholders();
    }
  }, [message]);

  useEffect(() => {
    if (identities === undefined || !isFirstOutreach || !hasValidEmail) {
      return;
    }

    setIsBoxOpened(true);
  }, [isFirstOutreach, hasValidEmail, identities, contactID]);

  useEffect(() => {
    handleClose();

    if (isFirstOutreach && hasValidEmail) {
      setIsBoxOpened(true);
    }
  }, [contactID]);

  useEffect(() => {
    toggleScenarioVisibility(isFirstOutreach);
  }, [isFirstOutreach, contactID]);

  useEffect(() => {
    if (replyParams?.id) {
      setIsBoxOpened(true);
      setSelectedEmails([replyParams.from]);
      setSubject(replyParams.subject);
      setOriginalMessageID(replyParams.id);
      if (handleReply) {
        handleReply('', '', '');
      }
    }
  }, [replyParams]);

  useEffect(() => {
    toggleScenarioVisibility(isFirstOutreach);

    if (!subject) {
      setSubject(defaultSubject);
    }

    if (!isBoxOpened) {
      handleClose();
    }
  }, [isBoxOpened]);

  useEffect(() => {
    if (scenarioTemplate && !isLoadingOutreachScenario) {
      setMessage(scenarioTemplate);
    }
  }, [scenarioTemplate, isLoadingOutreachScenario]);

  useEffect(() => {
    setSelectedEmails([primaryEmail]);
  }, [primaryEmail]);

  useEffect(() => {
    if (currentContact) {
      setContactPlaceholders({
        FirstName: currentContact.first_name,
        LastName: currentContact.last_name,
        Company: currentContact.current_position?.company,
      });
    }
  }, [currentContact?.id]);

  if (!isBoxOpened) {
    return (
      <div className="open-box-button">
        {hasValidEmail && (
          <Button
            icon="bi bi-pencil-square"
            onClick={handleComposeClick}
            mode="primary"
            size="large"
            label="Compose"
          />
        )}
      </div>
    );
  }

  return (
    <div className="email-actions">
      <div className="email-actions__title">
        <h2>New Email</h2>
        <div onClick={handleEmailClose} className="email-actions__close">
          &times;
        </div>
      </div>
      <div className="email-actions__header-line email-actions__email">
        To
        <EmailRecipient
          selectedEmails={selectedEmails}
          setSelectedEmails={selectEmailFromList}
          emailAddresses={emailAddresses}
        />
      </div>
      <div
        className="email-actions__subject email-actions__header-line"
        onKeyDownCapture={(e) => {
          if (e.code === 'Enter') {
            e.preventDefault();
            reactQuillRef.current?.focus();
          }
        }}
      >
        <ReactQuill
          ref={reactQuillSubjectRef}
          theme="snow"
          modules={{
            toolbar: null,
          }}
          id="email-subject"
          value={subject}
          placeholder="Subject"
          onChange={setSubject}
          style={{
            border: 'none',
            padding: '0',
            height: 'fit-content',
            width: '100%',
          }}
          data-testid="email-subject-input"
        />
      </div>

      <div className="email-actions__text-container" onClick={() => reactQuillRef.current?.focus()}>
        {(showScenarios || isLoadingOutreachScenario) && (
          <div className="email-actions__scenarios">
            <OutreachScenarios
              handleApplyTemplate={(sc) => checkForCatolica(sc)}
              handleWriteMessageSelection={handleWriteMessageSelection}
              isLoading={isLoadingOutreachScenario}
            />
          </div>
        )}
        <ReactQuill
          ref={reactQuillRef}
          placeholder="Draft your message using one of our templates or our AI assistant to bring your outreach to the next level."
          modules={{
            toolbar: {
              container: '#toolbar',
            },
          }}
          theme="snow"
          value={message}
          onChange={setMessage}
          style={{
            border: 'none',
            padding: '0',
            borderRadius: '8px',
            display: (showScenarios || isLoadingOutreachScenario) ? 'none' : 'block',
          }}
        />
        <div className="email-actions__footer">
          <Button
            handleClick={handleSendClick}
            mode="primary"
            size="medium"
            label="Send"
          />
          {!isFirstOutreach && (
            <UseAIButton
              options={GenerateMessageOptions}
              type="email"
              size="small"
              handleResult={handleAIResponse}
              metaData={{
                contactID,
              }}
            />
          )}
          {showRegenerateScenario && (
            <IconButton
              label="Regenerate message"
              icon={isLoadingOutreachScenario ? 'bi bi-x-lg' : 'bi bi-arrow-clockwise'}
              onClick={() => (isLoadingOutreachScenario ? cancelRegenerateScenario() : regenerateScenario(regenerateData))}
              mode="special"
              size="large"
              outlined
            />
          )}
          <TemplatesButton
            showOutreachButton={isFirstOutreach && !showScenarios && !isLoadingOutreachScenario}
            triggerOutreachTemplateButton={displayScenarios}
            triggerCustomTemplateButton={handleCustomTemplatesClick}
          />
          <EditorToolbar />
        </div>
      </div>
    </div>
  );
};
