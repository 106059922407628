import {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import * as Sentry from '@sentry/react';
import { Button } from '@careeros/coco';
import { useQueryClient } from 'react-query';
import { ManageTags } from '@/components/manage-tags/manage-tags';
import { TableRowItem } from '../table-types';
import { Tag, TagType } from '@/components/tag/tag';
import { createTag } from '@/services/api/advisor';

import './tags-cell.scss';
import { ModalContext } from '@/components/modal/modal-provider';

type Props = {
  cellData: TableRowItem;
};

export const TagsCell = ({ cellData }: Props) => {
  const { openModal, closeModal } = useContext(ModalContext) as any;
  const [isTagManagerOpen, setIsTagManagerOpen] = useState(false);
  const [selectedTags, setSelectedTags] = useState<TagType[]>(cellData.tags || []);
  const queryClient = useQueryClient();

  const revalidateData = () => {
    queryClient.invalidateQueries(['university-tags', cellData.student?.university_id]);
    queryClient.invalidateQueries(['cohort', cellData.student?.cohort_id]);
    queryClient.invalidateQueries(['allStudents', cellData.student?.cohort_id]);
    queryClient.invalidateQueries(['allStudentsList', cellData.student?.university_id]);
  };

  const handleTagAdd = async (tag: TagType, shouldCreateTag?: boolean) => {
    let addedTag = tag;
    if (cellData.addTag) {
      if (shouldCreateTag) {
        const tagWithSameName = cellData.allTags?.find((t) => t.label.trim().toLocaleLowerCase() === tag.label.trim().toLocaleLowerCase());
        if (!tagWithSameName) {
          addedTag = await createTag(tag.label, tag.color, cellData.student?.university_id || '');
        } else {
          addedTag = tagWithSameName;
        }
      }
      if (!addedTag) {
        return;
      }
      if (selectedTags.find((t) => t.id === addedTag.id)) {
        return;
      }
      await cellData.addTag(addedTag);
      setSelectedTags([...selectedTags, addedTag]);
      revalidateData();
    }
  };

  const handleRemoveTag = async (tag: TagType) => {
    if (cellData.removeTag) {
      setSelectedTags(selectedTags.filter((t) => t.id !== tag.id));
      await cellData.removeTag(tag);
      revalidateData();
    }
  };

  const handleDeleteTagFromUniversity = async (tag: TagType) => {
    if (cellData.deleteTag) {
      try {
        await cellData.deleteTag(tag);
        setSelectedTags(selectedTags.filter((t) => t.id !== tag.id));
        revalidateData();
      } catch (error) {
        Sentry.captureException(error);
      }
    }
  };

  const handleOpenTag = () => {
    setIsTagManagerOpen(true);
  };

  const currentTags = useMemo(() => cellData.tags ?? [], [cellData.tags]);

  useEffect(() => {
    setSelectedTags(currentTags);
  }, [currentTags]);

  return (
    <td className="table-cell">
      {isTagManagerOpen && (
        <div>
          <ManageTags
            id={cellData.id || 'default'}
            selectedTags={selectedTags}
            existingTags={cellData.allTags || []}
            handleTagAdd={handleTagAdd}
            handleTagRemove={handleRemoveTag}
            handleDeleteTagFromUniversity={handleDeleteTagFromUniversity}
            handleClose={() => setIsTagManagerOpen(false)}
            openModal={openModal}
            closeModal={closeModal}
          />
        </div>
      )}
      <span className="table-cell__content">
        <div className="tags-cell" onClick={handleOpenTag}>
          {selectedTags.length > 0 ? (
            <>
              {selectedTags.map((tag) => (
                <Tag
                  key={tag.id}
                  label={tag.label}
                  color={tag.color}
                />
              ))}
              <Button mode="invisible" size="small" icon="bi bi-plus" label="" />
            </>
          ) : (
            <span className="tags-cell__placeholder">
              <Button mode="invisible" size="small" icon="bi bi-plus" label="Add tag" />
            </span>
          )}
        </div>
      </span>
    </td>
  );
};
