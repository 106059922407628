import { useContext, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { Tile } from '@/domains/core/company/types';
import './applications-table.scss';
import Table from '@/components/table/table';
import { TableRow } from '@/components/table/table-types';
import { TrackerContext, TrackerContextType } from '@/domains/core/company';
import { ApplicationsTableColumns, mapApplications } from './compute-table';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { updateCompanyRating } from '@/services/api/company';
import { useStudentComments } from '@/services/queries/student';

type Props = {
  tiles: Tile[];
  readOnly: boolean;
  handleAddComment?: (tile: Tile) => void;
};

export const ApplicationsTable = ({
  tiles,
  readOnly,
  handleAddComment,
}: Props) => {
  const { openTracker } = useContext(TrackerContext) as TrackerContextType;
  const { studentID } = useParams() as any;
  const { data: commentsResponse, isLoading } = useStudentComments(studentID);
  const [tableData, setTableData] = useState<TableRow[]>([]);
  const queryClient = useQueryClient();
  const { trackEvent } = useAnalytics();

  const handleCompanyRate = async (tileID: string, rate: number) => {
    await updateCompanyRating(tileID, rate);

    await queryClient.invalidateQueries(['archive']);
    await queryClient.invalidateQueries(['applications']);
    await queryClient.invalidateQueries(['applications-no-cache']);

    trackEvent('Overview Board: Company Rate emoji clicked', {});
  };

  useEffect(() => {
    if (!isLoading) {
      setTableData(mapApplications(tiles, {
        comments: commentsResponse || [], handleCompanyRate, userID: studentID, openTracker, readOnly, handleAddComment,
      }));
    }
  }, [tiles, commentsResponse, isLoading]);

  return (
    <div className="applications-table">
      <Table isFixedLayout columns={ApplicationsTableColumns} data={tableData} isRowClickable />
    </div>
  );
};
