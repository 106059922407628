import { useEffect, useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import { Button, IconButton } from '@careeros/coco';
import HubSpotForm from '@/domains/generic/auth/hubspot-form/hubspot-form';
import { LoginLoaderResponse } from '@/domains/generic/auth/types';
import LogoImage from '@/assets/images/new-logo.svg';

import '../styles/login-box-form.scss';
import { useAnalytics } from '@/services/hooks/use-analytics';

export const waitlistHubspotFormId = 'f4eacae6-0e1e-4ec2-a7d6-286e527334da';

export default function WaitlistLoginForm() {
  const { selectedUniversity } = useLoaderData() as LoginLoaderResponse;
  const { trackEvent } = useAnalytics();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [universityName, setUniversityName] = useState(selectedUniversity?.name || '');
  const [userEmail, setUserEmail] = useState('');
  const [isFormLoading, setIsFormLoading] = useState(false);

  if (!selectedUniversity) {
    window.location.href = '/with-university';
    return null;
  }

  const title = selectedUniversity.isTrial
    ? `${selectedUniversity.name}: Get sign up code`
    : `${selectedUniversity.name}: Join waitlist`;
  const subtitle = selectedUniversity.isTrial
    ? 'Please provide your details to be contacted for a sign up code!'
    : 'Please provide your details to join the waitlist!';
  const successText = selectedUniversity.isTrial
    ? `We added you to the waitlist for ${universityName}!`
    : `We added ${universityName} to the waitlist!`;

  const handleFormSubmit = (data: { school: string, email: string }) => {
    setUniversityName(data.school);
    setUserEmail(data.email);
    setShowSuccessMessage(true);
    trackEvent(selectedUniversity.isTrial ? 'Login: Waitlist sign up form student submitted' : 'Login: Waitlist sign up form school submitted');
  };

  const handleWebsiteRedirect = () => {
    trackEvent('Login: Open CareerOS Website clicked');
    window.open('https://www.thecareeros.com', '_self');
  };

  const handleBackButtonClick = (step: string) => {
    trackEvent('Login: Back button clicked', {}, { flowStep: step });
    // eslint-disable-next-line no-restricted-globals
    history.back();
  };

  const handleFormReady = () => setIsFormLoading(false);

  useEffect(() => {
    setIsFormLoading(true);
  }, []);

  if (showSuccessMessage) {
    return (
      <>
        <div
          className="university-login__back-button"
        >
          <IconButton
            mode="unset"
            size="medium"
            icon="bi bi-arrow-left-circle"
            hoverIcon="bi bi-arrow-left-circle-fill"
            handleClick={() => handleBackButtonClick('Waitlist Form Success')}
          />
        </div>
        <div className="login-form">
          <div className="login-form__header">
            <img
              src={LogoImage}
              alt=""
              className="login-form__image"
            />
            <h2 className="login-form__title">
              Success. You’re on the waitlist!
            </h2>

            <p className="login-form__subtitle">
              {successText}
            </p>
          </div>
          <div>
            <p className="login-form__button-description">
              We’ll get back to you via
              {' '}
              {userEmail}
              {' '}
              asap so that you can use CareerOS soon!
            </p>
            <div className="login-form__button">
              <Button
                mode="primary"
                size="fill"
                label="Open CareerOS Website"
                handleClick={handleWebsiteRedirect}
              />
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div
        className="university-login__back-button"
      >
        <IconButton
          mode="unset"
          size="medium"
          icon="bi bi-arrow-left-circle"
          hoverIcon="bi bi-arrow-left-circle-fill"
          handleClick={() => handleBackButtonClick('Waitlist Form')}
        />

      </div>
      <div className={`login-form ${isFormLoading ? 'login-form--hidden' : ''}`}>
        <div className="login-form__header">
          <img
            src={LogoImage}
            alt=""
            className="login-form__image"
          />
          <h2 className="login-form__title">
            {title}
          </h2>
          {subtitle && (
          <p className="login-form__subtitle">
            {subtitle}
          </p>
          )}
        </div>
        <div>
          <HubSpotForm
            id={waitlistHubspotFormId}
            submitText="Submit"
            hiddenFieldName={selectedUniversity.isTrial ? 'school' : undefined}
            handleSubmit={handleFormSubmit}
            handleFormReady={handleFormReady}
          />
        </div>
      </div>
    </>
  );
}
