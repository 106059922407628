import DOMPurify from 'dompurify';
import { useContext, useRef, useState } from 'react';
import { Button, IconButton } from '@careeros/coco';
import { PopupBoxWrapper } from '@/domains/generic/popup-box/popup-box';
import { AddToastType } from '@/domains/generic/toasts/types';
import { ToastContext } from '@/components/toast/toast-provider';
import { prettifyHTML } from '@/services/helpers/processHtml';
import { AIToolLoader } from '@/domains/core/company/components/ai-tool-loader/ai-tool-loader';
import { Tooltip } from '@/components/tooltip/tooltip';
import './student-ai-summary.scss';

type Props = {
  AISummary?: string;
  isAISummaryLoading?: boolean;
  isAISummaryDisabled?: boolean;
};

export const StudentAISummary = ({
  AISummary,
  isAISummaryLoading,
  isAISummaryDisabled,
}: Props) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const { addToast }: AddToastType = useContext(ToastContext);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleCopyToClipboard = () => {
    if (!contentRef.current) {
      return;
    }

    try {
      const content = contentRef.current.innerText;
      navigator.clipboard.writeText(content);

      addToast({ type: 'success', message: 'Copied to clipboard' });
    } catch (error) {
      addToast({ type: 'error', message: 'Failed to copy to clipboard' });
    }
  };

  const closePopup = () => setIsPopupOpen(false);
  const togglePopup = () => setIsPopupOpen((prev) => !prev);

  return (
    <div className="student-ai-summary">
      <Tooltip label="Not enough data yet" position="bottom-center" disableHoverListener={!isAISummaryDisabled}>
        <Button
          mode="special"
          size="medium"
          label="AI Summary"
          icon="bi bi-stars"
          handleClick={togglePopup}
          disabled={isAISummaryDisabled}
        />
      </Tooltip>
      <div className="student-ai-summary__popup-wrapper">
        {isPopupOpen && (
          <PopupBoxWrapper handleBlur={closePopup}>
            <div className="student-ai-summary__popup">
              <div className="student-ai-summary__popup-header">
                <h3 className="student-ai-summary__popup-title">
                  <i className="student-ai-summary__title-icon bi bi-stars" />
                  <span>AI Summary</span>
                </h3>
              </div>
              {(AISummary && !isAISummaryLoading) && (
              <div className="student-ai-summary__popup-content">
                <div className="student-ai-summary__actions">
                  <Tooltip
                    label="Copy to clipboard"
                    position="top"
                    withArrow
                  >
                    <IconButton
                      icon="bi bi-copy"
                      mode="primary"
                      outlined
                      size="small"
                      handleClick={handleCopyToClipboard}
                    />
                  </Tooltip>
                </div>
                <p
                  ref={contentRef}
                  className="student-ai-summary__response-text"
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(prettifyHTML(AISummary)) }}
                />
              </div>
              )}
              {isAISummaryLoading && (
              <AIToolLoader
                text="Generating your unique insights."
              />
              )}
            </div>
          </PopupBoxWrapper>
        )}
      </div>
    </div>
  );
};
