import DOMPurify from 'dompurify';
import { useEffect, useRef, useState } from 'react';
import { IconButton } from '@careeros/coco';
import './custom-template-card.scss';
import { Tooltip } from '@/components/tooltip/tooltip';

type Props = {
  isSelected?: boolean;
  title: string;
  content: string;
  handleSelectTemplate: () => void;
  handleRemoveTemplate: () => void;
  handleEditTemplate: () => void;
};

export const CustomTemplateCard = ({
  isSelected,
  title,
  content,
  handleSelectTemplate,
  handleRemoveTemplate,
  handleEditTemplate,
}: Props) => {
  const titleRef = useRef<HTMLHeadingElement>(null);
  const [isTitleOverflow, setIsTitleOverflow] = useState(false);
  const handleTitleOverflow = () => {
    setIsTitleOverflow(true);
  };

  const checkTitleOverflow = () => {
    const titleWidth = titleRef.current?.offsetWidth;
    const titleScrollWidth = titleRef.current?.scrollWidth;

    if (titleWidth != null && titleScrollWidth != null && titleScrollWidth > titleWidth) {
      handleTitleOverflow();
    } else {
      setIsTitleOverflow(false);
    }
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(checkTitleOverflow);
    if (titleRef.current) {
      resizeObserver.observe(titleRef.current);
    }
    return () => {
      resizeObserver.disconnect();
    };
  }, [titleRef.current]);

  return (
    <div data-testid="template-card" className={`template-card ${isSelected ? 'template-card--selected' : ''}`} onClick={handleSelectTemplate}>
      <Tooltip label={title} position="top" withArrow disableHoverListener={!isTitleOverflow}>
        <h4 ref={titleRef} data-testid="template-card-title" className="template-card__title">{title}</h4>
      </Tooltip>
      <p data-testid="template-card-content" className="template-card__content" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }} />
      <div className="template-card__actions">
        <IconButton
          icon="bi bi-trash"
          onClick={handleRemoveTemplate}
          mode="rounded"
          isDangerous
          size="medium"
          outlined
          data-testid="template-card-delete"
        />
        <IconButton
          icon="bi bi-pencil"
          onClick={handleEditTemplate}
          mode="rounded"
          size="medium"
          outlined
          data-testid="template-card-edit"
        />
      </div>
    </div>
  );
};
