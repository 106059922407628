/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useContext } from 'react';
import { Student } from '@/career-advisor-v3/types';

// Create the context
export type StudentContextType = {
  student: Student;
};

const StudentContext = createContext<StudentContextType | null>(null);

// Create a custom hook to use the context
export const useStudentContext = () => useContext(StudentContext);

type StudentProviderProps = {
  children: React.ReactNode;
  student: Student;
};

// Create a provider component
export const StudentContextProvider = ({ children, student }: StudentProviderProps) => (
  <StudentContext.Provider value={{
    student,
  }}
  >
    {children}
  </StudentContext.Provider>
);
