import { useEffect, useState } from 'react';
import { RangeBadge } from '@/components/badge/badge';
import { BulletsQualitySection } from '../../../types';
import { ResumeBannerActionVerbs } from '../../resume-banner/resume-banner-action-verbs';

type SectionBulletsQualityProps = {
  data?: BulletsQualitySection;
  showBanner?: boolean;
};

export const SectionBulletsQuality = ({ data, showBanner = true }: SectionBulletsQualityProps) => {
  const [percentageOfStrongActionVerbsVisible, setPercentageOfStrongActionVerbsVisible] = useState(true);
  const [percentageOfBulletsStartingWithStrongActionVerbsVisible, setPercentageOfBulletsStartingWithStrongActionVerbsVisible] = useState(true);
  const [percentageOfQuantifyOutcomesVisible, setPercentageOfQuantifyOutcomesVisible] = useState(true);

  useEffect(() => {
    setPercentageOfStrongActionVerbsVisible(true);
    setPercentageOfBulletsStartingWithStrongActionVerbsVisible(true);
    setPercentageOfQuantifyOutcomesVisible(true);
  }, [data]);

  return (
    <div className="score-info-improvement">
      <div className="score-info-improvement__row">
        <div className="score-info-improvement__progress">
          <RangeBadge label={`${data?.percentage_of_strong_action_verbs.percentage_score}%`} percentageOfCompletion={data?.percentage_of_strong_action_verbs.percentage_score || 0} rangeColors={['#ff8585', '#ffe59e', '#7bcd8c']} />
        </div>
        <div className="score-info-improvement__label">
          Percentage with strong action verbs
        </div>
      </div>
      {showBanner && (
        <div className="score-info-improvement__row score-info-improvement__row--full">
          {percentageOfStrongActionVerbsVisible && <ResumeBannerActionVerbs title="" text={data?.percentage_of_strong_action_verbs.feedback} fragments={data?.percentage_of_strong_action_verbs.fragments} isClosable handleClose={() => setPercentageOfStrongActionVerbsVisible(false)} />}
        </div>
      )}
      <div className="score-info-improvement__row">
        <div className="score-info-improvement__progress">
          <RangeBadge label={`${data?.percentage_of_bullets_starting_with_strong_action_verbs.percentage_score}%`} percentageOfCompletion={data?.percentage_of_bullets_starting_with_strong_action_verbs.percentage_score || 0} rangeColors={['#ff8585', '#ffe59e', '#7bcd8c']} />
        </div>
        <div className="score-info-improvement__label">
          Percentage starting with strong action verbs
        </div>
      </div>
      {showBanner && (
        <div className="score-info-improvement__row score-info-improvement__row--full">
          {percentageOfBulletsStartingWithStrongActionVerbsVisible && <ResumeBannerActionVerbs title="" text={data?.percentage_of_bullets_starting_with_strong_action_verbs.feedback} fragments={data?.percentage_of_bullets_starting_with_strong_action_verbs.fragments} isClosable handleClose={() => setPercentageOfBulletsStartingWithStrongActionVerbsVisible(false)} />}
        </div>
      )}
      <div className="score-info-improvement__row">
        <div className="score-info-improvement__progress">
          <RangeBadge label={`${data?.percentage_of_quantify_outcomes.percentage_score}%`} percentageOfCompletion={data?.percentage_of_quantify_outcomes.percentage_score || 0} rangeColors={['#ff8585', '#ffe59e', '#7bcd8c']} />
        </div>
        <div className="score-info-improvement__label">
          Percentage with quantified outcomes
        </div>
      </div>
      {showBanner && (
        <div className="score-info-improvement__row score-info-improvement__row--full">
          {percentageOfQuantifyOutcomesVisible && <ResumeBannerActionVerbs title="" text={data?.percentage_of_quantify_outcomes.feedback} fragments={data?.percentage_of_quantify_outcomes.fragments} isClosable handleClose={() => setPercentageOfQuantifyOutcomesVisible(false)} />}
        </div>
      )}
    </div>
  );
};
