import { useCallback, useState } from 'react';
import DOMPurify from 'dompurify';
import * as Sentry from '@sentry/react';
import { Button } from '@careeros/coco';
import { DefaultLogo } from '@/components/default-logo/default-logo';
import './comment-preview-box.scss';
import { addBlankTargetToLinks } from '@/services/helpers/processHtml';

const sanitizeConfig = {
  ADD_ATTR: ['target'], // Allow the "target" attribute
};

type Props = {
  id: string;
  commentText: string;
  companyName?: string;
  companyLogo?: string;
  title: string;
  handleMarkAsResolved?: (id: string) => Promise<void>;
  closeCommentBox: () => void;
  isResolved?: boolean;
  withCloseButton?: boolean;
};

export const CommentPreviewBox = ({
  id,
  commentText,
  companyName,
  companyLogo,
  title,
  handleMarkAsResolved,
  closeCommentBox,
  isResolved = false,
  withCloseButton = false,
}: Props) => {
  const [isResolvedSuccess, setIsResolvedsuccess] = useState(false);

  const resolveComment = useCallback(async () => {
    if (!handleMarkAsResolved) return;
    try {
      await handleMarkAsResolved(id);
      setIsResolvedsuccess(true);
    } catch (error) {
      Sentry.captureException(error);
    }
  }, [handleMarkAsResolved, id]);

  return (
    <div className="comment-preview-box">
      {withCloseButton && (
        <div className="comment-preview-box__close-button" onClick={closeCommentBox}>
          <i className="bi bi-x-lg" />
        </div>
      )}
      <div className="comment-preview-box__header">
        <div className="comment-preview-box__image">
          {(companyLogo && companyName) ? (
            <DefaultLogo
              source={companyLogo}
              name={companyName || 'Company'}
              size={24}
              type="company"
              className="comment-preview-box__header-logo"
            />
          ) : (
            <i className="comment-preview-box__header-icon bi bi-chat-square-text" />
          )}
        </div>
        <div className="comment-preview-box__title">{title}</div>
      </div>

      {isResolvedSuccess && (
        <div className="comment-preview-box__success">
          <i className="comment-preview-box__success-icon bi bi-check-circle" />
          <span>You have successfully resolved this comment!</span>
        </div>
      )}

      {(!isResolved && !isResolvedSuccess && handleMarkAsResolved) && (
        <Button
          mode="primary"
          outlined
          size="medium"
          onClick={resolveComment}
          label="Mark as resolved"
          icon="bi bi-check-circle"
        />
      )}
      {(!isResolvedSuccess) && (
        <div className="comment-preview-box__content">
          <div
            className="comment-preview-box__text"
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(addBlankTargetToLinks(commentText), sanitizeConfig) }}
          />
        </div>
      )}
    </div>
  );
};
