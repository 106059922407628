import { useQuery } from 'react-query';
import { getUniversityTags } from '../api/advisor';
import { TagType } from '@/components/tag/tag';

export const useGetUniversityTags = (universityID: string, options = {}) => useQuery(
  ['university-tags', universityID],
  async () => {
    const response: TagType[] = await getUniversityTags(universityID);
    // Check if the response is null and return an empty array if so
    return response ?? [];
  },
  {
    retry: 0,
    staleTime: 1000 * 60 * 60 * 8, // should be refetched in the background every 8 hours
    ...options, // Spread additional options
  },
);
