// import DefaultCover from '@/assets/images/default-cover.svg';
import { TabsHeader } from '@/components/tabs';
import './basic-company-header.scss';
import { DefaultLogo } from '@/components/default-logo/default-logo';
import { Badge } from '@/components/badge/badge';
import CareerOSLogo from '@/assets/images/new-logo.svg';

interface Props {
  name: string;
  logo: string;
  tabs: string[];
  location: string;
  isVerified: boolean;
  defaultTab?: string;
  tabIcons?: string[];
  jobsTabCounter?: number;
}

/**
 * Primary UI component for company header
 */
export const BasicCompanyHeader = ({
  name,
  logo,
  location,
  isVerified,
  tabs = ['Overview', 'Jobs'],
  defaultTab,
  tabIcons,
  jobsTabCounter = 0,
}: Props) => (
  <div className="basic-company-header">
    <div className="basic-company-header__container">
      <DefaultLogo
        source={logo}
        type="company"
        name={name}
        className="basic-company-header__logo"
        size={72}
      />
      <div className="basic-company-header__text">
        <h2 className="basic-company-header__name">{name}</h2>
        <span className="basic-company-header__location">
          <i className="bi bi-geo-alt-fill" />
          {location}
        </span>
      </div>

      {isVerified && (
        <div className="basic-company-header__verified">
          <img src={CareerOSLogo} alt="" className="basic-company-header__cos-logo" />
          <Badge size="large" label="Verified CareerOS Partner" color="light-green" />
        </div>
      )}
    </div>

    <div className="basic-company-header__tabs">
      <TabsHeader
        tabs={tabs}
        defaultTab={defaultTab || tabs[0]}
        containerName="basic-company"
        icons={tabIcons}
        tabCounters={{ Jobs: jobsTabCounter }}
        unreadTabs={jobsTabCounter > 0 ? ['Jobs'] : []}
      />
    </div>
  </div>
);
