/* eslint-disable no-restricted-globals */
import {
  useContext, useEffect, useRef, useState,
} from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  CompanyRating, TrackerContextType, TrackerContext,
} from '@/domains/core/company';
import './kanban-tile.scss';
import { KanbanTileJob } from '../tile-job/tile-job';
import { Tooltip } from '@/components/tooltip/tooltip';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { DefaultLogo } from '@/components/default-logo/default-logo';
import { useSelf } from '@/services/queries/user';
import { updateCompanyRating } from '@/services/api/company';
import { Tile } from '@/domains/core/company/types';
import { useStudentComments } from '@/services/queries/student';
import { TileActions } from '../tile-actions/tile-actions';
import { CommentFromCA } from '@/career-advisor-v3/types';

interface KanbanTileProps {
  tile: Tile;
}

/**
 * Primary UI component for the kanban board
 */
export const CompanyKanbanTile = ({
  tile,
}: KanbanTileProps) => {
  const { openTracker } = useContext(TrackerContext) as TrackerContextType;
  const [isNameOverflow, setIsNameOverflow] = useState(false);
  const [tileComments, setTileComments] = useState<CommentFromCA[]>([]);
  const nameElementRef = useRef<any>(null);
  const { trackEvent } = useAnalytics();
  const { data: self } = useSelf();
  const { studentID } = useParams() as any;
  const { data: commentsResponse, isLoading } = useStudentComments(studentID);
  const queryClient = useQueryClient();

  const goToTracker = (id: string) => {
    history.replaceState({}, '/app/archive');
    openTracker(id);
  };

  const handleCompanyRate = async (rate: number) => {
    await updateCompanyRating(tile.id, rate);

    await queryClient.invalidateQueries(['archive']);
    await queryClient.invalidateQueries(['applications']);
    await queryClient.invalidateQueries(['applications-no-cache']);

    trackEvent('Overview Board: Company Rate emoji clicked', {});
  };

  const getTileComments = () => {
    const tileCommentsFiltered = commentsResponse?.filter((comment: CommentFromCA) => comment.application?.id === tile.id) || [];
    setTileComments(tileCommentsFiltered);
  };

  useEffect(() => {
    if (nameElementRef.current) {
      setIsNameOverflow(nameElementRef.current.scrollWidth > nameElementRef.current.clientWidth);
    }
  }, [self?.id]);

  useEffect(() => {
    if (isLoading) return;
    getTileComments();
  }, [commentsResponse, isLoading]);

  return (
    <div
      className="kanban-tile card"
      onClick={() => goToTracker(tile.company_id)}
    >
      <div onClick={() => goToTracker(tile.company_id)} className="kanban-tile__logo">
        <DefaultLogo
          source={tile.logo_url}
          type="company"
          name={tile.company_name}
          className="kanban-tile__logo__image"
          size={24}
        />
      </div>
      <Tooltip label={tile.company_name} position="bottom-center" disableHoverListener={!isNameOverflow}>
        <h3 ref={nameElementRef} onClick={() => goToTracker(tile.company_id)} className="kanban-tile__name">
          {tile.company_name}
        </h3>
      </Tooltip>
      <div className="kanban-tile__rating">
        <CompanyRating handleCompanyRate={handleCompanyRate} currentRate={tile.rating} />
      </div>
      <p className="kanban-tile__industry">{tile.industry}</p>
      <div className="kanban-tile__jobs">
        {tile.jobs?.length > 0 && tile.jobs.map((job) => (
          <KanbanTileJob id={job.id} key={job.title} title={job.title} url={job.linkedin_url} companyId={tile.company_id} />
        ))}
      </div>
      <div className="kanban-tile__actions">
        <TileActions
          companyID={tile.company_id}
          companyName={tile.company_name}
          comments={tileComments}
          studentID={studentID}
          tileID={tile.id}
          countOfContacts={tile.count_of_contacts}
          tileOrdering={tile.ordering}
        />
      </div>
    </div>
  );
};
