import { useCACommentDispatch } from './CA-comment-input';

export const useCACommentController = () => {
  const dispatch = useCACommentDispatch();

  const openCACommentBox = (comment: JSX.Element) => {
    dispatch({ type: 'OPEN', payload: comment });
  };

  const closeCACommentBox = () => {
    dispatch({ type: 'CLOSE' });
  };

  return { openCACommentBox, closeCACommentBox };
};
