import { useEffect, useState } from 'react';
import DOMPurify from 'dompurify';
import { Button } from '@careeros/coco';
import { TabsHeader, TabsContent, withTabs } from '@/components/tabs/';
import ResumeComments from '../../../resume/components/resume-comments/resume-comments';
import { Job } from '@/domains/core/company/types';
import { AddCommentToResume } from '@/domains/core/advisor/components/add-commnet-to-resume/add-comment-to-resume';
import { BaseResumeScoreResponse, ResumeResponse, ResumeSchema } from '@/domains/core/resume/types';
import { getBaseResumeScore } from '@/services/api/resume';
import { ScoreInfo } from '@/domains/core/resume';
import './resume-comments-sidebar.scss';

type Props = {
  resume: ResumeResponse;
  job: Job
  email: string;
  url: string;
  reviewRequired?: boolean;
};

const ResumeCommentsSidebar = ({
  resume, job, email, url, reviewRequired,
}: Props) => {
  const [showCommentInput, setShowCommentInput] = useState(false);
  const [originalScore, setOriginalScore] = useState<BaseResumeScoreResponse | null>(null);

  if (!resume) return null;

  const getOriginalScore = async (resumeObject: ResumeSchema) => {
    const response = await getBaseResumeScore(resume.id, resumeObject);
    setOriginalScore(response);
  };

  const toggleCommentInput = () => {
    setShowCommentInput(!showCommentInput);
  };

  const approveResume = () => {
    // waiting for backend
  };

  useEffect(() => {
    getOriginalScore(resume.resume);
  }, [resume]);

  return (
    <div className="resume-comments-sidebar">
      <div className="resume-comments-sidebar__header">
        <div className="resume-comments-sidebar__header-tabs">
          <TabsHeader tabs={job ? ['Comments', 'Job Description'] : ['Comments', 'Score']} defaultTab="Comments" icons={job ? ['bi bi-chat-left-dots', 'bi bi-briefcase'] : ['bi bi-chat-left-dots', 'bi bi-speedometer2']} />
        </div>
      </div>
      <div className="resume-comments-sidebar__content">
        <TabsContent>
          <div data-tab="Comments">
            <div className="resume-comments-sidebar__content-comments">
              {!showCommentInput && (
                <div className="resume-comments-sidebar__content-comments-buttons">
                  <Button onClick={toggleCommentInput} outlined mode="primary" icon="bi bi-plus-circle" label="Write a Comment" size="small" />
                  {reviewRequired && (
                    <Button onClick={approveResume} mode="primary" icon="bi bi-check-circle" label="Approve Resume" size="small" />
                  )}
                </div>
              )}
              {showCommentInput && (
                <AddCommentToResume
                  id={resume.id}
                  email={email}
                  url={url}
                  toggleCommentInput={toggleCommentInput}
                />
              )}
            </div>
            {resume.id && <ResumeComments hasUnreadComments={() => {}} id={resume.id} />}
          </div>
          <div data-tab="Score">
            {resume.id && !job && <ScoreInfo active resumeID={resume.id} resume={resume.resume} selectedResumeName={resume.resume_template.name} originalScore={originalScore} emitLatestSuggestions={() => {}} showBanner={false} />}
          </div>
          {job && (
          <div data-tab="Job Description">
            <div className="resume-comments-sidebar__job-info">
              <div className="resume-comments-sidebar__job-title">{job.title}</div>
              <div className="resume-comments-sidebar__job-description" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(job.description) }} />
            </div>
          </div>
          )}
        </TabsContent>
      </div>
    </div>
  );
};

export default withTabs(ResumeCommentsSidebar);
