import { BadgeColor } from '@/components/badge/badge';
import { StudentStatus } from '@/career-advisor-v3/types';

type StudentProgressBadges = {
  [key in StudentStatus]: {
    color: BadgeColor,
    label: string;
    order: number;
  }
};

export const studentProgressBadges: StudentProgressBadges = {
  Inactive: {
    color: 'light-red',
    label: '💤 Inactive',
    order: 0,
  },
  'Companies Saved': {
    color: 'medium-grey',
    label: '🏢 Companies Saved',
    order: 1,
  },
  Networking: {
    color: 'light-green',
    label: '💬 Networking',
    order: 2,
  },
  Applying: {
    color: 'yellow',
    label: '📩 Applying',
    order: 3,
  },
  Interviewing: {
    color: 'light-blue',
    label: '👥 Interviewing',
    order: 4,
  },
  Offer: {
    color: 'dark-gradient',
    label: '📑 Offer',
    order: 5,
  },
};
