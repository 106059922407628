import { useEffect, useState } from 'react';
import { FilterNameType, FilterOption, Tile } from '@/domains/core/company/types';
import { KanbanBoard } from '../../kanban/kanban-board';
import { OverviewHeader } from '../overview-header/overview-header';
import './overview-content.scss';
import { useScroll } from '@/services/hooks/use-scroll';
import { ApplicationsTable } from '../applications-table/applications-table';

type Props = {
  tiles: Tile[];
  columns: string[];
  layout: any;
  cardChangeHandler?: (cardInfo: any, newStatus: string) => Promise<void>;
  handleFiltersChange: (filters: { [name in FilterNameType]: FilterOption[] }, searchQuery: string) => void;
  readOnly?: boolean;
  savedIndustries: string[];
  openFullCommentHistory?: () => void;
  handleAddComment?: (tile: Tile) => void;
};

export const OverviewContent = ({
  tiles,
  columns,
  layout,
  cardChangeHandler,
  handleFiltersChange,
  savedIndustries,
  openFullCommentHistory,
  handleAddComment,
  readOnly = false,
}: Props) => {
  const [kanbanContainer, handleVerticalScroll] = useScroll();
  const [showRightScrollArrow, setShowRightScrollArrow] = useState(false);
  const [showLeftScrollArrow, setShowLeftScrollArrow] = useState(false);
  const [selectedView, setSelectedView] = useState(0);

  const updateScrollState = () => {
    if (!kanbanContainer.current) {
      return;
    }

    setShowLeftScrollArrow(kanbanContainer.current.scrollLeft > 0);
    setShowRightScrollArrow(kanbanContainer.current.scrollLeft < (kanbanContainer.current.scrollWidth - kanbanContainer.current.clientWidth));
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const el = e.currentTarget;

    if (!kanbanContainer.current) {
      return;
    }

    const allColumnsWidth = kanbanContainer.current.scrollWidth;
    const columnsInViewWidth = kanbanContainer.current.clientWidth;

    if (Math.round(el.scrollLeft + columnsInViewWidth) >= Math.round(allColumnsWidth)) {
      setShowRightScrollArrow(false);
    } else {
      setShowRightScrollArrow(true);
    }

    if (el.scrollLeft === 0) {
      setShowLeftScrollArrow(false);
    } else {
      setShowLeftScrollArrow(true);
    }
  };

  const handleViewChange = (value: number) => {
    setSelectedView(value);

    const currentSearch = new URLSearchParams(window.location.search);
    currentSearch.delete('view');
    currentSearch.append('view', value.toString());

    window.history.replaceState(null, '', `?${currentSearch.toString()}`);
  };

  useEffect(() => {
    updateScrollState();
  }, [kanbanContainer]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const view = searchParams.get('view');

    if (view) {
      setSelectedView(Number(view));
    }
  }, []);

  return (
    <div className={`overview-content ${selectedView === 1 ? 'overview-content--fit-screen-height' : ''}`}>
      <div className={`overview-content__header ${readOnly ? 'overview-content__header--spacing-small' : ''}`}>
        <OverviewHeader
          handleFiltersChange={handleFiltersChange}
          savedIndustries={savedIndustries}
          openFullCommentHistory={openFullCommentHistory}
          showRightScrollArrow={showRightScrollArrow}
          showLeftScrollArrow={showLeftScrollArrow}
          handleContentScroll={handleVerticalScroll}
          selectedView={selectedView}
          changeSelectedView={handleViewChange}
        />
      </div>

      <div
        className={`overview-content__kanban ${selectedView === 0 ? 'overview-content__kanban--active' : ''}`}
      >
        <KanbanBoard
          onChange={cardChangeHandler}
          columns={columns}
          layout={layout}
          readOnly={readOnly}
          scrollContainerRef={kanbanContainer}
          onScroll={handleScroll}
        />
      </div>
      <div className={`overview-content__table ${selectedView === 1 ? 'overview-content__table--active' : ''}`}>
        <ApplicationsTable readOnly={readOnly} tiles={tiles} handleAddComment={handleAddComment} />
      </div>

    </div>
  );
};
