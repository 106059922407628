import './accordion-with-arrows.scss';

type Props = {
  toggle: React.ReactNode;
  children: React.ReactNode;
  isOpen: boolean;
};

export const AccordionWithArrows = ({ toggle, children, isOpen }: Props) => (
  <div className="accordion-with-arrows">
    <div className="accordion-with-arrows__toggle">{toggle}</div>
    <div
      className={`accordion-with-arrows__item ${
        !isOpen ? 'accordion-with-arrows__item--collapsed' : 'accordion-with-arrows__item--opened'
      }`}
    >
      <div className="accordion-with-arrows__content">{children}</div>
    </div>
  </div>
);
